import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signUp } from "../../store/actions/authActions";
import { Link } from "react-router-dom";

import styled from "styled-components";
import Row from "../layout/Row";
import fontsize from "../tokens/FontSizes";
import whitespace from "../tokens/Whitespace";
import H1 from "../layout/H1";
import Button from "../layout/Button";
import LinkButton from "../layout/LinkButton";

const FormContainer = styled.div`
	background-color: ${({ theme }) => theme.cardBackgroundColorDefault};
	border-radius: 16px;
	width: 30%;
	padding: ${whitespace.large};
	justify-content: center;
	display: flex;

	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);

	@media (max-width: 800px) {
		width: 50%;
		padding: ${whitespace.medium};
	}

	@media (max-width: 420px) {
		width: 80%;
		padding: ${whitespace.medium};
	}
`;
const Input = styled.input`
	display: flex;
	flex-grow: 1;
	height: 40px;
	padding: ${whitespace.normal};
	color: ${({ theme }) => theme.primaryFontColor};
	border: 1px solid ${({ theme }) => theme.inputBorderColorDefault};
	background: ${({ theme }) => theme.inputBackgroundColorDefault};
	border-radius: 8px;
	font-size: 15px;

	:focus {
		border: 1px solid ${({ theme }) => theme.inputBorderColorActive};
	}

	&::-webkit-input-placeholder {
		color: ${props => props.placeholderColor};
	}
`;

const Label = styled.label`
	margin-bottom: ${whitespace.default};
	display: flex;
	font-size: ${fontsize.body};
	text-align: left;
	color: ${({ theme }) => theme.primaryFontColor};
	margin-top: ${props => props.marginTop};
`;

const ValidationError = styled.div`
	width: "fit-content";
	margin-top: 8px;
	color: ${({ theme }) => theme.destructive};

	font-size: 16px;
`;

class SignUp extends Component {
	state = {
		email: "",
		password: "",
		firstName: "",
		lastName: "",
	};

	handleChange = e => {
		this.setState({
			[e.target.id]: e.target.value,
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		this.props.signUp(this.state);
	};

	render() {
		const { auth, authError } = this.props;
		if (auth.uid) return <Redirect to="/NewsDashboard" />;

		return (
			<Row flexDirection="column" flexGrow="1" justifyContent="center" alignItems="center">
				<FormContainer>
					<form
						style={{
							display: "flex",
							flexDirection: "column",
							flexGrow: "1",
						}}
						onSubmit={this.handleSubmit}
					>
						<H1 fontWeight="600" textAlign="center">
							Sign Up
						</H1>

						<Row flexDirection="column" marginTop={whitespace.big} marginBottom={whitespace.big}>
							<Label htmlFor="email">Email</Label>
							<Input placeholder="type your email..." type="email" id="email" onChange={this.handleChange} />
						</Row>

						<Row flexDirection="column" marginBottom={whitespace.big}>
							<Label htmlFor="password">Password</Label>
							<Input placeholder="type your password..." type="password" id="password" onChange={this.handleChange} />
						</Row>

						<Row flexDirection="column" marginBottom={whitespace.big}>
							<Label htmlFor="firstName">Name</Label>
							<Input placeholder="How we should call you?" type="text" id="firstName" onChange={this.handleChange} />
						</Row>

						{/*<Row
                            flexDirection="column"
                            marginBottom={whitespace.big}
                        >
                            <Label htmlFor="lastName">Last Name</Label>
                            <Input
                                placeholder="type your name..."
                                type="text"
                                id="lastName"
                                onChange={this.handleChange}
                            />
                        </Row> */}

						<ValidationError>{authError ? <p>{authError}</p> : null}</ValidationError>

						<Button design="primary" text="Create account"></Button>
					</form>
				</FormContainer>

				<Label marginTop={whitespace.large}>
					Already have an account?
					<Link to={"/signin/"}>
						<LinkButton text="Log In!" padding="none" marginLeft={whitespace.small}></LinkButton>
					</Link>
				</Label>
			</Row>
		);
	}
}

const mapStateToProps = state => {
	return {
		auth: state.firebase.auth,
		authError: state.auth.authError,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		signUp: newUser => dispatch(signUp(newUser)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
