import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Toaster } from 'react-hot-toast';
import "react-tippy/dist/tippy.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./store/reducers/rootReducer";
import editorMiddleware from "./store/middlewares/editorMiddleware";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import {
    createFirestoreInstance,
    getFirestore,
    reduxFirestore,
} from "redux-firestore";
import { ReactReduxFirebaseProvider, getFirebase } from "react-redux-firebase";
import fbConfig from "./config/fbConfig";
import firebase from "firebase/app";

//for render on auth ready
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";

//Additionals
import LiveChat from "react-livechat";
import { hotjar } from "react-hotjar";

//Components
import Logoloader from "./Components/layout/Logoloader";
import Body from "./Components/layout/Body";
import Row from "./Components/layout/Row";
import AppWraper from "./Components/layout/AppWraper";
import ContentWraper from "./Components/layout/ContentWraper";

hotjar.initialize(2378539, 6);

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(editorMiddleware),
        applyMiddleware(thunk.withExtraArgument({ getFirestore, getFirebase })),
        reduxFirestore(firebase, fbConfig)
    )
);

const profileSpecificProps = {
    userProfile: "users",
    useFirestoreForProfile: true,
    enableRedirectHandling: false,
    resetBeforeLogin: false,
};

const rrfProps = {
    firebase,
    config: profileSpecificProps,
    dispatch: store.dispatch,
    createFirestoreInstance,
};

function AuthIsLoaded({ children }) {
    const auth = useSelector((state) => state.firebase.auth);
    if (!isLoaded(auth))
        return (
            <AppWraper>
                <ContentWraper padding="36px">
                    <Row
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Logoloader />
                        <Body
                        color={({ theme }) => theme.primaryFontColor}
                        >Loading...</Body>
                    </Row>
                </ContentWraper>
            </AppWraper>
        );
    return children;
}

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <AuthIsLoaded>
                <LiveChat
                    license={9977585}
                    group={7}
                    chatBetweenGroups={false}
                />

                <App />

                <Toaster />
            </AuthIsLoaded>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById("root")
);

serviceWorker.unregister();
