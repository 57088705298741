import { setIsDisabledAction } from "../../actions/editorActions";
import { updateProject } from "../../actions/projectActions";

const handleUpdateProject = ({ dispatch, getState }, next, action) => {
	const { editor } = getState();
	dispatch(setIsDisabledAction(false));
	dispatch(updateProject(editor.projectId, action.payload));
};

export default handleUpdateProject;
