import React, { memo, useCallback, useContext, useState } from "react";
import styled, { ThemeContext, ThemeProvider } from "styled-components";
import toast from "react-hot-toast";

import { functions } from "../../config/fbConfig";
import Button from "./Button";
import fontsize from "../tokens/FontSizes";
import whitespace from "../tokens/Whitespace";
import Logoloader from "./Logoloader";
import Caption from "./Caption";
import Close from "../Icons/Close";
import PageAnimationPlayer from "./PageAnimationPlayer";

const StyledButtonText = styled.span`
	font-size: ${fontsize.caption};
	padding: 0;
`;

const StyledPreloaderWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledAddButton = styled(Button)`
	position: relative;
	height: fit-content;
	font-size: ${fontsize.caption};
	padding: ${whitespace.tiny} ${whitespace.medium};

	${StyledButtonText} {
		visibility: ${({ isLoading }) => isLoading && "hidden"};
	}
`;

const NotificationContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.tooltipBackgroundColor};
	border-radius: 8px;
	padding: 12px;
	align-items: center;
	box-shadow: 0 1px 28px -4px rgba(0, 0, 0, 0.45);
	min-width: 200px;
`;

const StyledNotificationText = styled(Caption)`
	color: ${({ theme }) => theme.tooltipTextColor};
`;

const StyledNotificationCloseButton = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	margin-left: 20px;
	cursor: pointer;

	svg {
		width: 24px;
		height: 24px;
		color: ${({ theme }) => theme.tooltipTextColor};
	}
`;

const moveCompilationToFolder = functions.httpsCallable("moveCompilationToFolder");

const GetMarketplaceCompilationButton = ({ compilationId, compilationTitle, onAdd }) => {
	const [isPending, setIsPending] = useState(false);
	const theme = useContext(ThemeContext);

	const handleAdd = useCallback(
		async event => {
			event.stopPropagation();
			setIsPending(true);

			try {
				await moveCompilationToFolder({ compilationId });

				toast.custom(
					t => (
						<ThemeProvider theme={theme}>
							<NotificationContainer>
								<StyledNotificationText>{compilationTitle} added to your folders</StyledNotificationText>

								<StyledNotificationCloseButton onClick={() => toast.dismiss(t.id)}>
									<Close />
								</StyledNotificationCloseButton>
							</NotificationContainer>
						</ThemeProvider>
					),
					{
						duration: 10000,
						canDismiss: true,
					}
				);

				onAdd();
			} catch (error) {
				console.error("error fetching compilation with projects", error);
			} finally {
				// HACK to wait update UI (button removing)
				setTimeout(() => setIsPending(false), 1000);
			}
		},
		[onAdd, compilationId, theme, compilationTitle]
	);

	const buttonContentNode = (
		<>
			<StyledButtonText>Get it</StyledButtonText>
			{isPending && (
				<StyledPreloaderWrapper>
					<Logoloader />
				</StyledPreloaderWrapper>
			)}
		</>
	);

	return (
		<StyledAddButton text={buttonContentNode} primary onClick={handleAdd} isLoading={isPending} design="primary" />
	);
};

export default memo(GetMarketplaceCompilationButton);
