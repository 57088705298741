import React, { memo } from "react";
import Card from "./Card";
import { motion } from "framer-motion";
import noop from "lodash/noop";
import H3 from "./H3";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";
import Caption from "./Caption";
import GetMarketplaceCompilationButton from "./GetMarketplaceCompilationButton";
import Row from "./Row";
import colors from "../tokens/Colors";

const motionDivStyle = {
	height: "auto",
	width: "100%",
	overflow: "visible",
};

const whileHoverStyle = {
	scale: 1.02,
	transition: { ease: [0.2, 0.8, 0.2, 1], duration: 0.3 },
};

const StyledMotionDiv = styled(motion.div)`
	position: relative;
`;

const ThumbnailWrapper = styled.div``;

const TitleWrapper = styled.div`
	margin-bottom: ${whitespace.small};
`;

const DescriptionWrapper = styled.div`
	margin-top: ${whitespace.small};
	margin-bottom: ${whitespace.medium};
	color: ${({ theme }) => theme.secondaryFontColor};
`;

const AddToFoldersWrapper = styled(motion.div)`
	display: ${({ isHidden }) => isHidden && "none"};
	margin-top: ${whitespace.default};

	height: 42px;
`;

const StyledImg = styled.img`
	width: 100%;
	height: 100%;
	position: relative;
	object-fit: cover;
	max-height: 400px;
	min-height: 400px;
	border-radius: 16px;
	overflow: hidden;

	@media (min-width: 800px) {
		max-height: 400px;
	}
`;

const CompilationCard = ({
	id,
	title,
	thumbnailImage,
	compilationDescription,
	isInFolders,
	onAdd = noop,
	onClickCard = noop,
}) => (
	<StyledMotionDiv whileHover={whileHoverStyle} style={motionDivStyle} onClick={onClickCard}>
		<Card cursor="pointer">
			<ThumbnailWrapper>
				<StyledImg src={thumbnailImage} alt={title} />
			</ThumbnailWrapper>

			<Row position="absolute" bottom="0" flexDirection={"column"} justifyContent={"start"} padding={whitespace.medium}>
				<TitleWrapper>
					<H3 color={({ theme }) => theme.primaryFontColor} fontWeight={"500"}>
						{title}
					</H3>
				</TitleWrapper>

				<DescriptionWrapper>
					<Caption style={{ opacity: 0.8 }} color={colors.gray100}>
						{compilationDescription}
					</Caption>
				</DescriptionWrapper>

				<AddToFoldersWrapper isHidden={isInFolders}>
					<GetMarketplaceCompilationButton compilationId={id} compilationTitle={title} onAdd={onAdd} />
				</AddToFoldersWrapper>
			</Row>
		</Card>
	</StyledMotionDiv>
);

export default memo(CompilationCard);
