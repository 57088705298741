import React from "react";
import styled from "styled-components";

import Caption from "./Caption";
import Row from "./Row";
import whitespace from "../tokens/Whitespace";
import Input from "./Input";
import Button from "./Button";
import Keyframes from "../Icons/Keyframes";

const StyledColumn = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: ${whitespace.medium};
	margin-top: ${whitespace.medium};
`;

const StyledButton = styled(Button)`
	font-size: 13px;
	width: 100%;
	padding-left: 0;
	padding-right: 0;
`;

const KeyframedInputsOuter = styled(Row)`
	margin-top: ${whitespace.small};
	gap: ${whitespace.small};
`;

const StyledKeyframedInput = styled(Input)`
	width: 20px;
	height: unset;
	margin-bottom: ${whitespace.medium};
`;

const StyledKeyframedRow = styled(Row)`
	align-items: center;
`;

const InputsRowAnimationVariants = {
	hidden: { opacity: 0, height: 0 },
	visible: { opacity: 1, height: "auto" },
};

const StrokeWidths = ({ strokeWidths, onStrokeChange }) => {
	const [keyframedRevealed, setKeyframedRevealed] = React.useState(null);

	return (
		<>
			<Caption color={({ theme }) => theme.primaryFontColor} marginTop={whitespace.big} fontWeight="600">
				Stroke width
			</Caption>
			<StyledColumn>
				{strokeWidths?.value?.map((strokeWidth, inputIndex) => {
					const isKeyframedStroke = Array.isArray(strokeWidth);
					return (
						<React.Fragment key={`${inputIndex}-${strokeWidth}`}>
							<Row alignItems="center" marginBottom={whitespace.small}>
								<Caption style={{ width: "170px" }} color={({ theme }) => theme.secondaryFontColor}>
									<StyledKeyframedRow>
										<span>{`Stroke ${inputIndex + 1}`}</span>
										{isKeyframedStroke && <Keyframes />}
									</StyledKeyframedRow>
								</Caption>
								{isKeyframedStroke ? (
									<StyledButton
										onClick={() => {
											if (keyframedRevealed === inputIndex) {
												setKeyframedRevealed(null);
												return;
											}
											setKeyframedRevealed(inputIndex);
										}}
										text={keyframedRevealed === inputIndex ? "Hide keyframes" : "Show keyframes"}
									/>
								) : (
									<Input
										type="number"
										value={strokeWidth}
										width="140px"
										onChange={event => onStrokeChange(inputIndex, event.target.value)}
									/>
								)}
							</Row>
							{isKeyframedStroke && (
								<KeyframedInputsOuter
									animate={keyframedRevealed === inputIndex ? "visible" : "hidden"}
									variants={InputsRowAnimationVariants}
									initial="hidden"
									transition={{ duration: 0.2, ease: "easeInOut" }}
								>
									{strokeWidth.map((keyframedStrokeWidth, columnInputIndex) => (
										<StyledKeyframedInput
											key={`${inputIndex}-${columnInputIndex}-${keyframedStrokeWidth}`}
											type="number"
											value={keyframedStrokeWidth}
											onChange={event => onStrokeChange(inputIndex, event.target.value, columnInputIndex)}
										/>
									))}
								</KeyframedInputsOuter>
							)}
						</React.Fragment>
					);
				})}
			</StyledColumn>
		</>
	);
};

export default StrokeWidths;
