import React, { memo, useState, useCallback, useMemo } from "react";
import { useMount } from "ahooks";
import { DashboardWrapper, FolderWrapper, frameVariants, itemVariants } from "./styled";
import Container from "../layout/Container";
import Row from "../layout/Row";
import whitespace from "../tokens/Whitespace";
import H1 from "../layout/H1";
import Body from "../layout/Body";
import GridWrapper from "../layout/GridWrapper";
import Grid from "../layout/Grid";
import EmptyList from "../layout/EmptyList";
import withAuth from "../hocs/withAuth";
import { functions } from "../../config/fbConfig";
import CompilationCard from "../layout/CompilationCard";
import Modal from "../layout/Modal";
import CompilationAdd from "../layout/CompilationAdd";
import Logoloader from "../layout/Logoloader";

const getMarketplaceCompilations = functions.httpsCallable("getMarketplaceCompilations");

const useMarketplaceList = () => {
	const [list, setList] = useState([]);
	const [isInitialized, setIsInitialized] = useState(false);

	const fetch = useCallback(async () => {
		try {
			const { data } = await getMarketplaceCompilations();
			setList(data);
		} catch (error) {
			console.error("error fetching compilations", error);
		} finally {
			setIsInitialized(true);
		}
	}, []);

	const handleCompilationAdded = useCallback(
		async compilationId => {
			setList(prevList => {
				const compilationIndex = prevList.findIndex(it => it.id === compilationId);

				if (compilationIndex < 0) {
					return prevList;
				}

				return prevList.map((item, index) => {
					if (index !== compilationIndex) {
						return item;
					}

					return {
						...item,
						isInFolders: true,
					};
				});
			});

			await fetch();
		},
		[fetch]
	);

	useMount(() => fetch());

	return {
		isInitialized,
		list,
		handleCompilationAdded,
	};
};

const Marketplace = () => {
	const [compilationToAddId, setCompilationToAddId] = useState(null);

	const { isInitialized, list: marketplaceCompilations, handleCompilationAdded } = useMarketplaceList();

	const isEmpty = marketplaceCompilations.length === 0;

	const marketplaceCompilationsNode = useMemo(
		() =>
			marketplaceCompilations.map(it => (
				<FolderWrapper variants={itemVariants}>
					<CompilationCard
						key={it.id}
						id={it.id}
						title={it.compilationTitle}
						thumbnailImage={it.thumbnailImage}
						compilationDescription={it.compilationDescription}
						isInFolders={it.isInFolders}
						onClickCard={() => setCompilationToAddId(it.id)}
						onAdd={() => handleCompilationAdded(it.id)}
					/>
				</FolderWrapper>
			)),
		[marketplaceCompilations, handleCompilationAdded]
	);

	const handleCloseAddCompilationPopup = useCallback(() => setCompilationToAddId(null), []);

	const handleAddCompilation = useCallback(async () => {
		setCompilationToAddId(null);
		await handleCompilationAdded(compilationToAddId);
	}, [handleCompilationAdded, compilationToAddId]);

	return (
		<>
			<Modal isToggled={compilationToAddId != null} action={handleCloseAddCompilationPopup} padding={whitespace.large}>
				<CompilationAdd compilationId={compilationToAddId} onAdd={handleAddCompilation} />
			</Modal>

			<div
				style={{
					height: "100%",
					width: "100%",
					display: "flex",
					flexWrap: "nowrap",
					justifyContent: "center",
				}}
			>
				<DashboardWrapper>
					<Container>
						<Row justifyContent="space-between" paddingTop={whitespace.xlarge} paddingBottom={whitespace.big}>
							<Row width="100%" align-items="start" flexDirection="row" paddingRight={whitespace.default}>
								<Row flexDirection="column">
									<H1 fontWeight="600" textAlign="left">
										Marketplace
									</H1>

									<Body marginTop={whitespace.medium} color={({ theme }) => theme.primaryFontColor}>
										Here you can find animations collections
										<br />
										that you can use for free in your projects
									</Body>
								</Row>
							</Row>
						</Row>
					</Container>

					{isInitialized ? (
						<GridWrapper>
							<Container>
								<Row paddingTop={whitespace.large} paddingBottom={"100px"}>
									{!isEmpty ? (
										<Grid variants={frameVariants} initial="hidden" animate="visible">
											{marketplaceCompilationsNode}
										</Grid>
									) : (
										<EmptyList />
									)}
								</Row>
							</Container>
						</GridWrapper>
					) : (
						<Row height={"100%"} alignItems={"center"}>
							{/* <Row width="64px" height="96px">
								<PageAnimationPlayer src="https://jsonproxy.web.app/json/62b6b250-0f57-40fe-a1a2-1e64c0e23fc5.json" />
							</Row> */}
							<Logoloader />
						</Row>
					)}
				</DashboardWrapper>
			</div>
		</>
	);
};

export default memo(withAuth(Marketplace));
