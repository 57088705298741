import colors from "./tokens/Colors";

export const lightTheme = {
	primaryFontColor: colors.gray900,
	secondaryFontColor: colors.gray700,

	primaryButtonTextColorDefault: colors.gray0,
	primaryButtonBackgroundColorDefault: colors.primary,
	primaryButtonBackgroundColorHover: colors.primary400,

	secondaryButtonTextColorDefault: colors.primary600,
	secondaryButtonBackgroundColorDefault: colors.primary150,
	secondaryButtonBackgroundColorHover: colors.primary100,

	basicButtonTextColorDefault: colors.gray900,
	basicButtonBackgroundColorDefault: colors.gray200,

	destructiveButtonTextColorDefault: colors.gray0,

	linkColorDefault: colors.primary,

	checkboxBackgroundColorDefault: colors.gray100,
	checkboxBackgroundColorActive: colors.primary,

	highlight: colors.primary50,
	selectMenuBackgroundColor: colors.gray50,

	surface: colors.gray100,
	surfacePressed: colors.gray200,
	surfacevariant: colors.gray100,
	surfaceFloating: colors.gray0,

	iconButtonPressed: colors.gray300,

	cardBackgroundColorDefault: colors.gray50,
	cardBackgroundColorHover: colors.gray0,
	cardShadowColor: colors.shadowforlight,

	tagTextColorDefault: colors.primary700,
	tagBackgroundColorDefault: colors.primary100,

	sidePanelBackgroundColor: colors.gray75,

	sidebarBackgroundColor: colors.gray0,
	sidebarIconBackgroundColorHover: colors.primary50,
	sidebarIconColorDefault: colors.gray500,
	sidebarIconColorActive: colors.primary,

	inputBackgroundColorDefault: colors.gray75,
	inputBorderColorDefault: colors.gray300,
	inputBorderColorActive: colors.primary,

	tooltip: colors.gray900,
	border: colors.gray300,
	divider: colors.gray200,

	destructive: colors.red,
	destructiveContrast: colors.gray0,

	green: colors.green,

	pageAnimationPlayerBackground: colors.gray50,

	tooltipBackgroundColor: colors.gray900,
	tooltipTextColor: colors.gray0,

	orange: colors.orange,
	ruby: colors.ruby,
	blue: colors.blue,
	yellow: colors.yellow,

	websiteBackground: colors.gray0,
	websiteGradient: colors.gradientlight,
};
export const darkTheme = {
	primaryFontColor: colors.gray0,
	secondaryFontColor: colors.gray500,

	primaryButtonTextColorDefault: colors.gray0,
	primaryButtonBackgroundColorDefault: colors.primary400,
	primaryButtonBackgroundColorHover: colors.primary400,

	secondaryButtonTextColorDefault: colors.primary300,
	secondaryButtonBackgroundColorDefault: colors.gray700,
	secondaryButtonBackgroundColorHover: colors.gray600,

	basicButtonTextColorDefault: colors.gray200,
	basicButtonBackgroundColorDefault: colors.gray700,

	destructiveButtonTextColorDefault: colors.gray900,

	linkColorDefault: colors.primary300,

	highlight: colors.gray550,
	selectMenuBackgroundColor: colors.gray600,

	checkboxBackgroundColorDefault: colors.gray700,
	checkboxBackgroundColorActive: colors.primary400,

	surface: colors.gray900,
	surfacePressed: colors.gray600,
	surfacevariant: colors.gray700,
	surfaceFloating: colors.gray800,

	iconButtonPressed: colors.gray550,

	cardBackgroundColorDefault: colors.gray800,
	cardBackgroundColorHover: colors.gray700,
	cardShadowColor: colors.shadowfordark,

	tagTextColorDefault: colors.gray0,
	tagBackgroundColorDefault: colors.gray900,

	sidePanelBackgroundColor: colors.gray825,

	sidebarBackgroundColor: colors.gray850,
	sidebarIconBackgroundColorHover: colors.gray800,
	sidebarIconColorDefault: colors.gray500,
	sidebarIconColorActive: colors.primary400,

	inputBackgroundColorDefault: colors.gray700,
	inputBorderColorDefault: colors.gray550,
	inputBorderColorActive: colors.primary400,

	tooltip: colors.gray0,
	border: colors.gray550,
	divider: colors.gray700,

	destructive: colors.red500,
	destructiveContrast: colors.gray1000,

	green: colors.green300,

	pageAnimationPlayerBackground: colors.gray800,

	tooltipBackgroundColor: colors.gray600,
	tooltipTextColor: colors.gray300,

	orange: colors.orange300,
	ruby: colors.ruby300,
	blue: colors.blue300,
	yellow: colors.yellow300,

	websiteBackground: colors.gray1000,
	websiteGradient: colors.gradientdark,
};
