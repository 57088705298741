import styled from "styled-components";
import { motion } from "framer-motion";

const Row = styled(motion.div)`
	opacity: ${props => props.opacity};
	width: ${props => props.width};
	max-width: ${props => props.maxWidth};
	min-width: ${props => props.minWidth};
	height: ${props => props.height};
	margin-top: ${props => props.marginTop};
	margin-bottom: ${props => props.marginBottom};
	margin-left: ${props => props.marginLeft};
	margin-right: ${props => props.marginRight};
	flex-grow: ${props => props.flexGrow};
	display: flex;
	justify-content: ${props => props.justifyContent};
	flex-direction: ${props => props.flexDirection};
	flex-wrap: ${props => props.flexWrap};
	align-items: ${props => props.alignItems};
	padding: ${props => props.padding};
	padding-top: ${props => props.paddingTop};
	padding-bottom: ${props => props.paddingBottom};
	padding-left: ${props => props.paddingLeft};
	padding-right: ${props => props.paddingRight};
	background-color: ${props => props.backgroundColor};
	border-radius: ${props => props.borderRadius};
	color: ${props => props.color};
	overflow: ${props => props.overflow};
	position: ${props => props.position};
	bottom: ${props => props.bottom};
`;

export default Row;
