import { getColors, getStrokeWidths } from "../../Components/utils/lottie";

async function fileToJSON(file) {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onload = event => resolve(JSON.parse(event.target.result));
		fileReader.onerror = error => reject(error);
		fileReader.readAsText(file);
	});
}

export const extractLottieMeta = file => {
	return fileToJSON(file).then(animation => {
		const colors = getColors(animation);
		const strokeWidths = getStrokeWidths(animation);
		const { w, h } = animation;
		const size = {
			width: w,
			height: h,
			multiplier: 1,
		};

		return {
			animation,
			colors,
			size,
			strokeWidths,
		};
	});
};
