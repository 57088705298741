import * as React from "react";
import fontsize from "../tokens/FontSizes";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";

const H3 = styled.div`
	width: 100%;
	display: flex;

	color: ${props => props.color};
	text-align: ${props => props.textAlign};
	font-weight: ${props => props.fontWeight};
	font-size: ${({ fontSize = fontsize.h3 }) => fontSize};
	padding-top: ${whitespace.small};
	margin-top: ${props => props.marginTop};
	margin-bottom: ${props => props.marginBottom};
	margin-left: ${props => props.marginLeft};

	@media (max-width: 800px) {
	}
`;

export function Headline3({ color, fontWeight, textAlign, fontSize, marginTop, marginBottom, marginLeft, textShadow }) {
	return <H3></H3>;
}

export default H3;
