import * as React from "react";

const Loadericon = ({ styles = {}, ...props }) => (
    <svg height="32px" viewBox="0 0 64 64" width="32px" {...props}>
        <g fill="currentColor" fillRule="nonzero">
            <path
                fill="currentColor"
                d="M50.3,9l0.3,0c2.4,0.1,4.3,2.1,4.4,4.5l0,0.2v2l0,0.3c-0.1,1.2-0.6,2.2-1.4,3l-0.1,0.1l-9.6,8.5
	c-0.2,0.2-0.4,0.3-0.5,0.5c-2.2,2.5-2.1,5.7,0.4,8l0.1,0.1l9.6,8.5c0.9,0.8,1.5,2,1.6,3.3l0,0.2v2c0,2.5-2,4.6-4.5,4.7l-0.2,0H13.7
	C11.1,55,9,52.9,9,50.3v-2c0-1.3,0.6-2.6,1.6-3.5l9.2-8.5c2.6-2.3,2.8-5.6,0.5-8.2c-0.2-0.2-0.3-0.4-0.5-0.5l-9.2-8.5
	c-0.9-0.8-1.5-2-1.6-3.2l0-0.3v-2c0-2.5,1.9-4.6,4.4-4.7l0.3,0H50.3z"
            />
        </g>
    </svg>
);

export default Loadericon;
