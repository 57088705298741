import * as React from "react";

const ContextMenu = ({ styles = {}, ...props }) => (
	<svg height="32px" viewBox="0 0 32 32" width="32px" {...props}>
		<g fill="currentColor" fillRule="nonzero">
			<path d="M8.5,14 C9.88071187,14 11,15.1192881 11,16.5 C11,17.8807119 9.88071187,19 8.5,19 C7.11928813,19 6,17.8807119 6,16.5 C6,15.1192881 7.11928813,14 8.5,14 Z M16.5,14 C17.8807119,14 19,15.1192881 19,16.5 C19,17.8807119 17.8807119,19 16.5,19 C15.1192881,19 14,17.8807119 14,16.5 C14,15.1192881 15.1192881,14 16.5,14 Z M24.5,14 C25.8807119,14 27,15.1192881 27,16.5 C27,17.8807119 25.8807119,19 24.5,19 C23.1192881,19 22,17.8807119 22,16.5 C22,15.1192881 23.1192881,14 24.5,14 Z" />
		</g>
	</svg>
);

export default ContextMenu;
