import * as React from "react";

import { motion } from "framer-motion";

import Caption from "../../layout/Caption";
import RenderedLink from "../../layout/RenderedLink";
import Row from "../../layout/Row";
import Processing from "../../layout/Processing";
import Button from "../../layout/Button";

import whitespace from "../../tokens/Whitespace";
import { FILES_API_ENDPOINT } from "../../../config/fbConfig";

const Links = ({ project, onConfirm, onCancel, hasChanges, isUpdatingJson }) => {
	const getFileUrl = fileName => `${FILES_API_ENDPOINT}/${fileName}`;

	return (
		<Row flexDirection="column" marginBottom={whitespace.small} padding={whitespace.medium}>
			{hasChanges || isUpdatingJson ? (
				<motion.div
					transition={{ duration: 0.3 }}
					initial={{ opacity: 0, y: 30 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 30 }}
				>
					<Row
						backgroundColor={({ theme }) => theme.cardBackgroundColorDefault}
						borderRadius="12px"
						padding={whitespace.medium}
						marginBottom={whitespace.medium}
						flexDirection="column"
					>
						<Caption color={({ theme }) => theme.primaryFontColor}>
							You've made changes in this file. Would you like to keep new settings and update links?
						</Caption>
						<Button
							marginTop={whitespace.medium}
							text={"Yes, update links"}
							design={"primary"}
							onClick={onConfirm}
							disabled={isUpdatingJson || project.isProcessing}
						/>
						<Button
							marginTop={whitespace.medium}
							text={"No, undo changes"}
							onClick={onCancel}
							disabled={isUpdatingJson || project.isProcessing}
						/>
					</Row>
				</motion.div>
			) : (
				<Row flexDirection="column">
					<Caption fontWeight="600" color={({ theme }) => theme.primaryFontColor}>
						Exported links
					</Caption>
					<Row flexDirection="column" marginTop={whitespace.medium}>
						{project.json && (
							<RenderedLink
								type="JSON"
								value={project.filePath ? getFileUrl(project.filePath) : project.json}
								name={`${project.title}.json`}
							/>
						)}

						{project.isProcessing ? (
							<Processing />
						) : (
							<>
								{project.mpegUrl && <RenderedLink type="MP4" value={project.mpegUrl} name={`${project.title}.mp4`} />}
							</>
						)}
					</Row>
				</Row>
			)}
		</Row>
	);
};

export default Links;
