const fontsize = {
    heroheadline: "48px",
    h1: "34px",
    h2: "26px",
    h3: "20px",
    body: "16px",
    caption: "14px",
};

export default fontsize;
