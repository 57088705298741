import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";
import Typologo from "../Icons/Typologo";
import { motion } from "framer-motion";
import Button from "./Button";
import Row from "./Row";
import Duo from "../Icons/Duo";

const NavigationMenu = styled.div`
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 80px;
    display: flex;
    background-color: ${({ theme }) => theme.websiteBackground};
    color: ${({ theme }) => theme.primaryFontColor};
    align-items: center;
    justify-content: center;
`;

const SidebarIcon = styled.div`
    margin: ${(props) => props.margin};
    margin-right: 16px;
    height: 45px;
    width: 48px;
    color: ${(props) => props.color};
    padding-top: 3px;
    justify-content: center;
    display: flex;
    align-items: center;
    position: ${(props) => props.position};
    cursor: pointer;
    border-radius: 12px;
    transition: 0.15s cubic-bezier(0.2, 0.8, 0.2, 1);
    float: ${(props) => props.float};
    opacity: ${(props) => props.opacity};

    &:hover {
        background: ${({ theme }) => theme.sidebarIconBackgroundColorHover};
    }

    &:focus {
    }
    @media (max-width: 420px) {
        margin-right: 14px;
        height: 40px;
        width: 42px;
    }
`;

class WebsiteMenu extends Component {
    render() {
        const { auth } = this.props;

        const links = !auth.uid ? (
            <div>
                <NavigationMenu>
                    <Row
                        paddingLeft={whitespace.large}
                        paddingRight={whitespace.large}
                        width="100%"
                        maxWidth="1300px"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                ease: [0.2, 0.8, 0.2, 1],
                                duration: 1,
                            }}
                        >
                            <a href="https://moko-app.com/">
                                <Row
                                    color={({ theme }) =>
                                        theme.primaryFontColor
                                    }
                                >
                                    <Typologo />
                                </Row>
                            </a>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                ease: [0.2, 0.8, 0.2, 1],
                                duration: 1,
                                delay: 0.2,
                            }}
                        >
                            <Row alignItems="center">
                                <SidebarIcon
                                    color={({ theme }) =>
                                        theme.sidebarIconColorDefault
                                    }
                                    onClick={this.props.toggleTheme}
                                >
                                    <Duo />
                                </SidebarIcon>

                                <NavLink to="/signin">
                                    <Button
                                        marginRight={whitespace.medium}
                                        text="Log in"
                                    ></Button>
                                </NavLink>
                                <NavLink to="/signup">
                                    <Button
                                        design="primary"
                                        text="Sign up"
                                    ></Button>
                                </NavLink>
                            </Row>
                        </motion.div>
                    </Row>
                </NavigationMenu>
            </div>
        ) : null;

        return <div>{links}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
    };
};

export default compose(connect(mapStateToProps), withRouter)(WebsiteMenu);
