import * as React from "react";

import styled from "styled-components";
import { motion } from "framer-motion";
import Dropzone from "react-dropzone";

import Row from "../layout/Row";
import Image from "../layout/Image";
import PageAnimationPlayer from "../layout/PageAnimationPlayer";
import fontsize from "../tokens/FontSizes";
import whitespace from "../tokens/Whitespace";
import H2 from "../layout/H2";
import Button, { ButtonBasic } from "../layout/Button";
import Input from "../layout/Input";
import Tags from "../layout/Tags";
import ProjectErrors from "../../errors";

import { uploadFile, getPredictedFilePath, getFileExtenstion } from "../utils/file";

const StyledButton = styled(ButtonBasic)`
	width: fit-content;
`;

const FormContainer = styled.div`
	justify-content: center;
	display: flex;
	flex-direction: row;
	margin: auto;
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	width: 800px;
	position: relative;
`;

const LeftModule = styled.div`
	justify-content: center;
	display: flex;
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	width: 400px;
	position: relative;
	align-items: center;
	background-color: ${({ theme }) => theme.surface};
`;

const Label = styled.label`
	display: flex;
	font-size: ${fontsize.body};
	text-align: left;
	color: ${({ theme }) => theme.secondaryFontColor};
	margin-bottom: ${whitespace.default};
`;

const Caption = styled.label`
	display: flex;
	font-size: ${fontsize.caption};
	text-align: left;
	color: ${({ theme }) => theme.secondaryFontColor};
	margin-bottom: ${whitespace.default};
`;

const AdditionalDescriptionLabel = styled(Label)`
	display: flex;
	opacity: 0.7;
	margin-left: ${whitespace.small};
	text-align: left;
	color: ${({ theme }) => theme.primaryFontColor};
	margin-bottom: ${whitespace.default};
`;

const ValidationError = styled.div`
	width: fit-content;
	margin-top: 8px;
	color: ${({ theme }) => theme.destructive};
	text-align: left;
	font-size: ${fontsize.caption};
`;

const VisualBlock = styled.div`
	overflow: hidden;
	object-fit: cover;
	align-items: center;
	justify-content: center;
	display: flex;
	margin-right: ${props => props.marginRight};
	border-radius: 18px;
	width: 300px;
	height: 300px;
	background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
		linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%),
		linear-gradient(-45deg, transparent 75%, #808080 75%);
	background-size: 20px 20px;
	background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
	position: relative;
`;

const DropZoneBlock = styled(motion.div)`
	overflow: hidden;
	border: 2px dashed ${({ theme }) => theme.border};
	color: ${props => props.color};
	background-color: ${props => props.backgroundColor};
	text-align: center;
	object-fit: cover;
	align-items: center;
	justify-content: center;
	display: flex;
	margin-right: ${props => props.marginRight};
	border-radius: 18px;
	width: 300px;
	height: 300px;
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const getFormErrors = formData => {
	const errors = {};

	if (!formData.json) {
		errors.json = "JSON is required!";
	}

	if (!formData.title) {
		errors.title = "Project name is required!";
	}

	return errors;
};

const errorsBase = {
	title: null,
	json: null,
	tags: null,
};

const formBase = {
	title: "",
	tags: "",
	filePath: "",
	json: "",
};

const ProjectForm = ({ initialData = formBase, isEdit, folderId, onSubmit, className, children }) => {
	const [formData, setFormData] = React.useState(initialData);
	const [errors, setErrors] = React.useState(errorsBase);
	const [preview, setPreview] = React.useState({
		file: null,
		url: "",
	});
	const [isUploading, setIsUploading] = React.useState(false);

	const handleChangeFileDrop = files => {
		setErrors(errorsBase);

		if (Array.isArray(files) && !files.length) {
			setErrors({ json: "JSON is required!" });
			return;
		}

		const file = files[0];
		const fileName = file?.name.split(".")[0];
		const url = URL.createObjectURL(file);
		setPreview({ file, url });
		if (!isEdit) {
			const { filePath, predictedUrl } = getPredictedFilePath(file);
			setFormData(current => ({ ...current, filePath, json: predictedUrl, title: fileName }));
		}
	};

	const handleChangeFile = event => {
		setErrors(errorsBase);
		const file = event.target.files[0];
		const fileName = file.name.split(".")[0];
		const url = URL.createObjectURL(file);
		setPreview({ file, url });
		if (!isEdit) {
			const { filePath, predictedUrl } = getPredictedFilePath(file);
			setFormData(current => ({ ...current, filePath, json: predictedUrl, title: fileName }));
		}
	};

	const handleChange = ({ field, value }) => {
		setFormData(current => ({ ...current, [field]: value }));
		setErrors(errorsBase);
	};

	const handleChangeTags = event => {
		if (!event.target.validity.valid) {
			return;
		}

		handleChange({ field: "tags", value: event.target.value });
	};

	const handleSubmit = event => {
		event.preventDefault();
		const formErrors = getFormErrors(formData);

		if (Object.keys(formErrors).length) {
			setErrors(formErrors);
			return;
		}

		if (preview.file) {
			setIsUploading(true);
			const extension = getFileExtenstion(preview.file);
			return uploadFile({ file: preview.file, path: formData.filePath, extension })
				.then(([json, uploadedFilePath]) => {
					setIsUploading(false);
					onSubmit({
						...formData,
						json,
						extension,
						preview,
						folder: folderId,
						filePath: uploadedFilePath,
						backgroundColor: "#FFFFFF", // TODO
					});
				})
				.catch(error => {
					setIsUploading(false);

					if (error instanceof ProjectErrors) {
						if (error.name === "INVALID_FILE_TYPE") {
							setErrors({ json: "JSON is required!" });
						}
					}
				});
		}

		if (formData.json) {
			setIsUploading(true);
			fetch(formData.json).then(async response => {
				const fileBlob = await response.blob();
				const extension = formData.json
					.substring(formData.json.lastIndexOf(".") + 1)
					.toLowerCase()
					.split("?")[0];
				uploadFile({ file: fileBlob, path: formData.filePath, extension })
					.then(([json, uploadedFilePath]) => {
						setIsUploading(false);
						onSubmit({
							...formData,
							json,
							extension,
							preview: {
								file: fileBlob,
							},
							folder: folderId,
							filePath: uploadedFilePath,
							backgroundColor: "#FFFFFF", // TODO
						});
					})
					.catch(error => {
						setIsUploading(false);

						if (error instanceof ProjectErrors) {
							if (error.name === "INVALID_FILE_TYPE") {
								setErrors({ json: "JSON is required!" });
							}
						}
					});
			});
		}
	};

	return (
		<FormContainer className={className}>
			<LeftModule>
				{formData.json || preview.url ? (
					<VisualBlock>
						<Image height="90%" width="90%" img={preview.url || formData.json} />
						<PageAnimationPlayer position="absolute" src={preview.url || formData.json} />
					</VisualBlock>
				) : (
					<Dropzone onDrop={handleChangeFileDrop} accept={"application/JSON"}>
						{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
							<DropZoneBlock
								whileHover={{
									scale: 1.05,
									transition: { ease: [0.2, 0.8, 0.2, 1], duration: 0.3 },
								}}
								whileTap={{ scale: 0.95 }}
								backgroundColor={isDragReject ? ({ theme }) => theme.destructive : "none"}
								color={isDragReject ? ({ theme }) => theme.destructiveContrast : ({ theme }) => theme.primaryFontColor}
								{...getRootProps()}
							>
								<input
									{...getInputProps()}
									accept={"application/JSON"}
									multiple={false}
									type="file"
									onChange={handleChangeFile}
								/>
								{!isDragActive && "Click here or drop a file to upload!"}
								{isDragActive && !isDragReject && "Drop it like it's hot! 😎"}
								{isDragReject && "Only JSON!"}
							</DropZoneBlock>
						)}
					</Dropzone>
				)}
			</LeftModule>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					flexGrow: "1",
					padding: "24px",
				}}
			>
				<H2 fontWeight="600" textAlign="center" width="100%">
					{!isEdit ? "Add new file" : "File details"}
				</H2>

				{children}

				<Row flexDirection="column" marginTop={whitespace.even}>
					<StyledButton as={"label"} for={"file-input"}>
						Upload from computer
					</StyledButton>
					<input
						accept={"application/JSON"}
						id={"file-input"}
						type="file"
						onChange={handleChangeFile}
						style={{ display: "none" }}
					/>
				</Row>

				<Row flexDirection="column" marginTop={whitespace.medium}>
					<Caption>or paste a JSON/Lottie file link</Caption>
				</Row>

				<Row flexDirection="column" marginTop={whitespace.medium}>
					<Label htmlFor="json">File link</Label>
					<Input
						placeholder="Paste the file url here"
						type="text"
						id="json"
						onChange={ev => handleChange({ field: "json", value: ev.target.value })}
						value={formData.json}
					/>

					{errors.json && (
						<ValidationError>
							<span>{errors.json}</span>
						</ValidationError>
					)}
				</Row>

				<Row flexDirection="column" marginTop={whitespace.big} marginBottom={whitespace.big}>
					<Label htmlFor="title">Title</Label>
					<Input
						placeholder="Come up with some nifty title"
						type="text"
						id="title"
						onChange={ev => handleChange({ field: "title", value: ev.target.value })}
						value={formData.title}
					/>
					{errors.title && (
						<ValidationError>
							<span>{errors.title}</span>
						</ValidationError>
					)}
				</Row>

				<Row flexDirection="column" marginBottom={whitespace.big}>
					<Label htmlFor="tags">
						<Label>Tags</Label>
						<AdditionalDescriptionLabel>(separate with comma)</AdditionalDescriptionLabel>
					</Label>
					<Row>
						<Input
							style={{ color: "rgba(255, 0, 0, 0)", outline: "none" }}
							placeholder="just,like,this"
							type="text"
							id="tags"
							onChange={handleChangeTags}
							pattern={"^\\w{0,10}(,\\w{0,10}){0,2}$"}
							value={formData.tags}
						/>
						<Row position="absolute" paddingBottom="6px">
							<Tags tags={formData.tags} />
						</Row>
					</Row>

					{/* {errors.tags && (
						<ValidationError>
							<span>{errors.tags}</span>
						</ValidationError>
					)} */}
				</Row>

				<Button
					marginTop={whitespace.default}
					onClick={handleSubmit}
					design="primary"
					disabled={isUploading}
					text={isEdit ? "Update" : "Add"}
				/>
			</div>
		</FormContainer>
	);
};

export default ProjectForm;
