import * as React from "react";
import styled from "styled-components";

const ContentWraper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: none;
    background-color: ${(props) => props.backgroundColor};
    padding: ${(props) => props.padding};
`;

export function ContentWraping({ backgroundColor, padding }) {
    return <ContentWraper></ContentWraper>;
}

export default ContentWraper;
