import React, { memo } from "react";

const Confirmed = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" {...props}>
			<path d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M18.722839,8.30898357 C18.370558,7.94047927 17.8040945,7.89999294 17.405039,8.19611326 L17.3089836,8.27716095 L13.9562995,11.4822448 L10.986,14.571 L7.70710678,11.2928932 L7.61289944,11.2097046 C7.22060824,10.9046797 6.65337718,10.9324093 6.29289322,11.2928932 C5.93240926,11.6533772 5.90467972,12.2206082 6.20970461,12.6128994 L6.29289322,12.7071068 L10.2928932,16.7071068 L10.3884534,16.7913532 C10.7532999,17.0740819 11.2679494,17.0689789 11.6271181,16.7790713 L11.7209889,16.6929466 L15.3683049,12.8980305 L18.6910164,9.72283905 L18.7763038,9.63052747 C19.0900788,9.24519926 19.0751201,8.67748786 18.722839,8.30898357 Z" />
		</svg>
	);
};

export default memo(Confirmed);
