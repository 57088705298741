import styled from "styled-components";
import { motion } from "framer-motion";

const GridWrapper = styled(motion.div)`
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export default GridWrapper;
