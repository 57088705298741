import * as React from "react";

const Typologo = ({ styles = {}, ...props }) => (
    <svg height="32px" viewBox="0 0 147 38" width="80px" {...props}>
        <g fill="currentColor" fillRule="nonzero">
            <path
                fill="currentColor"
                d="M5.4,0.5l0.2,0C6.6,0.6,7.4,1,8.1,1.7l0.1,0.1L15,9.5c0.1,0.1,0.3,0.3,0.4,0.4
	c2,1.8,5.1,1.7,7-0.3l0.1-0.1l6.8-7.7C30,1,31,0.5,32,0.5l0.1,0h1.6c2.1,0,3.7,1.6,3.8,3.7l0,0.1v30c0,2.1-1.7,3.8-3.8,3.8h-1.6
	c-1.1,0-2.1-0.5-2.8-1.3L22.5,29c-1.8-2.1-5-2.3-7.1-0.4c-0.1,0.1-0.3,0.3-0.4,0.4l-6.8,7.7c-0.7,0.7-1.6,1.2-2.6,1.3l-0.2,0H3.8
	c-2,0-3.7-1.6-3.8-3.6l0-0.2v-30l0-0.2c0.1-1.9,1.7-3.5,3.7-3.5l0.1,0H5.4z M102.6,0c2.1,0,3.8,1.7,3.8,3.8c0,1-0.4,1.9-1,2.6
	l-9.5,10c-0.7,0.7-1,1.6-1.1,2.5c0,0.9,0.3,1.7,0.9,2.4l0.1,0.1l9.5,10c0.7,0.7,1,1.6,1,2.6c0,2-1.6,3.7-3.6,3.8l-0.1,0h-8
	c-0.9,0-1.8-0.4-2.5-1l-0.1-0.1L80,24.1c-0.6,7.8-7.1,13.9-15,13.9h-7.5c-8.3,0-15-6.8-15-15.1v-7.5c0-8.3,6.7-15.1,15-15.1h7.5
	c7.7,0,14.1,5.8,14.9,13.4l12-12.5C92.6,0.4,93.6,0,94.6,0H102.6z M132,0c8.3,0,15,6.8,15,15.1v7.5c0,8.3-6.7,15.1-15,15.1h-7.5
	c-8.3,0-15-6.8-15-15.1v-7.5c0-8.3,6.7-15.1,15-15.1H132z"
            />
        </g>
    </svg>
);

export default Typologo;
