import styled from "styled-components";

export const ListingCardWrapper = styled.div`
    flex-grow: 1;
    background-color: ${(props) => props.backgroundColor};
    height: 100%;
    max-height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
