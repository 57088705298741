import { v4 as uuidv4 } from "uuid";
import LottieParser from "lottie-web-parser";

import get from "lodash.get";

const rgbToHex = (r, g, b) => `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

const componentToHex = c => {
	const hex = c.toString(16);
	return hex.length === 1 ? `0${hex}` : hex;
};

const isNumbersList = list => !list.some(isNaN);

const getKeyframeColors = (path, lottieObject) => {
	const parsedKeyFramesElements = LottieParser.getKeyframeColors(path, lottieObject);
	if (!parsedKeyFramesElements) {
		return;
	}

	return parsedKeyFramesElements.map(parsedKeyFrame => {
		const [r, g, b, a] = parsedKeyFrame;

		if (!isNumbersList(parsedKeyFrame)) {
			return;
		}

		const color = rgbToHex(r, g, b);

		return {
			id: uuidv4(),
			a,
			rgba: parsedKeyFrame,
			color,
		};
	});
};

function parseStrokeWidths(lottieObject) {
	const parsedElements = [];

	if (!lottieObject.layers) {
		return parsedElements;
	}

	lottieObject.layers.forEach((layer, layerIndex) => {
		if (!layer.shapes) {
			return;
		}

		layer.shapes.forEach((shape, shapeIndex) => {
			if (!shape.it) {
				return;
			}

			shape.it.forEach((stroke, itIndex) => {
				if (stroke.w) {
					const isKeyFramed = Array.isArray(stroke.w.k);
					parsedElements.push({
						shapeDetails: { shapeIndex, itIndex, layerIndex },
						strokeWidth: isKeyFramed ? stroke.w.k.map(({ s }) => s[0]) : parseInt(stroke.w.k),
					});
				}
			});
		});
	});

	return parsedElements;
}

export const getStrokeWidths = (lottieObject = {}) => {
	if (!lottieObject?.layers) {
		return;
	}

	const filteredLottieObject = {
		...lottieObject,
		layers: lottieObject.layers.filter(({ cl }) => !cl),
	};

	let parsedElements = parseStrokeWidths(filteredLottieObject);

	parsedElements = parsedElements.sort((a, b) => {
		const isAKeyframed = a.strokeWidth instanceof Array;
		const isBKeyframed = b.strokeWidth instanceof Array;
		return isBKeyframed - isAKeyframed;
	});

	const StrokeWidths = parsedElements?.map(({ strokeWidth }) => strokeWidth);

	const uniqueStrokeWidths = Array.from(
		new Set(StrokeWidths.map(item => (typeof item === "object" ? JSON.stringify(item) : item)))
	).map(item => (typeof item === "string" ? JSON.parse(item) : item));

	return {
		value: uniqueStrokeWidths,
		itemList: parsedElements,
	};
};

export const getColors = (lottieObject = {}) => {
	const elementsByColors = {};

	if (!lottieObject.layers) {
		return;
	}

	const filteredLottieObject = {
		...lottieObject,
		layers: lottieObject.layers.filter(({ cl }) => !cl),
		assets: [],
	};

	const parsedElements = LottieParser.parseColors(filteredLottieObject);
	parsedElements.forEach(({ shapes }) => {
		shapes.forEach(({ path, rgba }) => {
			const keyFramedColors = getKeyframeColors(path, filteredLottieObject);
			if (keyFramedColors) {
				const keyFramedColors = getKeyframeColors(path, filteredLottieObject);
				keyFramedColors.forEach(({ a, rgba, color }, index) => {
					const newItem = {
						a,
						rgba,
						color,
						path,
						index,
					};

					if (elementsByColors[color]) {
						elementsByColors[color].push(newItem);
					} else {
						elementsByColors[color] = [newItem];
					}
				});
				return;
			}

			const [r, g, b, a] = rgba;
			const color = rgbToHex(r, g, b);

			const newItem = {
				a,
				rgba,
				color,
				path,
			};

			if (elementsByColors[color]) {
				elementsByColors[color].push(newItem);
			} else {
				elementsByColors[color] = [newItem];
			}
		});
	});

	const elementsWithColors = Object.entries(elementsByColors).map(([color, itemList]) => ({
		id: uuidv4(),
		color,
		itemList,
	}));

	return elementsWithColors;
};

const hexColorRegexp = /^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/;

export const isHexColor = color => hexColorRegexp.test(color);

const hexComponentsRegexp = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

const hexToComponents = hex => hexComponentsRegexp.exec(hex);

export const hexToRgb = hex => {
	const rgb = hexToComponents(hex);

	return rgb
		? {
				r: parseInt(rgb[1], 16),
				g: parseInt(rgb[2], 16),
				b: parseInt(rgb[3], 16),
		  }
		: {
				r: 0,
				g: 0,
				b: 0,
		  };
};

export const toUnitVector = n => Math.round((n / 255) * 1000) / 1000;

export const updateAnimationElementsWithColor = (animation, color) => {
	const newAnimation = { ...animation };
	const { value: newColor, itemList, elementList } = color;
	const { r, g, b } = hexToRgb(newColor);

	const elements = elementList ?? itemList;

	elements.forEach(({ a, path, index }) => {
		const shape = get(newAnimation, path);

		if (!shape) {
			return;
		}

		if (shape.c.k.every(keyframe => typeof keyframe === "object")) {
			shape.c.k[index].s = [toUnitVector(r), toUnitVector(g), toUnitVector(b), a];
			return;
		}
		shape.c.k = [toUnitVector(r), toUnitVector(g), toUnitVector(b), a];
	});

	return newAnimation;
};
