import React, { useMemo } from "react";
import styled from "styled-components";
import debounce from "lodash.debounce";
import fontsize from "../tokens/FontSizes";
import colors from "../tokens/Colors";
import whitespace from "../tokens/Whitespace";
import Caption from "../layout/Caption";
import Row from "../layout/Row";

const Group = styled.div`
	cursor: pointer;
	height: auto;
	padding: ${whitespace.small};
	padding-left: ${whitespace.small};
	background: ${({ theme }) => theme.inputBackgroundColorDefault};
	border-style: solid;
	border-width: 0px;
	border-radius: 8px;
	font-size: ${fontsize.caption};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Input = styled.input`
	width: 100%;

	margin-left: ${whitespace.small};
	background: none;
	color: ${({ theme }) => theme.primaryFontColor};
	border-style: solid;
	border-width: 0px;
	border-radius: 8px;
	font-size: ${fontsize.caption};
	display: flex;

	:focus {
		border: 1px solid ${colors.primary};
	}

	&::-webkit-input-placeholder {
		color: ${props => props.placeholderColor};
	}
`;

const ColorInfo = ({ group, value = "#FFFFFF", onChange = () => {} }) => {
	const onChangeDebounced = useMemo(() => debounce(onChange, 50), [onChange]);
	return (
		<Row paddingLeft={whitespace.medium} alignItems="center" marginBottom={whitespace.default}>
			<Caption color={({ theme }) => theme.secondaryFontColor} style={{ width: "140px" }}>
				{group}
			</Caption>
			<Group>
				<Row backgroundColor={value} width="38px" height="28px" borderRadius="5px">
					<Input
						type="color"
						style={{ opacity: "0", paddingLeft: "4px" }}
						value={value}
						onChange={event => onChangeDebounced(event.target.value)}
					/>
				</Row>
				<Input value={value} onChange={event => onChangeDebounced(event.target.value)} />
			</Group>
		</Row>
	);
};

export default ColorInfo;
