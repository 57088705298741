import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";

const FILES_API_ENDPOINT = "https://jsonproxy.web.app";

// const devFirebaseConfig = {
//     apiKey: "AIzaSyA1qRei3UKjnyfV2761wRwyCidWFXjv7i4",
//     authDomain: "lottie-test-project.firebaseapp.com",
//     databaseURL: "https://lottie-test-project.firebaseio.com",
//     projectId: "lottie-test-project",
//     storageBucket: "lottie-test-project.appspot.com",
//     messagingSenderId: "73978221661",
//     appId: "1:73978221661:web:afb3257d39f7c33b6029b4",
//     measurementId: "G-VNDQDT6SQK",
// };

const prodFirebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: "moko-app-bee21.firebaseapp.com",
	databaseURL: "https://moko-app-bee21.firebaseio.com",
	projectId: "moko-app-bee21",
	storageBucket: "moko-app-bee21.appspot.com",
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
//
// // Initialize Firebase
firebase.initializeApp(prodFirebaseConfig);
firebase.firestore().settings({
	timestampsInSnapshots: true,
});

const storage = firebase.storage();
const functions = firebase.functions();
const firebaseAnalytics = firebase.analytics();

// if (window.location.hostname === "localhost") {
//     firebase.firestore().settings({
//         host: "localhost:8080",
//         ssl: false,
//     });
//     firebase.auth().useEmulator("http://localhost:9099/");
//     functions.useFunctionsEmulator("http://localhost:5001");
// }

export { FILES_API_ENDPOINT, storage, functions, firebaseAnalytics, firebase as default };
