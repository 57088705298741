import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { firestoreConnect, populate } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";

import SidebarIconButton from "../layout/SidebarIconButton";
import LogoIcon from "../Icons/LogoIcon";
import Folder from "../Icons/Folder";
import whitespace from "../tokens/Whitespace";
import Tooltip from "./Tooltip";
import Marketplace from "../Icons/Marketplace";

const SidebarContainer = styled.div`
	padding-top: 90px;
	background-color: ${({ theme }) => theme.sidebarBackgroundColor};
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-start;
	display: flex;
	float: left;
	@media (min-width: 800px) {
	}
`;

const ChildrenContent = styled.div`
	position: absolute;
	top: 0;
	margin-left: 26px;
	margin-top: 36px;
`;

const Sections = {
	FOLDERS_VIEW: "ProjectsView",
	NEWS_DASHBOARD_VIEW: "NewsDashboardView",
	MARKETPLACE_VIEW: "MarketplaceView",
};

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showTooltipNews: false,
			showTooltipFolders: false,
			showTooltipMarketplace: false,
			selectedSection: Sections.NEWS_DASHBOARD_VIEW,
		};
	}
	toggleTooltipOne = () => {
		this.setState(state => ({
			showTooltipNews: !state.showTooltipNews,
		}));
	};

	toggleTooltipTwo = () => {
		this.setState(state => ({
			showTooltipFolders: !state.showTooltipFolders,
		}));
	};

	toggleTooltipFour = () => {
		this.setState(state => ({
			showTooltipMarketplace: !state.showTooltipMarketplace,
		}));
	};

	openFolders = () => {
		this.setState({
			selectedSection: Sections.FOLDERS_VIEW,
		});
	};

	openMarketplace = () => {
		this.setState({
			selectedSection: Sections.MARKETPLACE_VIEW,
		});
	};

	openNewsDashboard = () => {
		this.setState({
			selectedSection: Sections.NEWS_DASHBOARD_VIEW,
		});
	};

	componentDidMount() {
		if (this.props.location.pathname === "/editor") {
			this.openEditor();
		} else if (this.props.location.pathname === "/NewsDashboard") {
			this.openNewsDashboard();
		} else if (this.props.location.pathname.includes("/folders")) {
			this.openFolders();
		} else if (this.props.location.pathname === "/marketplace") {
			this.openMarketplace();
		}
	}

	render() {
		const { auth, editor, children, folders } = this.props;

		const folderList = Object.entries(folders || {})
			.filter(([key, value]) => !!value)
			.map(([key, value]) => {
				return {
					id: key,
					...value,
				};
			});

		const links = auth.uid ? (
			<div>
				<Tooltip isToggled={this.state.showTooltipNews} x text="News & Tips" top="24px" />
				<Tooltip isToggled={this.state.showTooltipFolders} text="Workspaces" top="100px" />
				<Tooltip isToggled={this.state.showTooltipMarketplace} text="Marketplace" top="178px" />
				<NavLink to="/NewsDashboard">
					<SidebarIconButton
						color={
							this.state.selectedSection === Sections.NEWS_DASHBOARD_VIEW && !editor.projectId
								? ({ theme }) => theme.sidebarIconColorActive
								: ({ theme }) => theme.sidebarIconColorDefault
						}
						onMouseEnter={this.toggleTooltipOne}
						onMouseLeave={this.toggleTooltipOne}
						onClick={this.openNewsDashboard}
						margin={whitespace.medium}
					>
						<LogoIcon />
					</SidebarIconButton>
				</NavLink>
				<NavLink to={folderList.length > 0 ? `/folders/${folderList[0].id}` : "/folders"}>
					<SidebarIconButton
						color={
							this.state.selectedSection === Sections.FOLDERS_VIEW && !editor.projectId
								? ({ theme }) => theme.sidebarIconColorActive
								: ({ theme }) => theme.sidebarIconColorDefault
						}
						onClick={this.openFolders}
						onMouseEnter={this.toggleTooltipTwo}
						onMouseLeave={this.toggleTooltipTwo}
						margin={whitespace.medium}
					>
						<Folder />
					</SidebarIconButton>
				</NavLink>

				<NavLink to="/marketplace">
					<SidebarIconButton
						color={
							this.state.selectedSection === Sections.MARKETPLACE_VIEW && !editor.projectId
								? ({ theme }) => theme.sidebarIconColorActive
								: ({ theme }) => theme.sidebarIconColorDefault
						}
						onClick={this.openMarketplace}
						onMouseEnter={this.toggleTooltipFour}
						onMouseLeave={this.toggleTooltipFour}
						margin={whitespace.medium}
					>
						<Marketplace />
					</SidebarIconButton>
				</NavLink>

				<ChildrenContent>{children}</ChildrenContent>
			</div>
		) : null;

		return <SidebarContainer>{links}</SidebarContainer>;
	}
}

// const authorPopulates = [{ child: "author", root: "users" }];
// const collaboratorPopulates = [
//     {
//         child: "roles",
//         root: "users",
//         populateByKey: true,
//         childAlias: "collaborators",
//     },
// ];

// const populates = [...authorPopulates, ...collaboratorPopulates];

// const mapStateToProps = (state) => {
//     return {
//         auth: state.firebase.auth,
//         editor: state.editor,
//     };
// };

// export default compose(connect(mapStateToProps), firestoreConnect((props) => {
//     const foldersQuery = {
//         collection: "folders",
//         storeAs: "folders",
//         populates: populates,
//         where: [
//             [`roles.${props.auth.uid}`, "in", ["admin", "collaborator"]],
//         ],
//     };

//     return [foldersQuery];
// }), withRouter)(Sidebar);

//////////

const authorPopulates = [{ child: "author", root: "users" }];
const collaboratorPopulates = [
	{
		child: "roles",
		root: "users",
		populateByKey: true,
		childAlias: "collaborators",
	},
];

const populates = [...authorPopulates, ...collaboratorPopulates];

const mapStateToProps = state => {
	return {
		folders: populate(state.firestore, `folders`, populates),
		auth: state.firebase.auth,
		search: state.project.search,
		editor: state.editor,
		isLoaded: state.firestore.status.requested.folders,
	};
};

export default compose(
	connect(mapStateToProps),
	firestoreConnect(props => {
		const foldersQuery = {
			collection: "folders",
			storeAs: "folders",
			populates: populates,
			where: [[`roles.${props.auth.uid}`, "in", ["admin", "collaborator"]]],
		};

		return [foldersQuery];
	}),
	withRouter
)(Sidebar);
