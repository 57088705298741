import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tippy";
import fontsize from "../tokens/FontSizes";
import IconWraper from "./IconWraper";
import Copy from "../Icons/Copy";
import Download from "../Icons/Download";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";
import Caption from "../layout/Caption";
import Row from "../layout/Row";

const Input = styled.input`
	width: 100%;
	height: 30px;
	margin-left: ${whitespace.default};
	margin-right: ${whitespace.default};
	padding: ${whitespace.default};
	color: ${({ theme }) => theme.primaryFontColor};
	background: ${({ theme }) => theme.inputBackgroundColorDefault};
	border-style: solid;
	border-width: 1px;
	border-radius: 8px;
	border-color: ${({ theme }) => theme.inputBorderColorDefault};
	font-size: ${fontsize.caption};
	display: flex;
	flex-grow: 1;

	:focus {
		border: 1px solid ${({ theme }) => theme.inputBorderColorActive};
	}

	&::-webkit-input-placeholder {
		color: ${props => props.placeholderColor};
	}
`;

const InputWrapper = styled.div`
	display: flex;
	min-width: 150px;
	align-items: center;
`;

const RenderedLink = ({ value, type, name }) => {
	const inputRef = useRef(null);
	const timeoutRef = useRef(null);

	const [isTooltipVisible, setIsTooltipVisible] = useState(false);

	const handleClickCopy = useCallback(() => {
		if (!navigator.clipboard) {
			return;
		}

		navigator.clipboard
			.writeText(inputRef.current.value)
			.then(() => {
				setIsTooltipVisible(true);
				timeoutRef.current = setTimeout(() => {
					setIsTooltipVisible(false);
				}, 5000);
			})
			.catch(err => {
				console.log("error copying to clipboard", err);
			});
	}, []);

	useEffect(() => {
		return () => clearTimeout(timeoutRef.current);
	}, []);

	return (
		<Row paddingLeft={whitespace.medium} marginBottom={whitespace.small} flexDirection="column" height="40px">
			<Row flexDirection="row" alignItems="center">
				<InputWrapper>
					<Row justifyContent="center" width="18%" flexDirection="row">
						<Caption marginRight={whitespace.default} color={({ theme }) => theme.secondaryFontColor}>
							{type}
						</Caption>
					</Row>
					<Row justifyContent="flex-start" flexGrow="1" flexDirection="row">
						<Input value={value} ref={inputRef} />
					</Row>
				</InputWrapper>

				<Tooltip open={isTooltipVisible} position={"top"} title={"Copied! 🎉"}>
					<IconWraper onClick={handleClickCopy} backgroundColor="none" as={"a"}>
						<Copy />
					</IconWraper>
				</Tooltip>
				<IconWraper as={"a"} backgroundColor="none" download={name} href={value} target={"_blank"}>
					<Download />
				</IconWraper>
			</Row>
		</Row>
	);
};

export default memo(RenderedLink);
