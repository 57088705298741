import * as React from "react";
import styled from "styled-components";
import fontsize from "../tokens/FontSizes";
import whitespace from "../tokens/Whitespace";

const TagLabel = styled.div`
	width: max-content;
	padding: ${whitespace.default};
	margin-left: ${props => props.marginLeft};
	margin-right: ${props => props.marginRight};
	justify-content: center;
	display: flex;
	align-items: center;
	position: ${props => props.position};
	background-color: ${({ theme }) => theme.tagBackgroundColorDefault};
	border-radius: 4px;
	font-size: ${fontsize.caption};
	color: ${({ theme }) => theme.tagTextColorDefault};
	line-height: 1;
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	font-weight: 400;
`;

export function Tag(position, backgroundColor, marginLeft, marginRight) {
	return <TagLabel></TagLabel>;
}

export default TagLabel;
