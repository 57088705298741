import styled from "styled-components";
import { motion } from "framer-motion";
import whitespace from "../tokens/Whitespace";

const Grid = styled(motion.div)`
	text-decoration: none;
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: ${whitespace.medium};
	grid-row-gap: ${whitespace.medium};

	@media (min-width: 600px) {
		grid-template-columns: repeat(1, 1fr);
	}

	@media (min-width: 800px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 1100px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (min-width: 1600px) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export default Grid;
