import React from "react";

const MiniArrow = ({ props }) => {
	return (
		<svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
			<g id="mini-arrow-right" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path
					d="M4.787,7.20892254 L8.306,11.2064197 C8.689,11.5978601 9.31,11.5978601 9.694,11.2064197 L13.212,7.20892254 C13.596,6.81848329 13.596,6.18376924 13.212,5.79333 C13.028,5.60511826 12.779,5.5 12.518,5.5 L5.481,5.5 C4.939,5.5 4.5,5.94850457 4.5,6.50112627 C4.5,6.76642473 4.603,7.02171193 4.787,7.20892254 Z"
					id="Fill-56"
					fill="currentColor"
					transform="translate(9.000000, 8.500000) rotate(-90.000000) translate(-9.000000, -8.500000) "
				></path>
			</g>
		</svg>
	);
};

export default MiniArrow;
