import * as React from "react";
import fontsize from "../tokens/FontSizes";
import styled from "styled-components";

const Body = styled.div`
    display: flex;
    max-width: ${(props) => props.maxWidth};
    color: ${(props) => props.color};
    text-align: ${(props) => props.textAlign};
    font-weight: ${(props) => props.fontWeight};
    font-size: ${fontsize.body};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    line-height: 24px;

    @media (max-width: 800px) {
    }
`;

export function BodyType({
    maxWidth,
    color,
    fontWeight,
    textAlign,
    fontSize,
    marginTop,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingBottom,
}) {
    return <Body></Body>;
}

export default Body;
