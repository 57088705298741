import * as React from "react";
import { motion } from "framer-motion";

const LogoIcon = ({ styles = {}, ...props }) => (
    <motion.div
        whileHover={{
            scale: 1.15,
            transition: { ease: [0.2, 0.8, 0.2, 1], duration: 0.3 },
        }}
        whileTap={{ scale: 0.75 }}
    >
        <svg height="32px" viewBox="0 0 32 32" width="32px" {...props}>
            <g fill="currentColor" fillRule="nonzero">
                <path
                    fill={props.bgcolor}
                    fillRule="nonzero"
                    d="M15.7,3h0.6l0.6,0c9.5,0.2,12,2.8,12.1,12.7l0,0.3c0,10.4-2.6,13-13,13l-0.6,0C5.6,28.9,3.1,26.2,3,16.3v-0.6
	l0-0.6C3.2,5.5,5.9,3,15.7,3L15.7,3z M10.4,9C9.7,9,9.1,9.6,9,10.3l0,0.1v11.2l0,0.1c0,0.7,0.6,1.2,1.3,1.3l0.1,0H11l0.1,0
	c0.3,0,0.6-0.2,0.9-0.4l0.1-0.1l2-2.2c0.1-0.1,0.1-0.1,0.2-0.2c1-0.9,2.6-0.9,3.6,0.1l0.1,0.1l2,2.2c0.2,0.3,0.6,0.4,0.9,0.5l0.1,0
	h0.6c0.7,0,1.3-0.6,1.4-1.3l0-0.1V10.4C23,9.6,22.4,9,21.6,9c-0.3,0-0.6,0.1-0.9,0.4l-2.9,2.9c-1,1-2.6,1-3.6,0.1l-0.1-0.1l-2.8-2.9
	l-0.1-0.1C11,9.1,10.7,9,10.4,9z"
                />
            </g>
        </svg>
    </motion.div>
);

export default LogoIcon;
