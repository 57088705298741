import React from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useFirestoreConnect, populate } from "react-redux-firebase";
import { useSelector } from "react-redux";

import Row from "../layout/Row";
import whitespace from "../tokens/Whitespace";
import SearchInput from "../layout/SearchInput";
import Button from "../layout/Button";
import CardGrid from "../layout/CardGrid";
import ProjectForm from "../projects/ProjectForm";
import Modal from "../layout/Modal";
import Container from "../layout/Container";
import Body from "../layout/Body";
import PageAnimationPlayer from "../layout/PageAnimationPlayer";

import { createProject, deleteProjectAction, duplicateProjectAction } from "../../store/actions/projectActions";

import EmptyList from "../layout/EmptyList";

const authorPopulates = [{ child: "author", root: "users" }];

const ProjectListWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: center;
`;

const ProjectList = () => {
	const store = useSelector(store => store);
	const [addNewModal, setAddNewModal] = React.useState(false);
	const [searchInput, setSearchInput] = React.useState(null);
	const dispatch = useDispatch();
	const { params } = useRouteMatch();
	const folder = store.firestore.data[`f_${params.folderId}`];
	const projects = populate(store.firestore, `projects_${params.folderId}`, authorPopulates);
	const isProjectListLoaded = store.firestore.status.requested[`projects_${params.folderId}`];

	useFirestoreConnect(() => {
		const projectsQuery = {
			collection: "newProjects",
			storeAs: `projects_${params.folderId}`,
			where: [["folder", "==", params.folderId]],
			populates: authorPopulates,
		};

		const currentFolderQuery = {
			collection: "folders",
			storeAs: `f_${params.folderId}`,
			doc: params.folderId,
		};

		return [projectsQuery, currentFolderQuery];
	});

	const createProjectHandler = project => dispatch(createProject(project));
	const deleteProject = id => dispatch(deleteProjectAction(id));
	const duplicateProject = project => dispatch(duplicateProjectAction(project));

	const handleCreateProject = newProject => {
		setAddNewModal(!addNewModal);
		createProjectHandler(newProject);
	};

	if (folder === null) {
		const folders = store.firestore.ordered.folders;
		if (folders.length > 0) {
			return <Redirect to={`/folders/${folders[0].id}`} />;
		}
		return <Redirect to="/folders" />;
	}

	if (!store.firebase.auth.uid) {
		return <Redirect to="/signin" />;
	}

	if (folder === undefined) {
		return (
			<div style={{ margin: "auto" }}>
				<Row alignItems="center" justifyContent="center" flexDirection="column">
					<Row width="64px" height="96px">
						<PageAnimationPlayer src="https://jsonproxy.web.app/json/62b6b250-0f57-40fe-a1a2-1e64c0e23fc5.json" />
					</Row>
					<Body color={({ theme }) => theme.primaryFontColor}>Loading...</Body>
				</Row>
			</div>
		);
	}

	if (folder[params.folderId] === null) {
		return <div>Sorry, something went wrong. Please reload the page</div>;
	}

	const projectList = Object.entries(projects || {}).map(([key, value]) => ({ id: key, ...value }));

	const filteredProjects = searchInput
		? projectList.filter(p => {
				const titleMatch = p.title ? p.title.toLowerCase().includes(searchInput.toLowerCase()) : false;
				const tagsMatch = p.tags ? p.tags.toLowerCase().includes(searchInput.toLowerCase()) : false;
				return titleMatch || tagsMatch;
		  })
		: projectList;

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				display: "flex",
				flexWrap: "nowrap",
				justifyContent: "center",
			}}
		>
			<Modal isToggled={addNewModal} action={() => setAddNewModal(!addNewModal)}>
				<ProjectForm onSubmit={handleCreateProject} folderId={params.folderId} />
			</Modal>

			<ProjectListWrapper>
				<Container>
					<Row justifyContent="space-between" paddingTop={whitespace.xlarge} paddingBottom={whitespace.big}>
						<Row width="100%" align-items="start" flexDirection="row">
							<Button onClick={() => setAddNewModal(!addNewModal)} design="primary" text="Add new file"></Button>
						</Row>
						<SearchInput placeholder="Search files..." value={searchInput} onChange={setSearchInput} />
					</Row>
				</Container>

				{isProjectListLoaded &&
					(filteredProjects.length > 0 ? (
						<CardGrid
							projects={filteredProjects}
							onClick={() => setAddNewModal(!addNewModal)}
							baseUrl={`/folders/${params.folderId}/`}
							onDeleteProject={deleteProject}
							onDuplicateProject={duplicateProject}
						/>
					) : (
						<EmptyList />
					))}
			</ProjectListWrapper>
		</div>
	);
};

export default ProjectList;
