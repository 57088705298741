import authReducer from "./authReducer";
import projectReducer from "./projectReducer";
import editorReducer from "./editorReducer";
import folderReducer from "./folderReducer";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
    auth: authReducer,
    project: projectReducer,
    editor: editorReducer,
    folder: folderReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
});

export default rootReducer;

// the key name will be the data property on the state object
