import React, { memo, useMemo, useState, useRef, useCallback } from "react";
import useClickAway from "ahooks/lib/useClickAway";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";

import { motion } from "framer-motion";
import noop from "lodash/noop";

import AlternateCard from "./AlternateCard";
import Row from "./Row";
import Body from "./Body";
import AvatarGuest from "./AvatarGuest";
import whitespace from "../tokens/Whitespace";
import fontsize from "../tokens/FontSizes";

import ContextMenu from "../Icons/ContextMenu";
import { getEmojiByIndex } from "../utils/emojis";

const motionDivStyle = {
	height: "100%",
	width: "100%",
	overflow: "visible",
};

const StyledContextMenuWrapper = styled.div`
	visibility: ${({ visibleContextMenu }) => (visibleContextMenu ? "visible" : "hidden")};
	color: ${props => props.color};
	width: 32px;
	height: 32px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${props => props.backgroundColor};
`;

const DropdownWrapper = styled.div`
	position: absolute;
	top: ${whitespace.default};
	right: ${whitespace.default};
`;

const StyledMotionDiv = styled(motion.div)`
	position: relative;
`;

const CollaboratorsWrapper = styled.div`
	display: flex;
	align-items: center;
	height: auto;
	margin-top: ${whitespace.normal};
`;

const CollaboratorAvatarWrapper = styled.div`
	:not(:first-child) {
		left: ${({ index }) => `calc(-${whitespace.normal} * ${index})`};
	}
`;

const StyledDropdownMenu = styled.div`
	position: absolute;
	z-index: 100;
	top: calc(100% - 30px);
	width: 120px;
	height: 80px;
	background-color: ${({ theme }) => theme.selectMenuBackgroundColor};
	border-radius: 8px;
	padding: ${whitespace.small};
	justify-content: flex-start;
	display: flex;
	flex-direction: column;
	margin-left: ${props => props.marginLeft};
	margin-right: auto;
	margin-top: ${whitespace.big};
	box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.25);
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	right: 0px;
`;

const ExtraCollaboratorsWrapper = styled.div`
	margin-left: ${whitespace.small};
	color: ${({ theme }) => theme.inputBorderColorDefault};
	font-size: ${fontsize.body};
`;

const MenuElement = styled.div`
	font-size: ${fontsize.body};
	color: ${props => props.color};
	flex-grow: 1;
	height: 16px;

	display: flex;
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	float: right;
	padding: ${whitespace.default};
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme }) => theme.highlight};
	}
`;

const VISIBLE_COLLABORATORS_LENGTH = 4;

const FolderCard = ({ folder = {}, onDelete = noop, setSelectedFolder }) => {
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const { params } = useRouteMatch();

	const menuButtonRef = useRef();

	const handleClickMenuButton = useCallback(event => {
		event.preventDefault();
		event.stopPropagation();
		setIsDropdownVisible(current => !current);
	}, []);

	useClickAway(() => {
		setIsDropdownVisible(false);
	}, menuButtonRef);

	const handleClickDropdownContent = useCallback(event => {
		event.preventDefault();
		event.stopPropagation();
		setIsDropdownVisible(false);
	}, []);

	const collaboratorList = () => {
		if (!folder.collaborators) {
			return [];
		}

		return Object.values(folder.collaborators);
	};

	return (
		<StyledMotionDiv
			style={{
				...motionDivStyle,
				...(isDropdownVisible && {
					zIndex: 1,
				}),
			}}
		>
			<AlternateCard
				color={({ theme }) => theme.secondaryFontColor}
				colorOnHover={({ theme }) => theme.primaryFontColor}
				cursor="pointer"
				padding={whitespace.default}
				activeFolder={folder.id === params.folderId}
			>
				<DropdownWrapper>
					<StyledContextMenuWrapper
						backgroundColor={isDropdownVisible ? ({ theme }) => theme.iconButtonPressed : "none"}
						color={({ theme }) => theme.primaryFontColor}
						ref={menuButtonRef}
						onClick={handleClickMenuButton}
						visibleContextMenu={folder.id === params.folderId}
					>
						<ContextMenu />
					</StyledContextMenuWrapper>

					{isDropdownVisible && (
						<motion.div
							transition={{
								type: "tween",
								ease: [0.12, 0.75, 0.02, 1.025],
								duration: 0.35,
							}}
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 5 }}
							exit={{ opacity: 0, y: -20 }}
						>
							<StyledDropdownMenu onClick={handleClickDropdownContent}>
								<MenuElement onClick={() => setSelectedFolder(folder)} color={({ theme }) => theme.primaryFontColor}>
									Manage
								</MenuElement>

								<MenuElement color={({ theme }) => theme.destructive} onClick={() => onDelete(folder)}>
									Delete
								</MenuElement>
							</StyledDropdownMenu>
						</motion.div>
					)}
				</DropdownWrapper>

				<Row width="100%" flexDirection="Row" alignItems="center" justifyContent="start">
					<Row marginRight={whitespace.medium}>{getEmojiByIndex(folder.emojiIndex)}</Row>
					<Body color="inherit" fontWeight={"400"}>
						{folder.title}
					</Body>
				</Row>
				<CollaboratorsWrapper>
					{collaboratorList()
						.slice(0, VISIBLE_COLLABORATORS_LENGTH)
						.map((collaborator, index) => {
							return (
								<CollaboratorAvatarWrapper key={collaborator.id} index={index}>
									<AvatarGuest backgroundColor={collaborator.iconColor} initials={collaborator.initials}></AvatarGuest>
								</CollaboratorAvatarWrapper>
							);
						})}

					{collaboratorList.length > VISIBLE_COLLABORATORS_LENGTH && (
						<ExtraCollaboratorsWrapper>
							{collaboratorList().length - VISIBLE_COLLABORATORS_LENGTH}
						</ExtraCollaboratorsWrapper>
					)}
				</CollaboratorsWrapper>
			</AlternateCard>
		</StyledMotionDiv>
	);
};

export default memo(FolderCard);
