import React, { memo } from "react";
import styled from "styled-components";

import TagLabel from "./TagLabel";

const StyledTagLabel = styled(TagLabel)`
	margin-top: 5px;
	margin-left: 5px;

	&:last-child {
		margin-right: 0;
	}
`;

const TagsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Tags = ({ tags }) => {
	const tagsContent = tags
		.split(",")
		.filter(tag => tag.length > 0)
		.map((tag, index) => <StyledTagLabel key={`${index}-${tag}`}>{tag}</StyledTagLabel>);

	return <TagsWrapper>{tagsContent}</TagsWrapper>;
};

export default memo(Tags);
