type ERROR_NAMES = "INVALID_FILE_TYPE";

class ProjectErrors extends Error {
	name: ERROR_NAMES;
	message: string;
	cause?: any;

	constructor({ name, message, cause }: { name: ERROR_NAMES; message: string; cause?: any }) {
		super();
		this.name = name;
		this.message = message;
		this.cause = cause;
	}
}

export default ProjectErrors;
