import styled from "styled-components";
import whitespace from "../tokens/Whitespace";
import fontsize from "../tokens/FontSizes";

const EmojiWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 3px;
	width: 40px;
	height: 40px;
	margin-top: ${whitespace.default};
	margin-right: ${whitespace.default};

	background-color: ${({ theme }) => theme.inputBackgroundColorDefault};
	border: 1px solid
		${({ theme, isSelected }) => (isSelected ? theme.inputBorderColorActive : theme.inputBorderColorDefault)};
	border-radius: 12px;

	font-size: ${fontsize.h3};
	cursor: pointer;

	&:last-child {
		margin-right: 0;
	}
`;

export default EmojiWrapper;
