import * as React from "react";

const Add = ({ styles = {}, ...props }) => (
    <svg height="32px" viewBox="0 0 32 32" width="32px" {...props}>
        <g fill="currentColor" fillRule="nonzero">
            <path
                fill="currentColor"
                d="M8,25h16c0.5,0,0.9,0.4,1,0.9l0,0.1v1c0,0.5-0.4,0.9-0.9,1L24,28H8c-0.5,0-0.9-0.4-1-0.9L7,27v-1
	c0-0.5,0.4-0.9,0.9-1L8,25h16H8z M17,5c1.1,0,2,0.9,2,2v6h2.1c0.5,0,1,0.2,1.4,0.6c0.7,0.7,0.8,1.9,0.1,2.7l-0.1,0.1l-5.8,5.8
	c-0.4,0.4-0.9,0.4-1.3,0.1l-0.1-0.1l-5.8-5.8C9.1,16,8.9,15.5,8.9,15c0-1.1,0.8-1.9,1.9-2l0.1,0l2.1,0l0-6c0-1.1,0.9-2,2-2H17z"
            />
        </g>
    </svg>
);

export default Add;
