import React, { PureComponent } from "react";
import styled from "styled-components";
import fontsize from "../tokens/FontSizes";

export const LinkBasic = styled.div`
    width: ${(props) => props.width};
    max-width: ${(props) => props.maxWidth};
    display: inline-block;
    color: ${({ theme }) => theme.linkColorDefault};
    background: none;
    font-size: ${fontsize.body};
    font-weight: 400;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    height: 15px;
    cursor: pointer;
    transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
    border: none;

    ${(props) => props.disabled && "opacity: 0.5; cursor: not-allowed;"}

    &:hover {
        text-decoration: underline;
    }
`;

class LinkButton extends PureComponent {
    render() {
        return (
            <LinkBasic
                className={this.props.className}
                width={this.props.width}
                maxWidth={this.props.maxWidth}
                onClick={this.props.onClick}
                marginLeft={this.props.marginLeft}
                marginRight={this.props.marginRight}
                marginTop={this.props.marginTop}
                marginBottom={this.props.marginBottom}
                disabled={this.props.disabled}
                as={this.props.as}
            >
                {this.props.text}
            </LinkBasic>
        );
    }
}

export default LinkButton;
