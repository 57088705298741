import * as React from "react";
import { motion, AnimateSharedLayout } from "framer-motion";
import H3 from "../H3";
import Row from "../Row";
import Button from "../Button";
import "./carouselupdatesstyles.css";

interface ItemProps {
	id: number;
	title: string;
	poster: string;
	video: string;
	date: string;
	url: string;
}

const items: ItemProps[] = [
	{
		id: 1,
		title: "Inviting teammates",
		poster:
			"https://firebasestorage.googleapis.com/v0/b/moko-app-bee21.appspot.com/o/png%2Ffolder_opt.png?alt=media&token=ae0c2c50-7f76-42aa-9370-5d01cbd3a965",
		video:
			"https://firebasestorage.googleapis.com/v0/b/moko-app-bee21.appspot.com/o/mp4%2Ffolderoverview_dimmed.mp4?alt=media&token=edbd1be3-452e-4e71-83ab-037948b46a58",
		date: "February 14, 2021",
		url: "https://moko-app.kb.help/general/team-collaboration-in-moko/",
	},
	{
		id: 2,
		title: "File organization",
		poster:
			"https://firebasestorage.googleapis.com/v0/b/moko-app-bee21.appspot.com/o/png%2Fsearch_opt.png?alt=media&token=c43c183c-3481-420e-8339-fee2057c0c7d",
		video:
			"https://firebasestorage.googleapis.com/v0/b/moko-app-bee21.appspot.com/o/mp4%2FSearch_vid_dimmed.mp4?alt=media&token=8d9df1c1-530d-4d53-82e2-e2928db61fa4",
		date: "February 15, 2021, 2020",
		url: "https://moko-app.kb.help/general/assets-organisation/",
	},
	{
		id: 3,
		title: "Interactive SVG's",
		poster:
			"https://firebasestorage.googleapis.com/v0/b/moko-app-bee21.appspot.com/o/png%2Fsvg_opt.png?alt=media&token=78ba7a71-aaab-49a0-838e-15e235c576e2",
		video:
			"https://firebasestorage.googleapis.com/v0/b/moko-app-bee21.appspot.com/o/mp4%2FSVG_dimmed.mp4?alt=media&token=fe1282cc-50fb-48c8-8609-c9e0a3c6f838",
		date: "February 15, 2021, 2020",
		url: "https://moko-app.kb.help/general/svgs-inmoko/",
	},
];

const cycleArray = (currentItems: number[], reverse?: boolean) => {
	const currentItemsCopy = [...currentItems];

	if (reverse) {
		const firstItem = currentItemsCopy.shift() as number;
		return [...currentItemsCopy, firstItem];
	} else {
		const lastItem = currentItemsCopy.pop() as number;
		return [lastItem, ...currentItemsCopy];
	}
};

const resolveX = [4, 0, -4];

const Item: React.FC<{
	position: number;
	item: ItemProps;
	onClick: React.MouseEventHandler;
}> = ({ item, position, onClick }) => {
	const ref = React.useRef<HTMLVideoElement>(null);

	const active = position === 1;

	React.useEffect(() => {
		if (!ref.current) return;

		if (position === 1) {
			ref.current.play();
		} else {
			ref.current.pause();
			ref.current.currentTime = 0;
		}
	}, [position]);

	return (
		<motion.div
			layout
			className={`item-${item.id} pos-${position}`}
			transition={{
				type: "spring",
				duration: 0.4,
				damping: 18,
			}}
			style={{
				scale: active ? 1 : 0.9,
				x: resolveX[position],
			}}
			onClick={onClick}
		>
			<video muted loop preload="metadata" poster={item.poster} ref={ref}>
				<source src={item.video} type="video/mp4" />
			</video>
		</motion.div>
	);
};

export default function CarouselUpdates() {
	const ids = items.map(item => item.id);
	const [order, setOrder] = React.useState(cycleArray(ids));

	const cycleItems = (reverse?: boolean) => setOrder(i => cycleArray(i, reverse));
	const handleItemClick = (position: number) => {
		if (position === 0) {
			cycleItems();
		}

		if (position === 2) {
			cycleItems(true);
		}
	};

	const activeItem = items.find(item => item.id === order[1]) as ItemProps;

	return (
		<div className="CarouselContainerUp">
			<AnimateSharedLayout>
				<motion.div layout className="carouselUp">
					{items.map(item => {
						const position = order.findIndex(id => item.id === id);
						return <Item position={position} item={item} onClick={() => handleItemClick(position)} />;
					})}
				</motion.div>
			</AnimateSharedLayout>
			<div className="titleContainerUp">
				<motion.div
					className="titleUp"
					initial={{ x: 10, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					exit={{ x: -10, opacity: 0 }}
					transition={{
						ease: "easeOut",
						duration: 0.3,
						delay: 0.2,
					}}
				>
					<Row width="200px" justifyContent="start" alignItems="center" borderRadius="8px">
						<H3 color="white" textAlign="left">
							{activeItem.title}
						</H3>
					</Row>
				</motion.div>
			</div>
			<div className="dateContainerUp">
				<a href={activeItem.url} target="_blank" rel="noopener noreferrer">
					<Button design="primary" text="Learn more" />
				</a>
			</div>
		</div>
	);
}
