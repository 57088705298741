import {
	EDITOR_SET_ANIMATION,
	EDITOR_SET_COLORS,
	EDITOR_UPDATE_ANIMATION,
	EDITOR_UPDATE_COLOR,
	EDITOR_SAVE_COLORS,
	EDITOR_SET_PROJECT_ID,
	EDITOR_CLEAR,
	EDITOR_SET_IS_DISABLED,
	EDITOR_UPDATE_BACKGROUND,
	EDITOR_UPDATE_MULTIPLIER,
} from "../constants/editorConstants";

export const setAnimationAction = animation => {
	return {
		type: EDITOR_SET_ANIMATION,
		payload: animation,
	};
};

export const updateAnimationAction = animation => {
	return {
		type: EDITOR_UPDATE_ANIMATION,
		payload: animation,
	};
};

export const setColorsAction = colors => ({
	type: EDITOR_SET_COLORS,
	payload: colors,
});

export const updateColorAction = (colorId, { value } = {}) => ({
	type: EDITOR_UPDATE_COLOR,
	payload: {
		colorId,
		value,
	},
});

export const saveColorsAction = () => ({
	type: EDITOR_SAVE_COLORS,
});

export const setProjectIdAction = projectId => ({
	type: EDITOR_SET_PROJECT_ID,
	payload: projectId,
});

export const clearEditorAction = () => ({
	type: EDITOR_CLEAR,
});

export const setIsDisabledAction = isDisabled => ({
	type: EDITOR_SET_IS_DISABLED,
	payload: isDisabled,
});

export const updateBackgroundColorAction = backgroundColor => ({
	type: EDITOR_UPDATE_BACKGROUND,
	payload: { backgroundColor },
});

export const updateMultiplierAction = multiplier => ({
	type: EDITOR_UPDATE_MULTIPLIER,
	payload: { "size.multiplier": multiplier },
});
