import * as React from "react";
import styled from "styled-components";

const TwoColumn = styled.div`
    height: ${(props) => props.height};
    max-width: ${(props) => props.maxWidth};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    flex-grow: ${(props) => props.flexGrow};
    display: flex;
    justify-content: ${(props) => props.justifyContent};
    flex-direction: row;
    flex-wrap: ${(props) => props.flexWrap};
    align-items: ${(props) => props.alignItems};
    padding: ${(props) => props.padding};
    padding-top: ${(props) => props.paddingTop};

    @media (max-width: 1300px) {
        flex-direction: row;
    }

    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

export function RowDiv({
    maxWidth,
    height,
    marginTop,
    marginBottom,
    flexGrow,
    flexWrap,
    alignItems,
    justifyContent,
    padding,
    paddingTop,
}) {
    return <TwoColumn></TwoColumn>;
}

export default TwoColumn;
