import * as React from "react";

const Dropdown16 = ({ styles = {}, ...props }) => (
    <svg height="16px" viewBox="0 0 16 16" width="16px" {...props}>
        <g fill="currentColor" fillRule="nonzero">
            <path
                d="M3.9,6.7l3.5,4c0.4,0.4,1,0.4,1.4,0l3.5-4c0.4-0.4,0.4-1,0-1.4C12.1,5.1,11.9,5,11.6,5h-7
                c-0.5,0-1,0.4-1,1C3.6,6.3,3.7,6.5,3.9,6.7z"
            />
        </g>
    </svg>
);

export default Dropdown16;
