const initState = {
	projects: [
		{
			id: "1",
			title: "Coco Bongo",
			tagone: "coco",
			tagtwo: "bongo",
			tagthree: "que",
			img: "https://static.dribbble.com/users/255/screenshots/6835472/bye_2000x_4x.png?compress=1&resize=1200x900",
		},
		{
			id: "2",
			title: "Baller",
			tagone: "fire",
			tagtwo: "ball",
			tagthree: "hero",
			img: "https://cdn.dribbble.com/users/2858859/screenshots/10613543/media/5fc96b208292e2968cb2637b545b68c5.jpg",
		},
		{
			id: "3",
			title: "Artist",
			tagone: "painting",
			tagtwo: "customize",
			tagthree: "design",
			img: "https://cdn.dribbble.com/users/2155177/screenshots/7905584/media/a8dbf0c96ffc2927275ec786d5c7dd8c.png",
		},
	],
	search: "",
};

const projectReducer = (state = initState, action) => {
	switch (action.type) {
		case "CREATE_PROJECT":
			console.log("project created", action.project);
			return state;
		case "CREATE_PROJECT_ERROR":
			console.log("create project error", action.err);
			return state;

		default:
			return state;
	}
};

export default projectReducer;
