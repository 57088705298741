import * as React from "react";

const Add = ({ styles = {}, ...props }) => (
    <svg height="32px" viewBox="0 0 32 32" width="32px" {...props}>
        <g fill="currentColor" fillRule="nonzero">
            <path
                fill="currentColor"
                d="M24.75,12.1538462 C25.9926407,12.1538462 27,13.1870352 27,14.4615385 L27,23.6923077 C27,24.966811 25.9926407,26 24.75,26 L15.75,26 C14.5073593,26 13.5,24.966811 13.5,23.6923077 L13.5,14.4615385 C13.5,13.1870352 14.5073593,12.1538462 15.75,12.1538462 L24.75,12.1538462 Z M16.5,6 C18.1568542,6 19.5,7.37758539 19.5,9.07692308 L19.5,9.84615385 C19.5,10.2709883 19.1642136,10.6153846 18.75,10.6153846 L15,10.6153846 C13.3933535,10.6153846 12.0816908,11.910745 12.0036715,13.5387382 L12,13.6923077 L12,19.0769231 C12,19.5017575 11.6642136,19.8461538 11.25,19.8461538 L9,19.8461538 C7.34314575,19.8461538 6,18.4685685 6,16.7692308 L6,9.07692308 C6,7.37758539 7.34314575,6 9,6 L16.5,6 Z"
            />
        </g>
    </svg>
);

export default Add;
