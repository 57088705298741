import styled from "styled-components";
import colors from "../tokens/Colors";

const AvatarRound = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	background-color: ${({ backgroundColor = "#C9C2FF" }) => backgroundColor};
	color: ${colors.gray900};
	border: none;
	font-size: 13px;
	font-weight: regular;
	cursor: pointer;
`;

export default AvatarRound;
