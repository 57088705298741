import React, { PureComponent } from "react";
import styled from "styled-components";
import colors from "../tokens/Colors";

const AvatarRound = styled.button`
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background-color: ${(props) => props.backgroundColor};
    color: ${colors.gray900};
    margin-right: ${(props) => props.marginRight};
    margin-left: ${(props) => props.marginLeft};
    border: none;
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
`;

class Avatar extends PureComponent {
    render() {
        return (
            <AvatarRound
                marginRight={this.props.marginRight}
                marginLeft={this.props.marginLeft}
                backgroundColor={this.props.backgroundColor}
            >
                {this.props.initials}
            </AvatarRound>
        );
    }
}

export default Avatar;
