import React, { memo } from "react";
import noop from "lodash/noop";
import styled from "styled-components";
import Logoloader from "../layout/Logoloader";

import useDownloadAnimation from "../../hooks/useDownloadAnimation";

const NotSuccessContainer = styled.div`
	display: flex;
	flex-grow: 1;
	margin: auto;
	justify-content: center;
`;

const CenteredLoader = styled.div`
	display: flex;
	flex-grow: 1;
	margin: auto;
	justify-content: center;
`;

const teststyle = {
	color: "red",
};

export default AnimationComponent =>
	memo(({ dataUrl, onError = noop, onSuccess = noop, backgroundColor, ...otherProps }) => {
		const [loadingStatus, animationData] = useDownloadAnimation(dataUrl, onError, onSuccess);

		return (
			<>
				{loadingStatus === "error" && <NotSuccessContainer>error</NotSuccessContainer>}

				{loadingStatus === "loading" && (
					<NotSuccessContainer>
						<CenteredLoader>
							<Logoloader />
							{/* Loading inside the card */}
						</CenteredLoader>
					</NotSuccessContainer>
				)}

				{loadingStatus === "success" && animationData && (
					<AnimationComponent animationData={animationData} backgroundColor={backgroundColor} {...otherProps} />
				)}
			</>
		);
	});
