import React, { memo, useMemo, useState } from "react";
import useMount from "ahooks/lib/useMount";
import noop from "lodash/noop";
import styled from "styled-components";
import { motion } from "framer-motion";
import Row from "./Row";
import whitespace from "../tokens/Whitespace";
import { functions } from "../../config/fbConfig";
import H3 from "./H3";
import withFetchAnimation from "../animation/withFetchAnimation";
import LottieViewerContainer from "../animation/LottieViewerContainer";
import Logoloader from "./Logoloader";
import GetMarketplaceCompilationButton from "./GetMarketplaceCompilationButton";

const AnimationViewer = withFetchAnimation(LottieViewerContainer);

const getMarketplaceCompilationById = functions.httpsCallable("getMarketplaceCompilationById");

const Grid = styled(motion.div)`
	text-decoration: none;
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: ${whitespace.medium};
	grid-row-gap: ${whitespace.medium};

	@media (min-width: 1100px) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

const StyledCompilationAddContainer = styled.div`
	width: 600px;
	height: auto;
`;

const StyledProjectPreview = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const CompilationAdd = ({ compilationId, onAdd = noop }) => {
	const [compilation, setCompilation] = useState(null);

	useMount(async () => {
		try {
			const { data } = await getMarketplaceCompilationById({
				compilationId,
			});
			setCompilation(data);
		} catch (error) {
			console.error("error fetching compilation with projects", error);
		}
	});

	const projectsNode = useMemo(() => {
		return compilation?.projects.map(it => (
			<StyledProjectPreview key={it.id}>
				<AnimationViewer dataUrl={it.url} backgroundColor={it.backgroundColor} />
			</StyledProjectPreview>
		));
	}, [compilation]);

	if (!compilation) {
		return (
			<StyledCompilationAddContainer>
				<Row justifyContent={"center"}>
					<Logoloader />
				</Row>
			</StyledCompilationAddContainer>
		);
	}

	return (
		<StyledCompilationAddContainer flexDirection={"column"}>
			<Row flexDirection={"row"} marginBottom={whitespace.big}>
				<Row>
					<H3 color={({ theme }) => theme.primaryFontColor}>{compilation.title}</H3>
				</Row>

				{!compilation.isInFolders && (
					<Row marginLeft={whitespace.medium}>
						<GetMarketplaceCompilationButton
							compilationId={compilationId}
							compilationTitle={compilation.title}
							onAdd={onAdd}
						/>
					</Row>
				)}
			</Row>

			<Grid>{projectsNode}</Grid>
		</StyledCompilationAddContainer>
	);
};

export default memo(CompilationAdd);
