const colors = {
	primary50: "#F2F1FF",
	primary75: "#EBEBFF",
	primary100: "#E9E8FF",
	primary150: "#E9DDFF",
	primary200: "#837BFF",
	primary300: "#6C62FF",
	primary400: "#6044F7",
	primary: "#5500FF",
	primary600: "#4400CC",
	primary700: "#310094",
	primary800: "#260668",
	primary900: "#090118",

	gray0: "#FFFFFF",
	gray25: "#F9F9FB",
	gray50: "#FBFBFE",
	gray75: "#F7F7FC",
	gray100: "#F1F0F4",
	gray200: "#ECEBEF",
	gray300: "#D9D8DB",
	gray400: "#C9C8CD",
	gray500: "#818286",
	gray550: "#3E3E41",
	gray600: "#2F2E31",
	gray700: "#27262B",
	gray800: "#19191A",
	gray825: "#18181B",
	gray850: "#151417",
	gray900: "#0E0E0E",
	gray1000: "#040404",

	black: "#121016",

	red: "#EB0037",
	red300: "#FF7394",
	red500: "#E84560",

	orange: "#FD4C17",
	orange300: "#FA9880",

	ruby: "#FF1A88",
	ruby300: "#FA80C5",

	blue: "#2990FF",
	blue300: "#0AEBFF",

	yellow: "#FFC300",
	yellow300: "#FAF18F",

	green: "#21D772",

	green300: "#86FAC8",

	shadowforlight: "15, 5, 45,",
	shadowfordark: "20, 30, 50",

	gradientlight:
		"#FFFFFF;background-image: radial-gradient(at 88% 88%, hsla(258,90%,62%,1) 0, transparent 42%), radial-gradient(at 58% 90%, hsla(284,100%,70%,1) 0, transparent 44%), radial-gradient(at 66% 50%, hsla(6,78%,75%,1) 0, transparent 41%), radial-gradient(at 84% 53%, hsla(208,100%,70%,1) 0, transparent 31%);",
	gradientdark:
		"#040404;background-image: radial-gradient(at 76% 91%, hsla(256,94%,23%,1) 0, transparent 57%), radial-gradient(at 34% 84%, hsla(283,41%,18%,1) 0, transparent 39%), radial-gradient(at 56% 65%, hsla(0,39%,41%,1) 0, transparent 41%), radial-gradient(at 81% 59%, hsla(236,45%,12%,1) 0, transparent 31%);",
};

export default colors;
