import React, { memo, useMemo, useState, useCallback, useRef } from "react";
import styled from "styled-components";
import useClickAway from "ahooks/lib/useClickAway";

import whitespace from "../tokens/Whitespace";
import Dropdown16 from "../Icons/Dropdown16";

const getValueDefault = item => item;
const getLabelDefault = item => item;

const DropdownContainer = styled("div")`
	position: relative;
	width: 100%;
	${({ disabled }) => disabled && "opacity: 0.5; pointer-events: none;"}
`;

const DropdownHeader = styled("div")`
	position: relative;
	width: ${props => props.width};
	display: flex;
	align-items: center;
	flex-grow: 1;
	height: ${props => props.height};
	padding: 0 ${whitespace.default};
	color: ${({ theme }) => theme.primaryFontColor};
	background: ${({ theme }) => theme.inputBackgroundColorDefault};
	justify-content: space-between;
	border-radius: 8px;
	font-size: ${props => props.fontSize};
	border: 1px solid ${({ theme }) => theme.inputBorderColorDefault};
	cursor: pointer;
	&:focus {
		outline: none;
		box-shadow: 0px 0px 1px 0px ${({ theme }) => theme.inputBorderColorActive};
	}

	&::-webkit-input-placeholder {
		color: ${props => props.placeholderColor};
	}
`;

const DropdownHeaderText = styled("span")`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
`;

const DropdownListContainer = styled("div")`
	position: absolute;
	z-index: 10;
	width: 100%;
	top: calc(100% + 5px);
	box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	overflow: hidden;
`;

const DropdownList = styled("div")`
	font-size: ${props => props.fontSize};
`;

const ListItem = styled("div")`
	background-color: ${({ theme }) => theme.selectMenuBackgroundColor};
	color: ${({ theme }) => theme.primaryFontColor};
	padding: ${whitespace.normal};
	font-weight: ${({ isHighlighted }) => (isHighlighted ? "bold" : "normal")};
	font-size: ${props => props.fontSize};
	position: relative;
	cursor: pointer;
	text-align: left;
	&:hover {
		background: ${({ theme }) => theme.highlight};
	}
`;

const Select = ({
	onChange,
	options,
	getValue = getValueDefault,
	getLabel = getLabelDefault,
	value,
	isDisabled,
	height,
	fontSize,
}) => {
	const dropdownRef = useRef(null);

	const [isOpened, setIsOpened] = useState(false);

	useClickAway(() => {
		setIsOpened(false);
	}, dropdownRef);

	const handleClickDropdownHeader = useCallback(() => setIsOpened(prevIsOpened => !prevIsOpened), []);

	const handleSelect = useCallback(
		value => {
			setIsOpened(false);
			onChange({ target: { value } });
		},
		[onChange]
	);

	const optionList = useMemo(() => {
		return options.map(option => {
			return (
				<ListItem key={getValue(option)} onClick={() => handleSelect(getValue(option))}>
					<DropdownHeaderText>{getLabel(option)}</DropdownHeaderText>
				</ListItem>
			);
		});
	}, [options, getLabel, getValue, handleSelect]);

	const selectedOptionLabel = useMemo(() => {
		const selectedOption = options.find(o => getValue(o) === value);

		if (!selectedOption) {
			return "";
		}

		return getLabel(selectedOption);
	}, [value, options, getValue, getLabel]);

	return (
		<DropdownContainer ref={dropdownRef} disabled={isDisabled}>
			<DropdownHeader height={height} fontSize={fontSize} onClick={handleClickDropdownHeader}>
				<DropdownHeaderText>{selectedOptionLabel}</DropdownHeaderText>
				<Dropdown16 />
			</DropdownHeader>

			{isOpened && (
				<DropdownListContainer>
					<DropdownList>{optionList}</DropdownList>
				</DropdownListContainer>
			)}
		</DropdownContainer>
	);
};

export default memo(Select);
