import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";
import Row from "../layout/Row";
import Close from "../Icons/Close";

const DivStyling = {
	zIndex: "8500",
};

const Backdrop = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	vertical-align: middle;
	background-color: rgba(0, 0, 0, 0.75);
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	z-index: 9000;
	background-color: ${({ theme }) => theme.cardBackgroundColorDefault};
	min-width: 300px;
	border-radius: 16px;
	padding: ${props => props.padding};
	justify-content: center;
	overflow: hidden;
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	position: relative;
`;

const IconHolder = styled.div`
	color: ${({ theme }) => theme.primaryFontColor};
	margin-right: ${whitespace.medium};
	cursor: pointer;
	position: absolute;
	top: 12px;
	right: -4px;
	z-index: 9000;
`;

const Modal = ({ isToggled, action, children, padding }) => {
	return (
		<AnimatePresence>
			{isToggled && (
				<>
					<motion.div
						style={DivStyling}
						transition={{ duration: 0.2 }}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<Backdrop>
							<motion.div
								transition={{
									type: "tween",
									ease: [0.12, 0.75, 0.02, 1.025],
									duration: 0.4,
									delay: 0.1,
								}}
								initial={{ y: 100, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								exit={{ y: 0, opacity: 0 }}
							>
								<Container padding={padding}>
									<Row flexDirection="row" justifyContent="flex-end">
										<IconHolder>
											<Close onClick={action} />
										</IconHolder>
									</Row>
									{children}
								</Container>
							</motion.div>
						</Backdrop>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	);
};

export default Modal;
