const userIconColors = [
	"#C9C2FF",
	"#B8CEFF",
	"#A3DEFA",
	"#9CFCE6",
	"#99FF85",
	"#C9FC59",
	"#FCE959",
	"#FCBB59",
	"#FCB29C",
	"#FC9C9C",
];

export const signIn = credentials => {
	return (dispatch, getState, { getFirebase }) => {
		const firebase = getFirebase();

		firebase
			.auth()
			.signInWithEmailAndPassword(credentials.email, credentials.password)
			.then(() => {
				dispatch({ type: "LOGIN_SUCCESS" });
			})
			.catch(err => {
				dispatch({ type: "LOGIN_ERROR", err });
			});
	};
};

export const signOut = () => {
	return (dispatch, getState, { getFirebase }) => {
		const firebase = getFirebase();
		firebase
			.auth()
			.signOut()
			.then(() => {
				dispatch({ type: "SIGNOUT_SUCCESS" });
			});
	};
};

export const signUp = newUser => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();
		const firestore = getFirestore();

		firebase
			.auth()
			.createUserWithEmailAndPassword(newUser.email, newUser.password)
			.then(resp => {
				return firestore
					.collection("users")
					.doc(resp.user.uid)
					.set({
						emailAddress: newUser.email,
						firstName: newUser.firstName,
						lastName: newUser.lastName,
						initials: newUser.firstName[0],
						iconColor: userIconColors[Math.floor(Math.random() * userIconColors.length)],
					});
			})
			.then(() => {
				dispatch({ type: "SIGNUP_SUCCESS" });
			})
			.catch(err => {
				dispatch({ type: "SIGNUP_ERROR", err });
			});
	};
};

export const resetPassword = mailrequest => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firebase = getFirebase();

		firebase
			.auth()
			.sendPasswordResetEmail(mailrequest.email)
			.then(() => {
				dispatch({ type: "PASSWORD_RESET_SUCCESS" });
			})
			.catch(err => {
				dispatch({ type: "PASSWORD_RESET_ERROR", err });
			});
	};
};
