import styled from "styled-components";

const Image = styled.div`
	flex-grow: ${props => props.flexGrow};
	height: ${props => props.height};
	width: ${props => props.width};
	background-color: ${props => props.backgroundColor};
	background-image: url("${props => props.img}");
	background-size: cover;
	background-position: center;
	border-radius: 4px;
	position: ${props => props.position};
	position: absolute;
`;

export default Image;
