import * as React from "react";
import fontsize from "../tokens/FontSizes";
import styled from "styled-components";

const Caption = styled.div`
	display: flex;

	color: ${props => props.color};
	text-align: ${props => props.textAlign};
	font-weight: ${props => props.fontWeight};
	font-size: ${fontsize.caption};
	padding-top: ${props => props.paddingTop};
	padding-bottom: ${props => props.paddingBottom};
	margin-top: ${props => props.marginTop};
	margin-bottom: ${props => props.marginBottom};
	margin-right: ${props => props.marginRight};
	margin-left: ${props => props.marginLeft};
	line-height: 20px;

	@media (max-width: 800px) {
	}
`;

export function CaptionType({
	color,
	fontWeight,
	textAlign,
	fontSize,
	marginTop,
	marginBottom,
	marginLeft,
	marginRight,
	paddingTop,
	paddingBottom,
}) {
	return <Caption></Caption>;
}

export default Caption;
