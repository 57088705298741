import React, { PureComponent } from "react";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";

const getBackground = ({ primary, destructive, basic, textButton, theme } = {}) => {
	if (primary) {
		return theme.primaryButtonBackgroundColorDefault;
	}

	if (destructive) {
		return theme.destructive;
	}

	if (basic) {
		return theme.basicButtonBackgroundColorDefault;
	}

	if (textButton) {
		return "none";
	}

	return theme.secondaryButtonBackgroundColorDefault;
};

const getColor = ({ primary, destructive, basic, textButton, theme } = {}) => {
	if (primary) {
		return theme.primaryButtonTextColorDefault;
	}

	if (destructive) {
		return theme.destructiveButtonTextColorDefault;
	}

	if (basic) {
		return theme.basicButtonTextColorDefault;
	}

	if (textButton) {
		return theme.secondaryButtonTextColorDefault;
	}

	return theme.secondaryButtonTextColorDefault;
};

const getAlignment = ({ primary, destructive, basic, textButton, theme } = {}) => {
	if (primary) {
		return "center";
	}

	if (destructive) {
		return "center";
	}

	if (basic) {
		return "center";
	}

	if (textButton) {
		return "left";
	}

	return "center";
};

export const ButtonBasic = styled.button`
	width: ${props => props.width};
	max-height: 50px;
	max-width: ${props => props.maxWidth};
	display: inline-block;
	padding: ${whitespace.small};
	padding-left: ${whitespace.medium};
	padding-right: ${whitespace.medium};
	color: ${props => getColor(props)};
	background: ${props => getBackground(props)};
	font-size: 16px;
	font-weight: 600;
	border-radius: 8px;
	margin-top: ${props => props.marginTop};
	margin-bottom: ${props => props.marginBottom};
	margin-left: ${props => props.marginLeft};
	margin-right: ${props => props.marginRight};
	align-items: ${props => getAlignment(props)};
	text-align: ${props => getAlignment(props)};
	vertical-align: middle;
	line-height: 28px;
	cursor: pointer;
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	border: none;

	${props => props.disabled && "opacity: 0.5; cursor: not-allowed;"}

	&:hover {
	}
	@media (max-width: 420px) {
		padding: 8px;
		font-size: 14px;
	}
`;

class Button extends PureComponent {
	render() {
		return (
			<ButtonBasic
				className={this.props.className}
				width={this.props.width}
				maxWidth={this.props.maxWidth}
				onClick={this.props.onClick}
				primary={this.props.design}
				destructive={this.props.destructive}
				basic={this.props.basic}
				textButton={this.props.textButton}
				marginLeft={this.props.marginLeft}
				marginRight={this.props.marginRight}
				marginTop={this.props.marginTop}
				marginBottom={this.props.marginBottom}
				disabled={this.props.disabled}
				as={this.props.as}
				theme={this.props.theme}
				color={this.props.color}
			>
				{this.props.text}
			</ButtonBasic>
		);
	}
}

export default Button;
