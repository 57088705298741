import React, { memo, useContext, useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext, ThemeProvider } from "styled-components";
import toast from "react-hot-toast";

import Row from "./Row";
import whitespace from "../tokens/Whitespace";
import Card from "./Card";
import Caption from "./Caption";
import ContextMenu from "../Icons/ContextMenu";
import { projectHandlerFactory } from "../utils/projectHandlers";
import MiniArrow from "../Icons/MiniArrow";
import { getEmojiByIndex } from "../utils/emojis";
import Body from "./Body";
import createConfirmDeleteModal from "./ConfirmDeleteModal";
import { deleteProjectAction, duplicateProjectAction } from "../../store/actions/projectActions";
import Close from "../Icons/Close";
import useClickAway from "ahooks/lib/useClickAway";

const NotificationContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.tooltipBackgroundColor};
	border-radius: 8px;
	padding: 12px;
	align-items: center;
	box-shadow: 0 1px 28px -4px rgba(0, 0, 0, 0.45);
	min-width: 200px;
`;

const StyledNotificationText = styled(Caption)`
	color: ${({ theme }) => theme.tooltipTextColor};
`;

const StyledNotificationCloseButton = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	margin-left: 20px;
	cursor: pointer;

	svg {
		width: 24px;
		height: 24px;
		color: ${({ theme }) => theme.tooltipTextColor};
	}
`;

const StyledContextMenuWrapper = styled(motion.div)`
	border-radius: 8px;
	color: ${({ theme }) => theme.primaryFontColor};
	width: 40px;
	height: 40px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 10px;
	right: 10px;
	background: ${props => props.backgroundColor};
`;

const DropDown = styled(motion.ul)`
	width: 150px;
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 4px;
	position: absolute;
	border-radius: 8px;
	padding: ${whitespace.small};
	bottom: 35px;
	right: 10px;
	background-color: ${({ theme }) => theme.selectMenuBackgroundColor};
	z-index: 1;
	box-shadow: 0 1px 4px rgba(${({ theme }) => theme.cardShadowColor} 0.03),
		0 2px 14px rgba(${({ theme }) => theme.cardShadowColor} 0.03),
		0 4px 20px rgba(${({ theme }) => theme.cardShadowColor} 0.03),
		0 8px 24px rgba(${({ theme }) => theme.cardShadowColor} 0.05),
		0 16px 38px rgba(${({ theme }) => theme.cardShadowColor} 0.05),
		0 32px 68px rgba(${({ theme }) => theme.cardShadowColor} 0.05);
`;

const NestedDropDown = styled(DropDown)`
	left: ${({ oppositeDropDownSide, extend }) =>
		oppositeDropDownSide ? (extend ? "calc(-100% - 5px)" : "calc(-50% - 5px)") : "calc(100% + 5px)"};
	padding: ${whitespace.small};
	top: 0;
	height: min-content;
	max-height: 240px;
	overflow-y: scroll;
	width: ${({ extend }) => (extend ? "100%" : "50%")};
	background-color: ${({ theme }) => theme.selectMenuBackgroundColor};
	min-width: ${({ extend }) => (extend ? "200px" : "100px")};
`;

const ArrowOuter = styled.div`
	position: absolute;
	right: 5px;
	top: 8px;
`;

const DropDownItem = styled(motion.li)`
	padding: 8px;
	max-width: 220px;
	text-overflow: ellipsis;
	color: ${({ color }) => color ?? "white"};
	flex: 1;
	position: relative;
	white-space: nowrap;
	border-radius: 8px;

	&:hover {
		background-color: ${({ theme }) => theme.highlight};
	}
`;

const Break = styled.div`
	width: 10px;
	height: 115px;
	position: absolute;
	right: ${({ alignment }) => (alignment === "left" ? "99%" : "-8px")};
`;

const ItemCard = ({ project, marginLeft, marginRight, setActiveDropDownId, activeDropDownId }) => {
	const [isCardHovered, setIsCardHovered] = React.useState(false);
	const [nestedDropDownActivator, setNestedDropDownActivator] = React.useState(null);
	const contextMenuRef = React.useRef(null);
	const dropDownRef = React.useRef(null);
	const cardRef = React.useRef(null);
	const folders = useSelector(state => state.firestore.ordered.folders);
	const dispatch = useDispatch();
	const theme = useContext(ThemeContext);

	useClickAway(event => {
		if (contextMenuRef.current && contextMenuRef.current.contains(event.target.closest("svg"))) {
			return;
		}

		setActiveDropDownId(null);
	}, dropDownRef);

	const handleClickDelete = ({ id }) => {
		createConfirmDeleteModal({
			headerText: "Delete file?",
			descriptionNode: (
				<Body color={({ theme }) => theme.primaryFontColor}>You won't be able to retrieve it after this action</Body>
			),
			theme,
			onDelete: () => dispatch(deleteProjectAction(id)),
		});
	};

	const listCopyTargets = () => {
		const targets = { mpegUrl: "MPEG", json: "JSON", svgUrl: "SVG", pngUrl: "PNG" };

		return Object.entries(project)
			.filter(([key]) => Object.keys(targets).includes(key))
			.map(([key, value]) => {
				return [targets[key], value];
			})
			.reduce(
				(acc, [key, value]) => ({
					...acc,
					[key]: value,
				}),
				{}
			);
	};

	function isCloseToRightEdge(element, threshold = 0) {
		if (!element || !element.current) {
			return;
		}

		const rect = element.current.getBoundingClientRect();
		return window.innerWidth - rect.right <= threshold;
	}

	const handleDuplicateProject = (id, title) => {
		const projectWithNewFolder = { ...project, folder: id };
		dispatch(duplicateProjectAction(projectWithNewFolder));

		setActiveDropDownId(null);

		toast.custom(
			t => (
				<ThemeProvider theme={theme}>
					<NotificationContainer>
						<StyledNotificationText>{`${project.title} cloned to ${title}`}</StyledNotificationText>
						<StyledNotificationCloseButton onClick={() => toast.dismiss(t.id)}>
							<Close />
						</StyledNotificationCloseButton>
					</NotificationContainer>
				</ThemeProvider>
			),
			{
				duration: 10000,
				canDismiss: true,
			}
		);
	};

	const projectHandler = useMemo(() => projectHandlerFactory(project), [project]);

	const cardImg = useMemo(() => {
		if (!projectHandler) {
			return null;
		}

		const { ListingCardCover } = projectHandler;

		return <ListingCardCover project={project} />;
	}, [projectHandler, project]);

	if (!projectHandler) {
		return null;
	}

	return (
		<Card
			cursor="pointer"
			marginLeft={marginLeft}
			marginRight={marginRight}
			minHeight="360px"
			padding={whitespace.medium}
			onMouseOver={() => setIsCardHovered(true)}
			onMouseLeave={() => setIsCardHovered(false)}
			ref={cardRef}
		>
			{cardImg}

			<Row marginTop={whitespace.medium} marginBottom={whitespace.small}>
				<Caption textAlign="left" fontWeight="400" color={({ theme }) => theme.secondaryFontColor}>
					{project.title}
				</Caption>
			</Row>
			<StyledContextMenuWrapper
				animate={isCardHovered || project.id === activeDropDownId ? { opacity: 1 } : { opacity: 0 }}
				backgroundColor={project.id === activeDropDownId && theme.iconButtonPressed}
				ref={contextMenuRef}
				onClick={event => {
					event.preventDefault();
					event.stopPropagation();
					setActiveDropDownId(project.id);

					if (project.id === activeDropDownId) {
						setActiveDropDownId(null);
					}
				}}
			>
				<ContextMenu />
			</StyledContextMenuWrapper>
			<AnimatePresence>
				{activeDropDownId === project.id && (
					<DropDown
						initial={{ opacity: 0, y: 30 }}
						animate={{ opacity: 1, y: -2 }}
						exit={{ opacity: 0, y: 30 }}
						transition={{ duration: 0.4, ease: [0.12, 0.75, 0.02, 1.025] }}
						onHoverEnd={() => setNestedDropDownActivator(null)}
						onClick={event => {
							event.stopPropagation();
							event.preventDefault();
						}}
						ref={dropDownRef}
					>
						<Break alignment="left" aria-hidden />
						<DropDownItem
							color={({ theme }) => theme.primaryFontColor}
							onHoverStart={() => setNestedDropDownActivator("clone")}
							active={nestedDropDownActivator === "clone"}
						>
							<>
								Clone to...
								<ArrowOuter>
									<MiniArrow />
								</ArrowOuter>
							</>
						</DropDownItem>
						<DropDownItem
							color={({ theme }) => theme.primaryFontColor}
							onHoverStart={() => setNestedDropDownActivator("copy")}
							active={nestedDropDownActivator === "copy"}
						>
							<>
								Copy link...
								<ArrowOuter>
									<MiniArrow />
								</ArrowOuter>
							</>
						</DropDownItem>
						<DropDownItem
							color={({ theme }) => theme.destructive}
							onHoverStart={() => setNestedDropDownActivator(null)}
							onClick={() => {
								handleClickDelete(project);
								setActiveDropDownId(null);
							}}
						>
							Delete
						</DropDownItem>
						<Break alignment="right" aria-hidden />
						<AnimatePresence>
							{nestedDropDownActivator && (
								<NestedDropDown
									initial={{ opacity: 0, x: -20 }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0, x: -20 }}
									transition={{ duration: 0.25, ease: [0.12, 0.75, 0.02, 1.025] }}
									extend={nestedDropDownActivator === "clone"}
									oppositeDropDownSide={isCloseToRightEdge(cardRef, 200)}
								>
									{nestedDropDownActivator === "copy"
										? Object.entries(listCopyTargets()).map(([formatName, link], index) => (
												<DropDownItem
													color={({ theme }) => theme.primaryFontColor}
													onClick={() => {
														navigator.clipboard.writeText(link);
														setActiveDropDownId(null);
													}}
													key={index}
												>
													{formatName}
												</DropDownItem>
										  ))
										: folders.map(({ title, emojiIndex, id }, index) => (
												<DropDownItem
													color={({ theme }) => theme.primaryFontColor}
													onClick={() => handleDuplicateProject(id, title)}
													key={index}
												>{`${emojiIndex ? getEmojiByIndex(emojiIndex) : ""} ${title}`}</DropDownItem>
										  ))}
								</NestedDropDown>
							)}
						</AnimatePresence>
					</DropDown>
				)}
			</AnimatePresence>
		</Card>
	);
};

export default memo(ItemCard);
