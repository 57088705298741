import React from "react";
import fontsize from "../tokens/FontSizes";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";

const H1 = styled.div`
    display: flex;

    color: ${({ theme }) => theme.primaryFontColor};
    text-align: ${(props) => props.textAlign};
    font-weight: ${(props) => props.fontWeight};
    font-size: ${fontsize.h1};
    padding-top: ${whitespace.small};
`;

export function Headline1({ textColor, fontWeight, textAlign, fontSize }) {
    return <H1></H1>;
}

export default H1;
