import { isHexColor, updateAnimationElementsWithColor } from "../../../Components/utils/lottie";
import { setColorsAction, setIsDisabledAction, updateAnimationAction } from "../../actions/editorActions";

const handleUpdateColor = ({ getState, dispatch }, next, action) => {
	const { colorId, value: color } = action.payload;

	if (!isHexColor(color)) {
		return;
	}

	const { colors, animation } = getState().editor;

	const selectedColorIndex = colors.findIndex(c => c.id === colorId);

	if (selectedColorIndex < 0) {
		return;
	}

	const newColors = [...colors];
	newColors[selectedColorIndex] = {
		...newColors[selectedColorIndex],
		value: color,
	};

	const newAnimation = updateAnimationElementsWithColor(animation, newColors[selectedColorIndex]);

	dispatch(setColorsAction(newColors));
	dispatch(updateAnimationAction(newAnimation));
	dispatch(setIsDisabledAction(false));
};

export default handleUpdateColor;
