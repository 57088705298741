import * as React from "react";

const Keyframes = () => (
	<svg width="24px" height="24px" viewBox="0 0 24 24">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M16.1568542,6.43933983 L20.4497475,10.732233 C21.0355339,11.3180195 21.0355339,12.267767 20.4497475,12.8535534 L16.1568542,17.1464466 C15.5710678,17.732233 14.6213203,17.732233 14.0355339,17.1464466 L11.944,15.055 L9.85355339,17.1464466 C9.26776695,17.732233 8.31801948,17.732233 7.73223305,17.1464466 L3.43933983,12.8535534 C2.85355339,12.267767 2.85355339,11.3180195 3.43933983,10.732233 L7.73223305,6.43933983 C8.31801948,5.85355339 9.26776695,5.85355339 9.85355339,6.43933983 L11.944,8.53 L14.0355339,6.43933983 C14.6213203,5.85355339 15.5710678,5.85355339 16.1568542,6.43933983 Z M8.61611652,8.38388348 L5.38393143,11.6160686 C5.28630035,11.7136996 5.28630035,11.8719909 5.38393143,11.969622 L8.61611652,15.2018071 C8.7137476,15.2994381 8.87203884,15.2994381 8.96966991,15.2018071 L10.53,13.641 L9.74264069,12.8535534 C9.15685425,12.267767 9.15685425,11.3180195 9.74264069,10.732233 L10.53,9.944 L8.96966991,8.38388348 C8.87203884,8.2862524 8.7137476,8.2862524 8.61611652,8.38388348 Z"
				fill="currentcolor"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);

export default Keyframes;
