import * as React from "react";
import { motion } from "framer-motion";

const Folder = ({ styles = {}, ...props }) => (
    <motion.div
        whileHover={{
            scale: 1.15,
            transition: { ease: [0.2, 0.8, 0.2, 1], duration: 0.3 },
        }}
        whileTap={{ scale: 0.75 }}
    >
        <svg height="32px" viewBox="0 0 32 32" width="32px" {...props}>
            <g fill="currentColor" fillRule="nonzero">
                <path
                    fill="currentColor"
                    d="M27,14v3l0,0.6c-0.1,7.5-2.4,9.4-11,9.4l-0.7,0C7.1,26.9,5,24.8,5,17V9c0-1.1,0.9-2,2-2h5.3
	c0.6,0,1.1,0.2,1.5,0.6l1.9,2c0.2,0.2,0.5,0.3,0.7,0.3H23l0,0C25.2,10,27,11.8,27,14z"
                />
            </g>
        </svg>
    </motion.div>
);

export default Folder;
