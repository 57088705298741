import React, { Component } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import styled from "styled-components";

const FlexBox = styled.div`
	margin: auto;
	align-items: center;
	justify-content: center;
	align-content: center;
	display: flex;
	position: ${props => props.position};
`;
class PageAnimationPlayer extends Component {
	constructor(props) {
		super(props);
		this.player = React.createRef();
	}

	doSomething() {
		this.player.current.play(); // make use of the player and call methods
	}

	render() {
		return (
			<FlexBox>
				<Player
					onEvent={event => {
						if (event === "load") this.doSomething(); // check event type and do something
					}}
					ref={this.player}
					autoplay={true}
					loop={true}
					background={this.props.background}
					controls={true}
					src={this.props.src}
					style={{ height: "90%", width: "90%" }}
					position={this.props.position}
				/>
			</FlexBox>
		);
	}
}

export default PageAnimationPlayer;
