import * as React from "react";

import ColorInfo from "../../layout/ColorInfo";
import ProjectResolution from "../../layout/ProjectResolution";
import ProjectStrokeWidths from "../../layout/StrokeWidths";

const Form = ({ formData, onChange, onColorChange, onStrokeChange }) => {
	const { backgroundColor, colors, size, strokeWidths } = formData;

	const handleChange = update => {
		onChange(currentValues => ({ ...currentValues, ...update }));
	};

	const handleColorChange = ({ id, value }) => {
		const colorsClone = [...colors];
		const updatedColorIndex = colorsClone.findIndex(color => color.id === id);
		if (updatedColorIndex > -1) {
			colorsClone[updatedColorIndex].value = value;
			handleChange({ colors: colorsClone });
			onColorChange(colorsClone[updatedColorIndex]);
		}
	};

	return (
		<>
			{backgroundColor && (
				<ColorInfo
					group="Background"
					value={backgroundColor}
					onChange={backgroundColor => handleChange({ backgroundColor })}
				/>
			)}
			{colors &&
				colors.map((color, colorIndex) => {
					return (
						<ColorInfo
							key={color.id}
							group={`Color ${colorIndex + 1}`}
							value={color.value ?? color.color}
							onChange={value => handleColorChange({ id: color.id, value })}
						/>
					);
				})}
			{Boolean(strokeWidths?.value.length) && (
				<ProjectStrokeWidths strokeWidths={strokeWidths} onStrokeChange={onStrokeChange} />
			)}
			<ProjectResolution
				width={size.width}
				height={size.height}
				multiplier={size.multiplier}
				onChangeMultiplier={multiplier => handleChange({ size: { ...size, multiplier } })}
			/>
		</>
	);
};

export default Form;
