import { useEffect, useState } from "react";
import noop from "lodash/noop";

const useDownloadAnimation = (dataUrl, onSuccess = noop, onError = noop) => {
	const [loadingStatus, setLoadingStatus] = useState("loading");

	const [animationData, setAnimationData] = useState(null);

	useEffect(() => {
		const setError = (...messages) => {
			console.error(...messages);
			setLoadingStatus("error");
			const [, ...nativeMessages] = messages;
			onError(...nativeMessages);
		};

		const handleReadJson = event => {
			try {
				const animationObject = JSON.parse(String(event.target.result));
				setAnimationData(animationObject);
				setLoadingStatus("success");
				onSuccess(animationObject);
			} catch (error) {
				setError("error parsing file", error);
			}
		};

		const handleErrorReadingJson = (...args) => {
			setError("error reading file", ...args);
		};

		const handleDownloadJson = event => {
			const blob = event.currentTarget.response;

			const fr = new FileReader();
			fr.onload = handleReadJson;
			fr.onerror = handleErrorReadingJson;

			fr.readAsText(blob);
		};

		const handleErrorDownloadingJson = (...args) => {
			setError("error downloading", ...args);
		};

		const fetchData = () => {
			const xhr = new XMLHttpRequest();
			xhr.responseType = "blob";

			xhr.onload = handleDownloadJson;
			xhr.onerror = handleErrorDownloadingJson;

			xhr.open("GET", dataUrl);
			xhr.send();
		};

		if (!dataUrl) {
			return;
		}

		fetchData();
	}, [dataUrl, onError, onSuccess]);

	return [loadingStatus, animationData];
};

export default useDownloadAnimation;
