import React, { memo } from "react";
import noop from "lodash/noop";
import { motion } from "framer-motion";
import { updateProjectAnimation } from "../../../store/actions/projectActions";
import useDownloadAnimation from "../../../hooks/useDownloadAnimation";
import withFetchAnimation from "../../animation/withFetchAnimation";

import Caption from "../../layout/Caption";
import ProjectResolution from "../../layout/ProjectResolution";
import ColorInfo from "../../layout/ColorInfo";
import LottieViewerContainer from "../../animation/LottieViewerContainer";
import RenderedLink from "../../layout/RenderedLink";
import Row from "../../layout/Row";
import Processing from "../../layout/Processing";
import Button from "../../layout/Button";

import whitespace from "../../tokens/Whitespace";

import { ListingCardWrapper } from "./_common";
import { getFileUrl } from "./index";

const AnimationViewer = withFetchAnimation(LottieViewerContainer);

function refreshPage() {
	window.location.reload(false);
}

export default class JsonProjectHandler {
	static ListingCardCover = memo(({ project }) => {
		return (
			<ListingCardWrapper>
				<AnimationViewer dataUrl={project.json} backgroundColor={project.backgroundColor} />
			</ListingCardWrapper>
		);
	});

	static EditorData = memo(({ colors, updateColor, project, onChangeMultiplier = noop }) => {
		return (
			<>
				{colors.map((color, colorIndex) => {
					return (
						<ColorInfo
							key={color.id}
							group={`Color ${colorIndex + 1}`}
							value={color.value}
							onChange={value => updateColor(color.id, { value })}
						/>
					);
				})}

				{project.size && (
					<ProjectResolution
						width={project.size.width}
						height={project.size.height}
						multiplier={project.size.multiplier}
						onChangeMultiplier={onChangeMultiplier}
					/>
				)}
			</>
		);
	});

	static Viewer = memo(({ animationData, project, setAnimation }) => {
		useDownloadAnimation(project && project.json, setAnimation);

		return (
			<LottieViewerContainer
				animationData={animationData}
				backgroundColor={project.backgroundColor}
				isJSONIconVisible
			/>
		);
	});

	static Actions = memo(({ project, isActionDisabled, saveColors }) => {
		if (project.isProcessing || !project.mpegUrl) {
			return <Processing />;
		}

		return (
			<Row flexDirection="column" marginBottom={whitespace.small} padding={whitespace.medium}>
				{isActionDisabled ? null : (
					<motion.div
						transition={{ duration: 0.3 }}
						initial={{ opacity: 0, y: 30 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 30 }}
					>
						<Row
							backgroundColor={({ theme }) => theme.cardBackgroundColorDefault}
							borderRadius="12px"
							padding={whitespace.medium}
							marginBottom={whitespace.medium}
							flexDirection="column"
						>
							<Caption color={({ theme }) => theme.primaryFontColor}>
								You've made changes in this file. Would you like to keep new settings and update links?
							</Caption>
							<Button
								marginTop={whitespace.medium}
								text={"Yes, update links"}
								design={"primary"}
								onClick={saveColors}
								disabled={isActionDisabled || project.isProcessing}
							/>
							<Button marginTop={whitespace.medium} text={"No, undo changes"} onClick={refreshPage} />
						</Row>
					</motion.div>
				)}

				{isActionDisabled ? (
					<Row flexDirection="column">
						<Caption fontWeight="600">Exported links</Caption>
						<Row flexDirection="column" marginTop={whitespace.medium}>
							{project.json && (
								<RenderedLink
									type="JSON"
									value={project.filePath ? getFileUrl(project.filePath) : project.json}
									name={`${project.title}.json`}
								/>
							)}
							{project.mpegUrl && <RenderedLink type="MP4" value={project.mpegUrl} name={`${project.title}.mp4`} />}
						</Row>
					</Row>
				) : null}
			</Row>
		);
	});

	static updateAssets({ dispatch, getState }) {
		const { firestore, editor } = getState();

		dispatch(updateProjectAnimation(firestore.data[`project_${editor.projectId}`], editor));
	}
}
