import noop from "lodash/noop";
import omit from "lodash/omit";

import { uploadFile } from "../../Components/utils/file";
import { functions } from "../../config/fbConfig";
import { extractLottieMeta } from "../../Components/projects/helpers";

const fetchAndUploadFileToStorage = async url => {
	try {
		const extension = url.substring(url.lastIndexOf(".") + 1).toLowerCase();

		if (extension !== "json") {
			return {};
		}

		const fetchResult = await fetch(url);
		const fileBlob = await fetchResult.blob();

		const [json, filePath] = await uploadFile({
			file: fileBlob,
			extension,
		});

		const { colors, size } = await extractLottieMeta(fileBlob);

		return {
			json,
			filePath,
			colors,
			size,
			isLoop: false,
		};
	} catch (err) {
		console.error("ERROR UPLOADING FILE", err);

		return {};
	}
};

export const createProject = ({ preview, extension, ...project }, onCreate = noop) => {
	return async (dispatch, getState, { getFirebase, getFirestore }) => {
		// make async call to database

		const uploadProject = async newProject => {
			const firestore = getFirestore();
			const author = getState().firebase.auth.uid;
			const additionalData = !newProject.filePath && (await fetchAndUploadFileToStorage(newProject.json));

			const payload = {
				...newProject,
				author,
				createdAt: new Date(),
				...(additionalData && { ...additionalData }),
			};

			firestore
				.collection("newProjects")
				.add(payload)
				.then(data => {
					onCreate(data.id);
					dispatch({ type: "CREATE_PROJECT_SUCCESS" });
				})
				.catch(err => {
					dispatch({ type: "CREATE_PROJECT_ERROR", err });
				});
		};

		if (extension === "json" && preview.file) {
			extractLottieMeta(preview.file).then(({ colors, size }) => {
				uploadProject({ colors, size, isLoop: false, ...project });
			});
		} else {
			uploadProject(project);
		}
	};
};

export const deleteProjectAction = id => {
	return (dispatch, getState, { getFirebase, getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection("newProjects")
			.doc(id)
			.delete()
			.then(() => {
				dispatch({ type: "DELETE_PROJECT_SUCCESS" });
			})
			.catch(err => {
				console.error("PROJECT DELETE ERROR", err);
				dispatch({ type: "DELETE_PROJECT_ERROR", payload: { err } });
			});
	};
};

export const duplicateProjectAction = project => {
	return (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();
		const author = getState().firebase.auth.uid;

		firestore
			.collection("newProjects")
			.add({
				...omit(project, ["id", "author"]),
				author,
				isDuplicate: true,
				createdAt: new Date(),
			})
			.then(() => {
				dispatch({ type: "DELETE_PROJECT_SUCCESS" });
			})
			.catch(err => {
				dispatch({ type: "DELETE_PROJECT_ERROR", err });
			});
	};
};

export const updateProject = (id, data, { isUpdateAssetsRequired = false } = {}, onDone = noop) => {
	return (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();

		return firestore
			.collection("newProjects")
			.doc(id)
			.update(data)
			.then(() => {
				if (isUpdateAssetsRequired) {
					const updateAssets = functions.httpsCallable("updateJsonTest");

					return updateAssets({ id })
						.then(() => {
							console.log("assets updated");
							onDone();
						})
						.catch(e => {
							console.log("error updating assets", e);
						});
				}
			})
			.catch(err => {
				console.log("error updating project", err);
			});
	};
};

export const updateProjectAnimation = (
	{ filePath, size, isLoop, colors, backgroundColor },
	{ animation, projectId },
	onJsonUpdate = noop,
	onFullUpdate = noop
) => {
	return dispatch => {
		if (!filePath) {
			return;
		}

		const update = () => {
			uploadFile({
				file: new Blob([JSON.stringify(animation)], {
					type: "application/json",
				}),
				path: filePath,
			}).then(([json, newFilePath]) => {
				onJsonUpdate();
				dispatch(
					updateProject(
						projectId,
						{
							json,
							filePath: newFilePath,
							colors,
							size,
							isLoop,
							backgroundColor,
						},
						{ isUpdateAssetsRequired: true },
						onFullUpdate
					)
				);
			});
		};

		update();
	};
};
