import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import fontsize from "../tokens/FontSizes";

const DivStyling = {
    position: "fixed",
    flexDirection: "row",
    zIndex: "9000",
    justifyContent: "center",
    alignItems: "center",
    top: (props) => props.top,
    marginLeft: "86px",
};

const Container = styled.div`
    top: ${(props) => props.top};
    position: relative;
    flex-direction: row;
    background-color: ${({ theme }) => theme.tooltipBackgroundColor};
    border-radius: 4px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 28px -4px rgba(0, 0, 0, 0.45);
`;

const Caption = styled.div`
    display: flex;
    font-size: ${fontsize.caption};
    color: ${({ theme }) => theme.tooltipTextColor};
`;

const Pointer = styled.div`
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.tooltipBackgroundColor};
    float: left;
    margin-top: 2px;
    margin-left: -16px;
    z-index: 9000;
    transform: rotate(45deg);
`;

const Tooltip = ({ isToggled, text, top }) => {
    return (
        <AnimatePresence>
            {isToggled && (
                <>
                    <motion.div
                        style={DivStyling}
                        transition={{
                            type: "tween",
                            ease: [0.12, 0.75, 0.02, 1.025],
                            duration: 0.4,
                            delay: 0.3,
                        }}
                        initial={{ x: -10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -10, opacity: 0 }}
                    >
                        <Container top={top}>
                            <Pointer />
                            <Caption>{text}</Caption>
                        </Container>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
};

export default Tooltip;
