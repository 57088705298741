import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import "firebase/auth";
import "./App.css";

import { ThemeProvider } from "styled-components";
import { useDarkMode } from "./hooks/useDarkMode";
import Avatar from "./Components/layout/Avatar";
import { lightTheme, darkTheme } from "./Components/Themes";
import AppWraper from "./Components/layout/AppWraper";
import Sidebar from "./Components/layout/Sidebar";
import ProjectList from "./Components/views/ProjectList";
import NewsDashboard from "./Components/views/NewsDashboard";
import ProjectView from "./Components/projects/ProjectView";
import SignIn from "./Components/auth/SignIn";
import SignUp from "./Components/auth/SignUp";
import Forgot from "./Components/auth/Forgot";
import FolderList from "./Components/views/FolderList";
import FolderConfirmation from "./Components/views/FolderConfirmation";
import WebsiteMenu from "./Components/layout/WebsiteMenu";
import Marketplace from "./Components/views/Marketplace";
import EmptyList from "./Components/layout/EmptyList";

const ProjectsView = () => {
	return (
		<>
			<FolderList />
			<ProjectList />
		</>
	);
};

const EmptyFolderListView = () => {
	return (
		<>
			<FolderList />
			<EmptyList center />
		</>
	);
};

const App = () => {
	const [theme, themeToggler, mountedComponent] = useDarkMode();

	const themeMode = theme === "light" ? lightTheme : darkTheme;

	if (!mountedComponent) {
		return <div>loading</div>;
	}

	return (
		<ThemeProvider theme={themeMode}>
			<Helmet>
				<title>Moko app</title>
				<meta
					name="description"
					content="Store visual assets in Moko cloud folders for usage in presentations, online projects, and prototypes by your team"
				/>
				<meta name="keywords" content="Lottie files, Customization, Brand assets" />
			</Helmet>
			<BrowserRouter>
				<AppWraper>
					<WebsiteMenu toggleTheme={themeToggler} theme={theme} />
					<Sidebar>
						<Avatar themeToggler={themeToggler} theme={theme} />
					</Sidebar>
					<Switch>
						<Route path="/NewsDashboard" component={NewsDashboard} />
						<Route exact path="/" component={NewsDashboard} />
						<Route exact path="/marketplace" component={Marketplace} />
						<Route exact path="/folders/:folderId" component={ProjectsView} />
						<Route exact path="/folders" component={EmptyFolderListView} />
						<Route path="/folders/:folderId/:id" component={ProjectView} />
						<Route path="/signin" component={SignIn} />
						<Route path="/signup" component={SignUp} />
						<Route path="/forgot" component={Forgot} />

						<Route path="/folder-confs/:confId" component={FolderConfirmation} />
					</Switch>
				</AppWraper>
			</BrowserRouter>
		</ThemeProvider>
	);
};

export default App;
