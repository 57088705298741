import React, { Component } from "react";
import styled from "styled-components";
import Loadericon from "../Icons/Loadericon";
import { motion } from "framer-motion";

const Rotator = styled.div`
    color: ${({ theme }) => theme.primaryFontColor};
    display: flex;
    width: 32px;
    height: 32px;
    opacity: 0.3;
`;

class Logoloader extends Component {
    render() {
        return (
            <Rotator>
                <motion.div
                    animate={{ rotate: 180 }}
                    transition={{ repeat: Infinity, duration: 1 }}
                >
                    <Loadericon />
                </motion.div>
            </Rotator>
        );
    }
}

export default Logoloader;
