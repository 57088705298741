import { FOLDER_SET_LIST } from "../constants/folderConstants";

const initState = {
	list: [],
};

const folderReducer = (state = initState, action) => {
	switch (action.type) {
		case FOLDER_SET_LIST:
			return {
				...state,
				list: action.payload,
			};

		default:
			return state;
	}
};

export default folderReducer;
