import { v4 as uuidv4 } from "uuid";

import { storage } from "../../config/fbConfig";
import ProjectErrors from "../../errors";

const getNewFilePath = extension => `${extension}/${uuidv4()}.${extension}`;

const getFilePath = ({ path, extension }) => {
	if (!path || path.split("/")[0] !== extension) {
		return getNewFilePath(extension);
	}

	return path;
};

export const getFileExtenstion = file => file.name.substring(file.name.lastIndexOf(".") + 1);

export const uploadFile = async ({ file, path, extension = "json" }) => {
	if (!["json"].includes(extension)) {
		throw new ProjectErrors({ name: "INVALID_FILE_TYPE", message: "JSON is required!" });
	}

	const filePath = getFilePath({ path, extension });
	const storageRef = storage.ref(filePath);

	const snapshot = await storageRef.put(file);
	const downloadUrl = await snapshot.ref.getDownloadURL();

	return [downloadUrl, filePath];
};

export const getPredictedFilePath = file => {
	const base = "https://firebasestorage.googleapis.com/v0/b/moko-app-bee21.appspot.com/o/";
	const filePath = getNewFilePath(getFileExtenstion(file));
	const altParam = "?alt=media";

	return {
		filePath,
		predictedUrl: base + encodeURIComponent(filePath) + altParam,
	};
};

export const deleteFile = async filePath => {
	const storageRef = storage.ref(filePath);

	await storageRef.delete();
};
