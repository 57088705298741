import styled from "styled-components";
import { motion } from "framer-motion";

const AlternateCard = styled(motion.div)`
	position: relative;
	min-height: ${({ minHeight = 0 }) => minHeight};
	height: ${({ activeFolder }) => (activeFolder ? "75px" : "35px")};
	cursor: ${props => props.cursor};
	background-color: ${({ activeFolder, backgroundColor, theme }) =>
		activeFolder ? theme.surfacePressed : backgroundColor};
	border-radius: 10px;
	padding: ${props => props.padding};
	flex-grow: 1;
	justify-content: flex-start;
	display: flex;
	flex-direction: column;
	margin-left: ${props => props.marginLeft};
	margin-right: ${props => props.marginRight};
	transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
	overflow: ${({ activeFolder }) => (activeFolder ? "visible" : "hidden")};
	color: ${({ activeFolder, color, colorOnHover }) => (activeFolder ? colorOnHover : color)};
`;

export default AlternateCard;
