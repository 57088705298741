import * as React from "react";
import styled from "styled-components";

const AppWraper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    background-color: ${({ theme }) => theme.surface};
    transition: 0.2s;
    font-family: "Rubik";
    position: fixed;
    min-height: 100vh;
`;

export function ApplicationWraper() {
    return <AppWraper></AppWraper>;
}

export default AppWraper;
