export const EMOJIS = [
	"🔥",
	"🛠",
	"💣",
	"❤️",
	"🎥",
	"🌈",
	"🖼",
	"⭐️",
	"👁",
	"💎",
	"🧠",
	"💬",
	"🤖",
	"📚",
	"🚀",
	"🦄",
	"🪐",
	"🎱",
	"🖐️",
	"💩",
	"🧵",
	"👑",
	"🍄",
	"🧩",
	"💈",
	"🔮",
	"🎈",
];

export const getEmojiByIndex = index => EMOJIS[index] || EMOJIS[0];
