import React, { memo, useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import noop from "lodash/noop";
import { useLocation } from "react-router-dom";

import styled from "styled-components";
import Input from "./Input";
import Row from "./Row";

const StyledRow = styled(Row)`
	position: relative;
`;

const StyledInput = styled(Input)`
	width: ${props => props.width};
	padding-left: 8px;
`;

const SearchInput = ({ width, placeholder, value = "", onChange = noop }) => {
	const [localValue, setLocalValue] = useState(value);
	const location = useLocation();

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	const debounceOnChange = useCallback(
		debounce(value => onChange(value), 500),
		[onChange]
	);

	useEffect(() => {
		setLocalValue("");
		debounceOnChange("");
	}, [location, debounceOnChange]);

	const handleChange = useCallback(
		({ target }) => {
			setLocalValue(target.value);
			debounceOnChange(target.value);
		},
		[debounceOnChange]
	);

	return (
		<StyledRow flexDirection="row" alignItems="flex-end">
			<StyledInput placeholder={placeholder} value={localValue} onChange={handleChange} width={width} />
		</StyledRow>
	);
};

export default memo(SearchInput);
