import React, { memo } from "react";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";

const InputField = styled.input`
	width: ${props => props.width};
	display: flex;
	flex-grow: 1;
	height: 40px;
	padding: ${whitespace.default};
	color: ${({ theme }) => theme.primaryFontColor};
	background: ${({ theme }) => theme.inputBackgroundColorDefault};

	border-radius: 8px;
	font-size: 15px;
	border: 1px solid ${({ theme }) => theme.inputBorderColorDefault};

	&:focus {
		outline: none;
		box-shadow: 0px 0px 2px 1px ${({ theme }) => theme.inputBorderColorActive};
	}

	&::-webkit-input-placeholder {
		color: ${props => props.placeholderColor};
	}
`;
const Input = ({ width, text, id, onChange, onValueChange, placeholder, value, className, pattern, ...otherProps }) => {
	return (
		<InputField
			{...otherProps}
			className={className}
			width={width}
			type={text}
			id={id}
			onChange={onChange}
			onValueChange={onValueChange}
			placeholder={placeholder}
			value={value}
			pattern={pattern}
		/>
	);
};

export default memo(Input);
