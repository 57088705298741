import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Container from "../layout/Container";
import H1 from "../layout/H1";
import H2 from "../layout/H2";
import styled from "styled-components";
import Body from "../layout/Body";
import Row from "../layout/Row";
import whitespace from "../tokens/Whitespace";
import CarouselUpdates from "../layout/CarouselUpdates/CarouselUpdates.tsx";
import LinkButton from "../layout/LinkButton";
import { motion } from "framer-motion";

const DashboardWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: center;
`;

const NewsDashboard = ({ profile, auth }) => {
	if (!auth.uid) {
		return <Redirect to="/signin" />;
	}

	return (
		<>
			<DashboardWrapper>
				<Container>
					<motion.div
						style={{ width: "320px" }}
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{
							ease: [0.2, 0.8, 0.2, 1],
							duration: 0.6,
							delay: 0,
						}}
					>
						<Row flexDirection="column" paddingTop={whitespace.xlarge} paddingBottom={whitespace.large}>
							<H1 fontWeight="600" textAlign="left">
								Hello {profile.firstName} 👋
							</H1>

							<Row>
								<Body
									color={({ theme }) => theme.primaryFontColor}
									marginTop={whitespace.default}
									fontWeight="400"
									textAlign="left"
								>
									Here you will find articles, videos, and tips about Moko and motion-design
								</Body>
							</Row>
						</Row>
					</motion.div>
					<Row flexDirection="row" width="100%">
						<motion.div
							style={{
								display: "flex",
								flexGrow: "2",
								minWidth: "400px",
							}}
							initial={{ opacity: 0, y: 50 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{
								ease: [0.2, 0.8, 0.2, 1],
								duration: 0.6,
								delay: 0.2,
							}}
						>
							<Row
								flexDirection="column"
								flexGrow="2"
								minWidth="400px"
								padding={whitespace.large}
								justifyContent="start"
								alignItems="center"
								backgroundColor={({ theme }) => theme.sidebarBackgroundColor}
								overflow="hidden"
								borderRadius="16px"
							>
								<Row width="100%" marginBottom={whitespace.medium}>
									<H2 width="100%" textAlign="center" fontWeight="600" marginBottom={whitespace.large}>
										Getting started
									</H2>
								</Row>
								<CarouselUpdates />
							</Row>
						</motion.div>

						<motion.div
							style={{ display: "flex", flexGrow: "1" }}
							initial={{ opacity: 0, y: 50 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{
								ease: [0.2, 0.8, 0.2, 1],
								duration: 0.6,
								delay: 0.3,
							}}
						>
							<Row
								flexDirection="column"
								flexGrow="1"
								marginRight="88px"
								padding={whitespace.large}
								justifyContent="start"
								alignItems="start"
								backgroundColor={({ theme }) => theme.sidebarBackgroundColor}
								marginLeft={whitespace.big}
								borderRadius="16px"
							>
								<H2 width="100%" fontWeight="600" textAlign="left">
									Workflow Tips
								</H2>

								<Row flexDirection="column" width="100%" justifyContent="start">
									<Row
										borderRadius="10px"
										flexDirection="column"
										marginTop={whitespace.big}
										marginBottom={whitespace.medium}
										padding={whitespace.medium}
										flexGrow="1"
										height="100%"
										backgroundColor={({ theme }) => theme.inputBackgroundColorDefault}
									>
										<Body
											fontWeight="600"
											marginBottom={whitespace.default}
											color={({ theme }) => theme.primaryFontColor}
										>
											Working with JSON animations
										</Body>
										<Body color={({ theme }) => theme.primaryFontColor}>
											Optimize files made in AE and exported with Bodymovin plugin
										</Body>
										<a href="https://moko-app.kb.help/general/working-with-json-files/" target="_blank">
											<LinkButton marginTop={whitespace.default} width="120px" text="Read more"></LinkButton>
										</a>
									</Row>

									<Row
										borderRadius="10px"
										flexDirection="column"
										marginBottom={whitespace.medium}
										padding={whitespace.medium}
										flexGrow="1"
										height="100%"
										backgroundColor={({ theme }) => theme.inputBackgroundColorDefault}
									>
										<Body
											fontWeight="600"
											marginBottom={whitespace.default}
											color={({ theme }) => theme.primaryFontColor}
										>
											Sync animations with Framer
										</Body>
										<Body color={({ theme }) => theme.primaryFontColor}>
											Learn how to enrich your prototypes with Lottie animations and interactive SVG
										</Body>
										<a href="https://moko-app.kb.help/general/sync-animations-from-moko-with-framer/" target="_blank">
											<LinkButton marginTop={whitespace.default} width="120px" text="Read more"></LinkButton>
										</a>
									</Row>

									<Row
										borderRadius="10px"
										flexDirection="column"
										justifyContent="center"
										alignItems="center"
										marginBottom={whitespace.big}
										padding={whitespace.medium}
										flexGrow="1"
										height="100%"
										backgroundColor={({ theme }) => theme.inputBackgroundColorDefault}
									>
										<Body color={({ theme }) => theme.secondaryFontColor}>☝ More tips are coming soon</Body>
									</Row>
								</Row>
							</Row>
						</motion.div>
					</Row>
				</Container>
			</DashboardWrapper>
		</>
	);
};

const mapStateToProps = state => {
	return {
		auth: state.firebase.auth,
		profile: state.firebase.profile,
	};
};

export default connect(mapStateToProps)(NewsDashboard);
