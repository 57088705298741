import * as React from "react";
import { motion } from "framer-motion";

const Marketplace = ({ styles = {}, ...props }) => (
    <motion.div
        whileHover={{
            scale: 1.15,
            transition: { ease: [0.2, 0.8, 0.2, 1], duration: 0.3 },
        }}
        whileTap={{ scale: 0.75 }}
    >
        <svg height="32px" viewBox="0 0 32 32" width="32px" {...props}>
            <g fill="currentColor" fillRule="nonzero">
                <path d="M7,18a1,1,0,0,1,1,1v.42H8V20c.2,5,1.34,5.9,6.61,6l.85,0H18c4.85-.19,5.85-1.33,6-6.38V19h0a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1v0C26.81,27,24.45,29,16,29H14.84C7.55,28.8,5.31,26.72,5,19.66L5,19a1,1,0,0,1,1-1ZM22.79,4A4,4,0,0,1,26.7,7.13L27,8.44l.31,1.2A20.34,20.34,0,0,1,28,13a3,3,0,0,1-6,.18h0a3,3,0,0,1-6,.17v-.17h0a3,3,0,0,1-6,.17v-.17h0A3,3,0,0,1,4,13,36.68,36.68,0,0,1,5.3,7.13,4,4,0,0,1,9,4H22.79Z" />
            </g>
        </svg>
    </motion.div>
);

export default Marketplace;
