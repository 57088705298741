import styled from "styled-components";
import { motion } from "framer-motion";
import whitespace from "../tokens/Whitespace";

const List = styled(motion.div)`
	text-decoration: none;
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: ${whitespace.medium};
	grid-row-gap: ${whitespace.medium};
`;

export default List;
