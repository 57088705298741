import styled from "styled-components";
import { motion } from "framer-motion";
import whitespace from "../tokens/Whitespace";

export const CardRemoveButton = styled.div`
	color: ${({ theme }) => theme.destructive};
	position: absolute;
	bottom: ${whitespace.even};
	right: ${whitespace.medium};
	display: none;
	width: 24px;
	height: 24px;
`;

export const DuplicateButton = styled.div`
	position: absolute;
	color: ${({ theme }) => theme.primaryFontColor};
	bottom: ${whitespace.even};
	right: calc(2 * ${whitespace.medium} + 24px);
	display: none;
	width: 24px;
	height: 24px;
`;

const Card = styled(motion.div)`
	position: relative;
	min-height: ${({ minHeight = 0 }) => minHeight};
	width: 100%;
	height: 390px;
	cursor: ${props => props.cursor};
	background-color: ${({ theme }) => theme.cardBackgroundColorDefault};
	border-radius: 16px;
	padding: ${props => props.padding};
	flex-grow: 1;
	justify-content: flex-start;
	display: flex;
	flex-direction: column;
	margin-left: ${props => props.marginLeft};
	margin-right: ${props => props.marginRight};
	box-shadow: 0px 0px 16px 0px rgba(19, 0, 94, 0);
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);

	&:hover {
		box-shadow: 0 1px 4px rgba(${({ theme }) => theme.cardShadowColor} 0.03),
			0 2px 10px rgba(${({ theme }) => theme.cardShadowColor} 0.03),
			0 4px 16px rgba(${({ theme }) => theme.cardShadowColor} 0.03),
			0 8px 20px rgba(${({ theme }) => theme.cardShadowColor} 0.05),
			0 16px 32px rgba(${({ theme }) => theme.cardShadowColor} 0.05),
			0 32px 64px rgba(${({ theme }) => theme.cardShadowColor} 0.05);
		background-color: ${({ theme }) => theme.cardBackgroundColorHover};

		${CardRemoveButton}, ${DuplicateButton} {
			display: block;
		}
	}
`;

export default Card;
