import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const withAuth = Component => props => {
	const auth = useSelector(state => state.firebase.auth);

	if (!auth.uid) {
		return <Redirect to="/signin" />;
	}

	return <Component {...props} />;
};

export default withAuth;
