import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { resetPassword } from "../../store/actions/authActions";
import { AnimatePresence, motion } from "framer-motion";

import styled from "styled-components";
import Row from "../layout/Row";
import fontsize from "../tokens/FontSizes";
import whitespace from "../tokens/Whitespace";
import H1 from "../layout/H1";
import Caption from "../layout/Caption";
import Button from "../layout/Button";

const FormContainer = styled.div`
	background-color: ${({ theme }) => theme.cardBackgroundColorDefault};
	border-radius: 16px;
	width: 30%;
	padding: ${whitespace.large};
	justify-content: center;
	display: flex;

	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);

	@media (max-width: 800px) {
		width: 50%;
		padding: ${whitespace.medium};
	}

	@media (max-width: 420px) {
		width: 80%;
		padding: ${whitespace.medium};
	}
`;
const Input = styled.input`
	display: flex;
	flex-grow: 1;
	height: 40px;
	padding: ${whitespace.normal};
	color: ${({ theme }) => theme.primaryFontColor};
	border: 1px solid ${({ theme }) => theme.inputBorderColorDefault};
	background: ${({ theme }) => theme.inputBackgroundColorDefault};
	border-radius: 8px;
	font-size: 15px;

	:focus {
		border: 1px solid ${({ theme }) => theme.inputBorderColorActive};
	}

	&::-webkit-input-placeholder {
		color: ${props => props.placeholderColor};
	}
`;

const Label = styled.label`
	display: flex;
	font-size: ${fontsize.body};
	text-align: left;
	color: ${({ theme }) => theme.primaryFontColor};
	margin-top: ${props => props.marginTop};
	margin-bottom: ${whitespace.default};
`;

const ValidationError = styled(Caption)`
	width: "fit-content";
	margin-top: 8px;
	color: ${({ theme }) => theme.destructive};
`;

const NotificationContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.tooltipBackgroundColor};
	border-radius: 8px;
	padding: 12px;
	align-items: center;
	box-shadow: 0 1px 28px -4px rgba(0, 0, 0, 0.45);
	min-width: 200px;
	margin-bottom: 200px;
`;

const StyledNotificationText = styled(Caption)`
	color: ${({ theme }) => theme.tooltipTextColor};
`;

class Forgot extends Component {
	state = {
		email: "",
	};

	handleChange = e => {
		this.setState({
			[e.target.id]: e.target.value,
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		this.props.resetPassword(this.state);
	};

	render() {
		const { resetPassError, resetPassSuccess, auth } = this.props;
		if (auth.uid) return <Redirect to="/" />;

		return (
			<Row flexDirection="column" flexGrow="1" justifyContent="center" alignItems="center">
				<AnimatePresence>
					{resetPassSuccess && (
						<motion.div
							transition={{
								type: "tween",
								ease: [0.12, 0.75, 0.02, 1.025],
								duration: 0.15,
							}}
							initial={{ opacity: 0, height: 0, y: 0 }}
							animate={{ opacity: 1, height: 30, y: -20 }}
							exit={{ opacity: 0, height: 0, y: 0 }}
						>
							<NotificationContainer>
								<StyledNotificationText>{resetPassSuccess}</StyledNotificationText>
							</NotificationContainer>
						</motion.div>
					)}{" "}
					: null
				</AnimatePresence>

				<FormContainer>
					<form
						style={{
							display: "flex",
							flexDirection: "column",
							flexGrow: "1",
						}}
						onSubmit={this.handleSubmit}
					>
						<H1 fontWeight="600" textAlign="center">
							Reset Password
						</H1>

						<Row flexDirection="column" marginTop={whitespace.big} marginBottom={whitespace.big}>
							<Label htmlFor="email">Email</Label>
							<Input placeholder="type your email..." type="email" id="email" onChange={this.handleChange} />
						</Row>

						<AnimatePresence>
							{resetPassError && (
								<motion.div
									transition={{
										type: "tween",
										ease: [0.12, 0.75, 0.02, 1.025],
										duration: 0.15,
									}}
									initial={{ opacity: 0, height: 0, y: 0 }}
									animate={{ opacity: 1, height: 30, y: -20 }}
									exit={{ opacity: 0, height: 0, y: 0 }}
								>
									<ValidationError>
										<p>{resetPassError}</p>
									</ValidationError>
								</motion.div>
							)}{" "}
							: null
						</AnimatePresence>

						<Button design="primary" text="Reset"></Button>
					</form>
				</FormContainer>
			</Row>
		);
	}
}

const mapStateToProps = state => {
	return {
		resetPassError: state.auth.resetPassError,
		resetPassSuccess: state.auth.resetPassSuccess,
		auth: state.firebase.auth,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		resetPassword: mailrequest => dispatch(resetPassword(mailrequest)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
