import React, { memo } from "react";
import styled from "styled-components";

import IconWraper from "../../layout/IconWraper";
import Processing from "../../Icons/Processing";
import Confirmed from "../../Icons/Confirmed";
import whitespace from "../../tokens/Whitespace";
import colors from "../../tokens/Colors";

const StyledIconWrapper = styled(IconWraper)`
	width: 32px;
	height: 32px;
	margin-left: ${whitespace.small};
	margin-top: 4px;
	color: ${({ theme }) => theme.primaryFontColor};
`;

const ConfirmStatus = ({ status }) => {
	return (
		<StyledIconWrapper>
			{status === "pending" && <Processing width={24} height={24} fill={colors.primary} />}
			{status === "confirmed" && <Confirmed width={24} height={24} fill={colors.green} />}
		</StyledIconWrapper>
	);
};

export default memo(ConfirmStatus);
