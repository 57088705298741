import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import TooltipPanel from "./TooltipPanel";
import AvatarRound from "./AvatarRound";

const DivStyling = {
    zIndex: "8000",
    position: "fixed",
    top: "0",
    left: "80px",
};

const SidebarAvatar = ({ profile, theme, themeToggler }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleCloseDropdown = useCallback(() => setIsVisible(false), []);

    return (
        <>
            <AvatarRound
                onClick={() => setIsVisible((value) => !value)}
                backgroundColor={profile.iconColor}
                as={"button"}
            >
                {profile.initials}
            </AvatarRound>
            {isVisible && (
                <motion.div
                    style={DivStyling}
                    transition={{
                        type: "tween",
                        ease: [0.12, 0.75, 0.02, 1.025],
                        duration: 0.4,
                    }}
                    initial={{ x: -10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -10, opacity: 0 }}
                >
                    <TooltipPanel
                        onClick={() => setIsVisible((value) => !value)}
                        onClose={handleCloseDropdown}
                        theme={theme}
                        toggleTheme={themeToggler}
                    />
                </motion.div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    };
};

export default connect(mapStateToProps)(SidebarAvatar);
