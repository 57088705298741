import {
    EDITOR_SET_ANIMATION,
    EDITOR_SET_COLORS,
    EDITOR_SET_PROJECT_ID,
    EDITOR_UPDATE_ANIMATION,
    EDITOR_CLEAR,
    EDITOR_SET_IS_DISABLED,
} from "../constants/editorConstants";

const initState = {
    animation: null,
    colors: [],
    projectId: null,
    isDisabled: true,
};

export default (state = initState, action) => {
    switch (action.type) {
        case EDITOR_SET_ANIMATION:
        case EDITOR_UPDATE_ANIMATION:
            return {
                ...state,
                animation: action.payload,
            };

        case EDITOR_SET_IS_DISABLED:
            return {
                ...state,
                isDisabled: action.payload,
            };

        case EDITOR_SET_COLORS:
            return {
                ...state,
                colors: action.payload,
            };

        case EDITOR_SET_PROJECT_ID:
            return {
                ...state,
                projectId: action.payload,
            };

        case EDITOR_CLEAR:
            return {
                ...initState,
            };

        default:
            return state;
    }
};
