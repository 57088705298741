import {
	EDITOR_SET_ANIMATION,
	EDITOR_UPDATE_COLOR,
	EDITOR_SAVE_COLORS,
	EDITOR_UPDATE_BACKGROUND,
	EDITOR_UPDATE_MULTIPLIER,
} from "../../constants/editorConstants";

import handleSetAnimation from "./handleSetAnimation";
import handleUpdateColor from "./handleUpdateColor";
import handleSaveColors from "./handleSaveColors";
import handleUpdateProject from "./handleUpdateProject";

export default store => next => action => {
	next(action);

	switch (action.type) {
		case EDITOR_SET_ANIMATION:
			handleSetAnimation(store, next, action);
			return;

		case EDITOR_UPDATE_COLOR:
			handleUpdateColor(store, next, action);
			return;

		case EDITOR_SAVE_COLORS:
			handleSaveColors(store);
			return;

		case EDITOR_UPDATE_BACKGROUND:
		case EDITOR_UPDATE_MULTIPLIER:
			handleUpdateProject(store, next, action);
			return;

		default:
			return;
	}
};
