import { setIsDisabledAction } from "../../actions/editorActions";
import { projectHandlerFactory } from "../../../Components/utils/projectHandlers";

const handleSaveColors = ({ dispatch, getState }) => {
	const { firestore, editor } = getState();

	const projectHandler = projectHandlerFactory(firestore.data[`project_${editor.projectId}`]);

	projectHandler.updateAssets({ dispatch, getState });

	dispatch(setIsDisabledAction(true));
};

export default handleSaveColors;
