import React, { useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import lottie from "lottie-web";
import cloneDeep from "lodash.clonedeep";

const LottieViewer = forwardRef(({ data, containerRef, isVisible, isProjectPath }, ref) => {
	const animationRef = useRef(null);

	useImperativeHandle(ref, () => ({
		play: () => {
			if (animationRef.current) animationRef.current.play();
		},
		pause: () => {
			if (animationRef.current) animationRef.current.pause();
		},
		stop: () => {
			if (animationRef.current) animationRef.current.stop();
		},
		setSpeed: speed => {
			if (animationRef.current) animationRef.current.setSpeed(speed);
		},
		goToAndStop: frame => {
			if (animationRef.current) animationRef.current.goToAndStop(frame, true);
		},
		getDuration: () => (animationRef.current ? animationRef.current.getDuration(true) : 0),
		getTotalFrames: () => (animationRef.current ? animationRef.current.totalFrames : 0),
		getCurrentFrame: () => (animationRef.current ? animationRef.current.currentFrame : 0),
	}));

	useEffect(() => {
		if (!data || !containerRef.current) {
			return;
		}

		if (animationRef.current) {
			animationRef.current.destroy();
		}

		animationRef.current = lottie.loadAnimation({
			container: containerRef.current,
			animationData: cloneDeep(data),
			autoplay: isProjectPath,
		});

		if (isVisible && !isProjectPath) {
			animationRef.current.play();
		}

		return () => {
			if (animationRef.current) {
				animationRef.current.destroy();
			}
		};
	}, [data, containerRef, isVisible, isProjectPath]);

	return null;
});

export default React.memo(LottieViewer);
