import React, { memo } from "react";
import whitespace from "../tokens/Whitespace";
import Row from "./Row";
import Caption from "./Caption";
import PageAnimationPlayer from "./PageAnimationPlayer";

const Processing = () => {
	return (
		<Row
			alignItems="center"
			justifyContent="start"
			backgroundColor={({ theme }) => theme.inputBackgroundColorDefault}
			borderRadius="12px"
			padding={whitespace.small}
			marginTop={whitespace.medium}
		>
			<Row width="64px" height="96px">
				<PageAnimationPlayer src="https://jsonproxy.web.app/json/62b6b250-0f57-40fe-a1a2-1e64c0e23fc5.json" />
			</Row>

			<Row width="180px" paddingLeft={whitespace.small} flexDirection="column">
				<Caption color={({ theme }) => theme.secondaryFontColor} fontWeight="400" textAlign="left">
					Updating MP4. It may take a while.
				</Caption>
			</Row>
		</Row>
	);
};

export default memo(Processing);
