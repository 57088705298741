import noop from "lodash/noop";

import { functions } from "../../config/fbConfig";

export const createFolderAction = (folder, onCreate = noop) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const author = getState().firebase.auth.uid;

        firestore
            .collection("folders")
            .add({
                ...folder,
                author,
                roles: {
                    [author]: "admin",
                },
                createdAt: new Date(),
            })
            .then((data) => {
                onCreate(data.id);
                dispatch({ type: "CREATE_FOLDER_SUCCESS", payload: data.id });
            })
            .catch((err) => {
                dispatch({ type: "CREATE_FOLDER_ERROR", err });
            });
    };
};

export const deleteFolderAction = (id) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        firestore
            .collection("folders")
            .doc(id)
            .delete()
            .then(() => {
                dispatch({ type: "DELETE_FOLDER_SUCCESS", payload: { id } });
            })
            .catch((err) => {
                console.error("ERROR DELETING FOLDER", err);
                dispatch({ type: "DELETE_FOLDER_ERROR", payload: { err } });
            });
    };
};

export const updateFolderAction = (folderId, data) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        firestore
            .collection("folders")
            .doc(folderId)
            .update({ ...data })
            .then((data) => {
                dispatch({ type: "UPDATE_FOLDER_SUCCESS", payload: data.id });
            })
            .catch((err) => {
                dispatch({ type: "UPDATE_FOLDER_ERROR", err });
            });
    };
};

export const createFolderConfirmationAction = (folderId, data) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        firestore
            .collection("folderConfirmations")
            .add({
                ...data,
                folder: folderId,
                status: "pending",
                author: getState().firebase.auth.uid,
                createdAt: new Date(),
            })
            .then(() => {
                dispatch({ type: "CREATE_CONFIRMATION_SUCCESS" });
            })
            .catch((err) => {
                dispatch({ type: "CREATE_CONFIRMATION_ERROR", err });
            });
    };
};

export const confirmGroupAcceptAction = (confId, onApprove = noop) => {
    return (dispatch) => {
        const approveFolderConfirmation = functions.httpsCallable(
            "approveFolderConfirmation"
        );

        approveFolderConfirmation({ confId })
            .then(() => {
                onApprove();
                dispatch({ type: "CONFIRM_GROUP_ACCESS_SUCCESS" });
            })
            .catch((err) => {
                dispatch({ type: "CONFIRM_GROUP_ACCESS_ERROR", err });
            });
    };
};

export const updateFolderConfirmationAction = (confId, data) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        firestore
            .collection("folderConfirmations")
            .doc(confId)
            .update({ ...data })
            .then(() => {
                dispatch({ type: "UPDATE_CONFIRMATION_SUCCESS" });
            })
            .catch((err) => {
                dispatch({ type: "UPDATE_CONFIRMATION_ERROR", err });
            });
    };
};

export const deleteFolderConfirmationAction = (confId) => {
    return (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        firestore
            .collection("folderConfirmations")
            .doc(confId)
            .delete()
            .then(() => {
                dispatch({ type: "DELETE_CONFIRMATION_SUCCESS" });
            })
            .catch((err) => {
                dispatch({ type: "DELETE_CONFIRMATION_ERROR", err });
            });
    };
};
