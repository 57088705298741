import React, { memo, useCallback, useMemo } from "react";
import noop from "lodash/noop";
import Caption from "./Caption";
import Row from "./Row";
import whitespace from "../tokens/Whitespace";
import Select from "./Select";

const MULTIPLIERS = [0.25, 0.5, 1, 2, 3];

const valueGetter = resolution => resolution.value;
const labelGetter = resolution => resolution.label;

const ProjectResolution = ({ width, height, multiplier, onChangeMultiplier = noop }) => {
	const resolutions = useMemo(() => {
		return MULTIPLIERS.map(m => {
			return {
				value: m,
				label: (
					<span>
						<b>x{m}</b> ({Math.round(width * m)}x{Math.round(height * m)})
					</span>
				),
			};
		});
	}, [width, height]);

	const handleChange = useCallback(event => onChangeMultiplier(event.target.value), [onChangeMultiplier]);

	return (
		<>
			<Caption color={({ theme }) => theme.primaryFontColor} marginTop={whitespace.big} fontWeight="600">
				Export settings
			</Caption>
			<Row paddingLeft={whitespace.medium} marginTop={whitespace.medium} flexDirection="column">
				<Row alignItems="center" marginBottom={whitespace.small}>
					<Caption color={({ theme }) => theme.secondaryFontColor} style={{ width: "170px" }}>
						Size
					</Caption>
					<Select
						height="32px"
						fontSize="13px"
						size={"s"}
						options={resolutions}
						value={multiplier}
						getValue={valueGetter}
						getLabel={labelGetter}
						onChange={handleChange}
					/>
				</Row>
			</Row>
		</>
	);
};

export default memo(ProjectResolution);
