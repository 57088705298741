import styled from "styled-components";
import { motion } from "framer-motion";
import whitespace from "../tokens/Whitespace";

export const FolderWrapper = styled(motion.div)`
	position: relative;
	height: calc(100%);
	justify-content: flex-start;
	display: flex;
	flex-direction: column;
`;

export const frameVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,

		transition: {
			duration: 0.1,
			staggerChildren: 0.025,
			ease: [0.02, 0.6, -0.01, 0.91],
		},
	},
};

export const itemVariants = {
	hidden: {
		opacity: 0,
		y: 20,
	},
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.25,
			ease: [0.02, 0.6, -0.01, 0.91],
		},
	},
};

export const DashboardWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: center;
`;

export const CardRemoveButton = styled.div`
	color: ${({ theme }) => theme.destructive};
	position: absolute;
	right: ${whitespace.large};
	top: 25%;
	display: none;
	width: 24px;
	height: 24px;

	&::after {
		position: absolute;
		width: 300%;
		transform: translateX(-50%);
		background: white;
		opacity: 0.2;
	}
`;
