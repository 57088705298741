import React, { useState, useMemo, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { populate } from "react-redux-firebase";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";

import createConfirmDeleteModal from "../layout/ConfirmDeleteModal";

import Row from "../layout/Row";
import whitespace from "../tokens/Whitespace";
import SearchInput from "../layout/SearchInput";
import Button from "../layout/Button";
import Modal from "../layout/Modal";
import CreateFolder from "../projects/CreateFolder";

import { createFolderAction, deleteFolderAction, updateFolderAction } from "../../store/actions/folderActions";

import Body from "../layout/Body";
import GridWrapper from "../layout/GridWrapper";
import List from "../layout/List";
import FolderCard from "../layout/FolderCard";
import ManageFolder from "../projects/ManageFolder";
import EmptyList from "../layout/EmptyList";

import { CardRemoveButton, FolderWrapper, DashboardWrapper, frameVariants } from "./styled";

const StyledLink = styled(Link)`
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		${CardRemoveButton} {
			display: block;
		}
	}
`;

const StyledRow = styled(Row)`
	min-width: 300px;
`;

const authorPopulates = [{ child: "author", root: "users" }];
const collaboratorPopulates = [
	{
		child: "roles",
		root: "users",
		populateByKey: true,
		childAlias: "collaborators",
	},
];
const populates = [...authorPopulates, ...collaboratorPopulates];

const FolderList = () => {
	const dispatch = useDispatch();
	const folders = useSelector(state => populate(state.firestore, `folders`, populates));
	const auth = useSelector(state => state.firebase.auth);
	const [search, setSearch] = useState(null);
	const areFoldersLoaded = useSelector(state => isLoaded(state.firestore.status.requested.folders));
	const [manageFolderModal, setManageFolderModal] = useState(false);
	const [selectedFolder, setSelectedFolder] = useState(null);
	const theme = useContext(ThemeContext);

	useFirestoreConnect(() => [
		{
			collection: "folders",
			storeAs: "folders",
			populates: populates,
			where: [[`roles.${auth.uid}`, "in", ["admin", "collaborator"]]],
		},
	]);

	const filteredFolders = useMemo(() => {
		const folderList = Object.entries(folders || {})
			.filter(([_, value]) => value?.author)
			.map(([key, value]) => {
				return {
					id: key,
					...value,
				};
			});

		return search
			? folderList.filter(f => f && f.title).filter(f => f.title.toLowerCase().includes(search.toLowerCase()))
			: folderList;
	}, [search, folders]);

	const handleDeleteFolder = ({ id, title }) => {
		createConfirmDeleteModal({
			headerText: "Delete folder?",
			descriptionNode: (
				<Body color={({ theme }) => theme.primaryFontColor}>
					All files in&nbsp;<b>{title}</b>&nbsp;will be removed
				</Body>
			),
			theme,
			onDelete: () => dispatch(deleteFolderAction(id)),
		});
	};

	const handleCreateFolder = data => {
		setManageFolderModal(false);
		dispatch(createFolderAction(data));

		console.log(folders);
	};

	const handleUpdateFolder = data => {
		dispatch(updateFolderAction(selectedFolder.id, data));
		setSelectedFolder(null);
	};

	if (!areFoldersLoaded) {
		return null;
	}

	if (!auth.uid) {
		return <Redirect to="/signin" />;
	}

	return (
		<StyledRow
			height="100%"
			display="flex"
			flexWrap="nowrap"
			justifyContent="center"
			backgroundColor={({ theme }) => theme.sidePanelBackgroundColor}
			padding={whitespace.large}
			paddingTop={whitespace.small}
		>
			<Modal isToggled={manageFolderModal} action={() => setManageFolderModal(value => !value)}>
				<CreateFolder onSubmit={handleCreateFolder} />
			</Modal>

			<Modal isToggled={selectedFolder} action={() => setSelectedFolder(null)}>
				{selectedFolder && (
					<ManageFolder
						folderId={selectedFolder.id}
						title={selectedFolder.title}
						emojiIndex={selectedFolder.emojiIndex}
						onSubmit={handleUpdateFolder}
						isAdmin={selectedFolder.roles[auth.uid] === "admin"}
					/>
				)}
			</Modal>

			<DashboardWrapper>
				<Row
					justifyContent="space-between"
					paddingTop={whitespace.xlarge}
					paddingBottom={whitespace.medium}
					width="100%"
					flexDirection="column"
				>
					<Button
						marginBottom={whitespace.xlarge}
						marginTop={theme.big}
						onClick={setManageFolderModal}
						secondaryButton={true}
						text="+ New workspace"
					/>

					<SearchInput placeholder="Search workspaces..." width="100%" value={search} onChange={setSearch} />
				</Row>

				<Row height="1px" width="100%" backgroundColor={({ theme }) => theme.border} style={{ opacity: 0.5 }}>
					{/* Divider here */}
				</Row>

				<GridWrapper>
					<Row paddingTop={whitespace.medium} paddingBottom={whitespace.xlarge} width="100%">
						{filteredFolders.length > 0 ? (
							<List variants={frameVariants} initial="hidden" animate="visible">
								{filteredFolders.map(folder => (
									<FolderWrapper key={folder.id}>
										<StyledLink to={`/folders/${folder.id}`}>
											<FolderCard folder={folder} onDelete={handleDeleteFolder} setSelectedFolder={setSelectedFolder} />
										</StyledLink>
									</FolderWrapper>
								))}
							</List>
						) : (
							<EmptyList />
						)}
					</Row>
				</GridWrapper>
			</DashboardWrapper>
		</StyledRow>
	);
};

export default FolderList;
