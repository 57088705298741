import * as React from "react";
import lottie from "lottie-web";
import { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { connect, useDispatch } from "react-redux";
import { firestoreConnect, populate } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { compose } from "redux";

import { updateAnimationElementsWithColor } from "../../Components/utils/lottie";
import { updateProjectAnimation, updateProject } from "../../store/actions/projectActions";

import ContentWraper from "../layout/ContentWraper";
import H1 from "../layout/H1";
import Caption from "../layout/Caption";
import Row from "../layout/Row";
import TwoColumn from "../layout/TwoColumn";
import AvatarGuest from "../layout/AvatarGuest";
import EditorSkeleton from "../layout/EditorSkeleton";
import Button from "../layout/Button";
import Play from "../Icons/Play";
import Pause from "../Icons/Pause";

import fontsize from "../tokens/FontSizes";
import whitespace from "../tokens/Whitespace";

import ProjectForm from "./ProjectForm";
import Modal from "../layout/Modal";

import Viewer from "./components/Viewer";
import Form from "./components/Form";
import Links from "./components/Links";

import { extractLottieMeta } from "./helpers";
import { getStrokeWidths } from "../../Components/utils/lottie";

const ControlsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: ${whitespace.medium};
	padding: ${whitespace.small};
	padding-right: ${whitespace.medium};
	background-color: ${({ theme }) => theme.surfaceFloating};
	border-radius: 16px;
	max-width: 480px;
	width: 100%;
	border-width: 1px;
	border-color: ${({ theme }) => theme.divider};
	border-style: solid;
	box-shadow: 0 1px 4px rgba(${({ theme }) => theme.cardShadowColor} 0.03),
		0 2px 10px rgba(${({ theme }) => theme.cardShadowColor} 0.03),
		0 4px 16px rgba(${({ theme }) => theme.cardShadowColor} 0.03),
		0 8px 20px rgba(${({ theme }) => theme.cardShadowColor} 0.05),
		0 16px 32px rgba(${({ theme }) => theme.cardShadowColor} 0.05),
		0 32px 64px rgba(${({ theme }) => theme.cardShadowColor} 0.05);
`;

const Slider = styled.input.attrs({ type: "range" })`
	flex-grow: 1;
	margin-right: ${whitespace.small};
	-webkit-appearance: none;
	width: 100%;
	background: transparent;
	margin-left: ${whitespace.small};

	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 8px;
		cursor: pointer;
		background: ${({ theme }) => theme.surfacevariant};
		border-radius: 4px;
	}

	&::-moz-range-track {
		width: 100%;
		height: 8px;
		cursor: pointer;
		background: ${({ theme }) => theme.surfacevariant};
		border-radius: 4px;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background: ${({ theme }) => theme.primaryButtonBackgroundColorDefault};
		cursor: pointer;
		margin-top: -4px;
	}

	&::-moz-range-thumb {
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background: ${({ theme }) => theme.primaryButtonBackgroundColorDefault};
		cursor: pointer;
	}
`;

const PlayPauseButton = styled.div`
	width: 40px;
	height: 40px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.primaryFontColor};
	pointer: cursor;
`;

const ViewerContainer = styled.div`
	flex-grow: 1;
	max-height: calc(100% - 180px);
`;

const StyledChangeButton = styled(Button)`
	height: fit-content;
	font-size: ${fontsize.caption};
	padding: ${whitespace.tiny} ${whitespace.medium};
`;

const getFormDataFromProject = project => {
	const { backgroundColor, colors, size, isLoop, strokeWidths } = project;
	return { backgroundColor, colors, size, isLoop, strokeWidths };
};

const initialFormData = {
	backgroundColor: "#FFFFFF",
	colors: [],
	isLoop: false,
	size: {
		width: 0,
		height: 0,
		multiplier: 1,
	},
};

const ProjectView = ({ project, auth, folderId, projectId }) => {
	const dispatch = useDispatch();
	const lottieRef = useRef(null);
	const lottieInstance = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentFrame, setCurrentFrame] = useState(0);
	const [totalFrames, setTotalFrames] = useState(0);
	const [animation, setAnimation] = useState(null);
	const [isEditModalOpen, setEditModalOpen] = useState(false);
	const [formData, setFormData] = useState(initialFormData);
	const [originalStrokeWidths, setOriginalStrokeWidths] = useState(null);
	const [originalAnimation, setOriginalAnimation] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isUpdatingJson, setIsUpdatingJson] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);

	useEffect(() => {
		if (lottieInstance.current) {
			if (isPlaying) {
				lottieInstance.current.play();
			} else {
				lottieInstance.current.pause();
			}
		}
	}, [isPlaying]);

	useEffect(() => {
		if (lottieInstance.current) {
			lottieInstance.current.goToAndStop(currentFrame, true);
			if (isPlaying) {
				lottieInstance.current.play();
			}
		}
	}, [currentFrame, isPlaying]);

	const togglePlayPause = () => {
		setIsPlaying(prevIsPlaying => {
			if (lottieInstance.current) {
				if (prevIsPlaying) {
					lottieInstance.current.pause();
				} else {
					lottieInstance.current.play();
				}
			}
			return !prevIsPlaying;
		});
	};
	const handleSliderChange = e => {
		const frame = parseInt(e.target.value, 10);
		setCurrentFrame(frame);
		if (lottieInstance.current) {
			lottieInstance.current.goToAndStop(frame, true);
		}
	};
	useEffect(() => {
		if (lottieRef.current && animation) {
			lottieInstance.current = lottie.loadAnimation({
				container: lottieRef.current,
				animationData: animation,
				autoplay: false,
				loop: true,
			});

			const totalFrames = lottieInstance.current.totalFrames;
			setTotalFrames(totalFrames);

			const updateFrame = () => {
				setCurrentFrame(Math.floor(lottieInstance.current.currentFrame));
			};

			lottieInstance.current.addEventListener("enterFrame", updateFrame);

			return () => {
				lottieInstance.current.removeEventListener("enterFrame", updateFrame);
				lottieInstance.current.destroy();
				lottieInstance.current = null;
			};
		}
	}, [animation]);

	const populateFormWithProjectData = useCallback(_project => {
		setFormData(current => ({ ...current, ...getFormDataFromProject(_project) }));
	}, []);

	const updateAnimationColor = color => {
		const newAnimation = updateAnimationElementsWithColor(animation, color);
		setAnimation(newAnimation);
	};

	const resetFormAndAnimation = () => {
		populateFormWithProjectData({ ...project, strokeWidths: originalStrokeWidths });
		setAnimation(cloneDeep(originalAnimation));
	};

	const handleProjectUpdate = async () => {
		setIsUpdatingJson(true);
		await dispatch(
			updateProjectAnimation(
				{ filePath: project.filePath, ...formData },
				{ animation, projectId },
				() => console.log("json updated"),
				() => console.log("on full done")
			)
		);
		const strokeWidths = getStrokeWidths(animation);
		setOriginalStrokeWidths(strokeWidths);
	};

	const handleStrokeChange = (inputIndex, newStrokeValue, columnInputIndex) => {
		const animationClone = cloneDeep(animation);
		const { strokeWidths } = formData;
		const { value, itemList } = strokeWidths;
		const parsedNewStrokeValue = parseInt(newStrokeValue, 10) || 0;
		const isKeyframedStroke = typeof columnInputIndex === "number";

		if (value.length !== itemList.length) {
			const previousStroke = value[inputIndex];

			itemList.forEach(({ strokeWidth, shapeDetails }, index) => {
				if (isEqual(strokeWidth, previousStroke)) {
					const matchingLayer = animationClone.layers[shapeDetails.layerIndex];
					const matchingShape = matchingLayer.shapes[shapeDetails.shapeIndex];
					const matchingStroke = matchingShape.it[shapeDetails.itIndex];

					if (isKeyframedStroke) {
						matchingStroke.w.k[columnInputIndex].s = [parsedNewStrokeValue];
						itemList[index].strokeWidth[columnInputIndex] = parsedNewStrokeValue;
					} else {
						matchingStroke.w.k = parsedNewStrokeValue;
						itemList[index].strokeWidth = parsedNewStrokeValue;
					}
				}
			});

			if (isKeyframedStroke) {
				value[inputIndex][columnInputIndex] = parsedNewStrokeValue;
			} else {
				value[inputIndex] = parsedNewStrokeValue;
			}

			setFormData(current => ({
				...current,
				strokeWidths: {
					value,
					itemList,
				},
			}));

			setAnimation(animationClone);
			return;
		}

		const { shapeDetails } = strokeWidths.itemList[inputIndex];

		const matchingLayer = animationClone.layers[shapeDetails.layerIndex];
		const matchingShape = matchingLayer.shapes[shapeDetails.shapeIndex];
		const matchingStroke = matchingShape.it[shapeDetails.itIndex];

		if (isKeyframedStroke) {
			matchingStroke.w.k[columnInputIndex].s = [parsedNewStrokeValue];
			itemList[inputIndex].strokeWidth[columnInputIndex] = parsedNewStrokeValue;
			value[inputIndex][columnInputIndex] = parsedNewStrokeValue;
		} else {
			matchingStroke.w.k = parsedNewStrokeValue;
			itemList[inputIndex].strokeWidth = parsedNewStrokeValue;
			value[inputIndex] = parsedNewStrokeValue;
		}

		setAnimation(animationClone);
		setFormData(current => ({
			...current,
			strokeWidths: {
				value,
				itemList,
			},
		}));
	};

	const handleNewProjectUpdate = async ({ extension, preview, ...data }) => {
		setEditModalOpen(false);
		if (data.json === project.json) {
			// just meta change
			await dispatch(
				updateProject(projectId, data, {
					isUpdateAssetsRequired: false,
				})
			);
			return;
		}
		extractLottieMeta(preview.file).then(async ({ animation, colors, size }) => {
			setOriginalAnimation(cloneDeep(animation));

			const isLoop = false;

			setFormData(current => ({ ...current, colors, size, isLoop }));

			await dispatch(
				updateProject(
					projectId,
					{ colors, size, isLoop, ...data },
					{
						isUpdateAssetsRequired: true,
					}
				)
			);
		});
	};

	// when updated the project, color ids are different
	// at this point we know that project's json was updated
	React.useEffect(() => {
		if (isLoaded && project && project.colors && formData.colors.length) {
			if (
				project.colors.every(
					(color, index) => color.value === formData.colors[index].value && color.id !== formData.colors[index].id
				)
			) {
				setFormData(getFormDataFromProject(cloneDeep(project)));
				setIsUpdatingJson(false);
			}
		}
	}, [formData.colors, project, isLoaded]);

	React.useEffect(() => {
		if (formData?.strokeWidths || !isLoaded || !animation) {
			return;
		}

		const strokeWidths = getStrokeWidths(animation);
		setFormData(current => ({ ...current, strokeWidths }));
	}, [animation, isLoaded, formData]);

	React.useEffect(() => {
		if (isLoaded && project && project.isProcessing && isUpdatingJson) {
			setIsUpdatingJson(false);
		}
	}, [isLoaded, isUpdatingJson, project]);

	React.useEffect(() => {
		if (!project || !isLoaded || isUpdatingJson) {
			return;
		}

		const projectFormData = { ...getFormDataFromProject(project), strokeWidths: originalStrokeWidths };
		setHasChanges(!isEqual(formData, projectFormData));
	}, [formData, project, isLoaded, originalStrokeWidths, isUpdatingJson]);

	React.useEffect(() => {
		if (project && !isLoaded) {
			setIsLoaded(true);
			populateFormWithProjectData(cloneDeep(project));

			if (project.json) {
				fetch(project.json).then(response =>
					response.json().then(anim => {
						setAnimation(anim);
						const strokeWidths = getStrokeWidths(anim);
						setOriginalStrokeWidths(strokeWidths);
						setOriginalAnimation(cloneDeep(anim));
					})
				);
			}
		}
	}, [populateFormWithProjectData, project, formData, isLoaded]);

	if (!auth.uid) {
		return <Redirect to="/signin" />;
	}

	if (!project) {
		return <EditorSkeleton />;
	}

	return (
		<>
			<Modal isToggled={isEditModalOpen} action={() => setEditModalOpen(false)} paddingRight={whitespace.big}>
				<ProjectForm
					onSubmit={handleNewProjectUpdate}
					folderId={folderId}
					initialData={{
						title: project?.title ?? "",
						tags: project?.tags ?? "",
						filePath: project?.filePath ?? "",
						json: project?.json ?? "",
					}}
					isEdit
				/>
			</Modal>

			<ContentWraper paddingLeft={whitespace.large}>
				<TwoColumn height="100%" justifyContent="space-between" paddingLeft={whitespace.large} flexDirection="row">
					<Row flexGrow="4" padding={whitespace.large} flexDirection={"column"}>
						<Row flexDirection={"row"} justifyContent={"space-between"}>
							<Row flexDirection={"column"}>
								<Row marginBottom={whitespace.default}>
									<H1 fontWeight="600" textAlign="left">
										{project.title}
									</H1>
								</Row>
								<Row alignItems="center" marginBottom={whitespace.large}>
									{project.author && (
										<>
											<AvatarGuest
												marginLeft={whitespace.default}
												marginRight={whitespace.default}
												initials={project.author.initials}
												backgroundColor={project.author.iconColor || "#C9C2FF"}
											/>
											<Caption color={({ theme }) => theme.secondaryFontColor} fontWeight="400" textAlign="left">
												Added by {project.author.firstName} {project.author.lastName}
											</Caption>
										</>
									)}
								</Row>
							</Row>

							<StyledChangeButton text={"File details"} onClick={() => setEditModalOpen(true)} />
						</Row>

						<ViewerContainer>
							<Viewer animation={animation} backgroundColor={formData.backgroundColor} lottieRef={lottieRef} />
						</ViewerContainer>
						<Row flexDirection="row" justifyContent="center" alignItems="center" width="100%">
							<ControlsContainer>
								<PlayPauseButton onClick={togglePlayPause}>{isPlaying ? <Pause /> : <Play />}</PlayPauseButton>
								<Slider type="range" min={0} max={totalFrames} value={currentFrame} onChange={handleSliderChange} />
							</ControlsContainer>
						</Row>
					</Row>

					<Row
						minWidth="300px"
						maxWidth="320px"
						height="100%"
						padding={whitespace.medium}
						backgroundColor={({ theme }) => theme.sidebarBackgroundColor}
						flexDirection="column"
						justifyContent="space-between"
						flexGrow="1"
						style={{ overflowY: "scroll" }}
					>
						<Row flexDirection="column" padding={whitespace.medium} justifyContent="start" flexGrow="1">
							<Caption color={({ theme }) => theme.primaryFontColor} fontWeight="600">
								Colors
							</Caption>
							<Row marginTop={whitespace.default} flexDirection="column">
								<Form
									formData={formData}
									onChange={setFormData}
									onColorChange={updateAnimationColor}
									onStrokeChange={handleStrokeChange}
								/>
							</Row>
						</Row>
						<Links
							project={project}
							onConfirm={handleProjectUpdate}
							onCancel={resetFormAndAnimation}
							hasChanges={hasChanges}
							isUpdatingJson={isUpdatingJson}
						/>
					</Row>
				</TwoColumn>
			</ContentWraper>
		</>
	);
};

const projectPopulates = [{ child: "author", root: "users" }];

const mapStateToProps = (state, ownProps) => {
	return {
		project: populate(state.firestore, `project_${ownProps.match.params.id}`, projectPopulates),
		auth: state.firebase.auth,
		folderId: ownProps.match.params.folderId,
		projectId: ownProps.match.params.id,
	};
};

export default compose(
	firestoreConnect(props => [
		{
			storeAs: `project_${props.match.params.id}`,
			collection: "newProjects",
			doc: props.match.params.id,
			populates: projectPopulates,
		},
	]),
	connect(mapStateToProps)
)(ProjectView);
