import React from "react";
import styled from "styled-components";

const SidebarIcon = styled.div`
    margin: ${(props) => props.margin};
    height: 60px;
    width: 60px;
    color: ${(props) => props.color};

    justify-content: center;
    display: flex;
    align-items: center;
    position: ${(props) => props.position};
    cursor: pointer;
    border-radius: 12px;
    transition: 0.15s cubic-bezier(0.2, 0.8, 0.2, 1);
    float: ${(props) => props.float};
    opacity: ${(props) => props.opacity};

    &:hover {
        background: ${({ theme }) => theme.sidebarIconBackgroundColorHover};
    }

    &:focus {
    }
`;

const SidebarIconButton = (props) => {
    return (
        <SidebarIcon
            className={props.className}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            primary={props.design}
            color={props.color}
            margin={props.margin}
        >
            {props.children}
        </SidebarIcon>
    );
};

export default SidebarIconButton;
