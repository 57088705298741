import * as React from "react";
import fontsize from "../tokens/FontSizes";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";

const H2 = styled.div`
    width: ${(props) => props.width};
    display: flex;
    color: ${({ theme }) => theme.primaryFontColor};
    text-align: ${(props) => props.textAlign};
    font-weight: ${(props) => props.fontWeight};
    font-size: ${fontsize.h2};
    padding-top: ${whitespace.small};
`;

export function Headline2({ textColor, fontWeight, textAlign, fontSize, width }) {
    return <H2></H2>;
}

export default H2;
