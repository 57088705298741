import React from "react";
import { confirmAlert } from "react-confirm-alert";
import noop from "lodash/noop";
import styled, { ThemeProvider } from "styled-components";

import whitespace from "../tokens/Whitespace";

import Modal from "./Modal";
import H2 from "./H2";
import Button from "./Button";

const Layout = styled.div``;

const TitleWrapper = styled.div`
	margin-bottom: ${whitespace.big};
`;

const DescriptionWrapper = TitleWrapper;

const ButtonsWrapper = styled.div`
	display: flex;
`;

const ButtonWrapper = styled.div`
	display: flex;
	width: 50%;

	button {
		width: 100%;
	}
`;

const CancelButtonWrapper = styled(ButtonWrapper)`
	margin-right: ${whitespace.medium};
`;

const createConfirmDeleteModal = ({
	headerText,
	descriptionNode,
	confirmText = "Yes, delete",
	cancelText = "Cancel",
	onDelete = noop,
	theme,
}) => {
	return confirmAlert({
		customUI: ({ onClose }) => {
			const handleDelete = () => {
				onClose();
				onDelete();
			};

			return (
				<ThemeProvider theme={theme}>
					<Modal padding={whitespace.big} isToggled={true} action={onClose}>
						<Layout>
							<TitleWrapper>
								<H2 fontWeight="600">{headerText}</H2>
							</TitleWrapper>

							<DescriptionWrapper>{descriptionNode}</DescriptionWrapper>

							<ButtonsWrapper>
								<CancelButtonWrapper>
									<Button onClick={onClose} text={cancelText} basic={true} />
								</CancelButtonWrapper>

								<ButtonWrapper>
									<Button destructive={true} onClick={handleDelete} text={confirmText} />
								</ButtonWrapper>
							</ButtonsWrapper>
						</Layout>
					</Modal>
				</ThemeProvider>
			);
		},
	});
};

export default createConfirmDeleteModal;
