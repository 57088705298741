import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signIn } from "../../store/actions/authActions";
import { Link } from "react-router-dom";

import styled from "styled-components";
import Row from "../layout/Row";
import fontsize from "../tokens/FontSizes";
import whitespace from "../tokens/Whitespace";
import H1 from "../layout/H1";
import Button from "../layout/Button";
import { AnimatePresence, motion } from "framer-motion";
import LinkButton from "../layout/LinkButton";

const FormContainer = styled.div`
	background-color: ${({ theme }) => theme.cardBackgroundColorDefault};
	border-radius: 16px;
	width: 30%;
	padding: ${whitespace.large};
	justify-content: center;
	display: flex;

	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);

	@media (max-width: 800px) {
		width: 50%;
		padding: ${whitespace.medium};
	}

	@media (max-width: 420px) {
		width: 80%;
		padding: ${whitespace.medium};
	}
`;
const Input = styled.input`
	display: flex;
	flex-grow: 1;
	height: 40px;
	padding: ${whitespace.normal};
	color: ${({ theme }) => theme.primaryFontColor};
	border: 1px solid ${({ theme }) => theme.inputBorderColorDefault};
	background: ${({ theme }) => theme.inputBackgroundColorDefault};
	border-radius: 8px;
	font-size: 15px;

	:focus {
		border: 1px solid ${({ theme }) => theme.inputBorderColorActive};
	}

	&::-webkit-input-placeholder {
		color: ${props => props.placeholderColor};
	}
`;

const Label = styled.label`
	display: flex;
	font-size: ${fontsize.body};
	text-align: left;
	color: ${({ theme }) => theme.primaryFontColor};
	margin-top: ${props => props.marginTop};
	margin-bottom: ${whitespace.default};
`;

const ValidationError = styled.div`
	width: "fit-content";
	margin-top: 8px;
	color: ${({ theme }) => theme.destructive};

	font-size: 16px;
`;

class SignIn extends Component {
	state = {
		email: "",
		password: "",
	};

	handleChange = e => {
		this.setState({
			[e.target.id]: e.target.value,
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		this.props.signIn(this.state);
	};

	render() {
		const { authError, auth } = this.props;
		if (auth.uid) return <Redirect to="/NewsDashboard" />;

		return (
			<Row flexDirection="column" flexGrow="1" justifyContent="center" alignItems="center">
				<FormContainer>
					<form
						style={{
							display: "flex",
							flexDirection: "column",
							flexGrow: "1",
						}}
						onSubmit={this.handleSubmit}
					>
						<H1 fontWeight="600" textAlign="center">
							Log in
						</H1>

						<Row flexDirection="column" marginTop={whitespace.big} marginBottom={whitespace.big}>
							<Label htmlFor="email">Email</Label>
							<Input placeholder="type your email..." type="email" id="email" onChange={this.handleChange} />
						</Row>

						<Row flexDirection="column" marginBottom={whitespace.big}>
							<Row flexDirection="Row" justifyContent="space-between">
								<Label htmlFor="password">Password</Label>
								<Label>
									<Link to={"/forgot/"}>
										<LinkButton marginLeft={whitespace.small} text="Forgot password?" padding="none"></LinkButton>
									</Link>
								</Label>
							</Row>
							<Input placeholder="type your password..." type="password" id="password" onChange={this.handleChange} />
						</Row>

						<AnimatePresence>
							{authError && (
								<motion.div
									transition={{
										type: "tween",
										ease: [0.12, 0.75, 0.02, 1.025],
										duration: 0.15,
									}}
									initial={{ opacity: 0, height: 0, y: 0 }}
									animate={{ opacity: 1, height: 30, y: -20 }}
									exit={{ opacity: 0, height: 0, y: 0 }}
								>
									<ValidationError>
										<p>{authError}</p>
									</ValidationError>
								</motion.div>
							)}{" "}
							: null
						</AnimatePresence>

						{/* without animation method */}
						{/* <div style={{color: "red"}}>
{ authError ? <p>{authError}</p> : null }
        </div>  */}

						<Button design="primary" text="Log in">
							Log in
						</Button>
					</form>
				</FormContainer>
				<Label marginTop={whitespace.large}>
					Don't have an account?
					<Link to={"/signup/"}>
						<LinkButton marginLeft={whitespace.small} text="Create one!" padding="none"></LinkButton>
					</Link>
				</Label>
			</Row>
		);
	}
}

const mapStateToProps = state => {
	return {
		authError: state.auth.authError,
		auth: state.firebase.auth,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		signIn: creds => dispatch(signIn(creds)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
