import JsonProjectHandler from "./json";
import { FILES_API_ENDPOINT } from "../../../config/fbConfig";

const FILE_EXTENSION_REGEXP = /\.([^./?#]+)($|\?|#)/;

export const getFileExtension = url => {
	try {
		return url.match(FILE_EXTENSION_REGEXP)[1] || "unknown";
	} catch (err) {
		console.error("error getting url");
		return "unknown";
	}
};

export const getFileUrl = fileName => `${FILES_API_ENDPOINT}/${fileName}`;

export const projectHandlerFactory = project => {
	if (!project || !project.json) {
		return null;
	}

	// TODO rename json => url
	const extension = getFileExtension(project.json);

	switch (extension.toLowerCase()) {
		case "json":
			return JsonProjectHandler;

		default:
			return null;
	}
};
