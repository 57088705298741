import * as React from "react";
import { motion } from "framer-motion";

const Duo = ({ styles = {}, ...props }) => (
    <motion.div
        whileHover={{
            scale: 1.15,
            transition: { ease: [0.2, 0.8, 0.2, 1], duration: 0.3 },
        }}
        whileTap={{ scale: 0.75 }}
    >
        <svg height="32px" viewBox="0 0 32 32" width="32px" {...props}>
            <g fill="currentColor" fillRule="nonzero">
                <path
                    fill="currentColor"
                    d="M16,4c6.6,0,12,5.4,12,12c0,6.5-5.2,11.8-11.7,12L16,28C9.4,28,4,22.6,4,16C4,9.5,9.2,4.2,15.7,4L16,4z M17.3,7
	H17c-0.5,0.1-0.9,0.4-1,0.9L16,8v16l0,0.1c0.1,0.5,0.4,0.8,0.9,0.9l0.1,0l0.3,0c4.8-0.1,7.7-4.1,7.7-9c0-4.6-3-8.5-7.5-9L17.3,7z"
                />
            </g>
        </svg>
    </motion.div>
);

export default Duo;
