import * as React from "react";
import styled from "styled-components";

const IconWraper = styled.div`
    right: ${(props) => props.right};
    margin: ${(props) => props.margin};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    height: 50px;
    color: ${({ theme }) => theme.primaryFontColor};
    width: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
    position: ${(props) => props.position};
    cursor: pointer;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 12px;
    transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
    float: ${(props) => props.float};
    opacity: ${(props) => props.opacity};
`;

export function Icon32(
    right,
    position,
    backgroundColor,
    float,
    margin,
    marginLeft,
    marginRight
) {
    return <IconWraper></IconWraper>;
}

export default IconWraper;
