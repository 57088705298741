import React, { useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import Row from "../../layout/Row";
import fontsize from "../../tokens/FontSizes";
import whitespace from "../../tokens/Whitespace";
import H2 from "../../layout/H2";
import Body from "../../layout/Body";
import Button from "../../layout/Button";
import Input from "../../layout/Input";
import Select from "../../layout/Select";
import ConfirmStatus from "./ConfirmStatus";
import Close from "../../Icons/Close";
import EmojiWrapper from "../../layout/EmojiWrapper";

import {
	createFolderConfirmationAction,
	deleteFolderConfirmationAction,
	updateFolderConfirmationAction,
} from "../../../store/actions/folderActions";

import { EMOJIS } from "../../utils/emojis";

const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const FormContainer = styled.div`
	width: 480px;
	justify-content: center;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin: auto;
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	padding: ${whitespace.big};
	@media (max-width: 800px) {
		width: 380px;
	}
`;

const Label = styled.label`
	display: flex;
	font-size: ${fontsize.body};
	text-align: left;
	color: ${({ theme }) => theme.primaryFontColor};
	margin-bottom: ${whitespace.default};
`;

const StyledRemoveIconButton = styled.div`
	width: 24px;
	height: 24px;
	margin-right: ${whitespace.default};
	margin-left: ${whitespace.default};
	color: ${({ theme }) => theme.destructive};
	cursor: pointer;
`;

const EmojiListWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
`;

const ROLES = ["admin", "collaborator"];

const ManageFolder = ({
	title,
	emojiIndex = 0,
	folderConfirmations,
	createFolderConfirmation,
	folderId,
	auth,
	isAdmin,
	onSubmit,
	updateFolderConfirmation,
	deleteFolderConfirmation,
}) => {
	const [localTitle, setLocalTitle] = useState(title);
	const [localEmojiIndex, setLocalEmojiIndex] = useState(emojiIndex);
	const [email, setEmail] = useState("");
	const [role, setRole] = useState(ROLES[0]);

	const handleClickAddEmail = useCallback(() => {
		createFolderConfirmation(folderId, { email, role });
		setEmail("");
	}, [email, role, folderId, createFolderConfirmation]);

	const confirmationList = useMemo(
		() =>
			Object.entries(folderConfirmations || {}).map(([key, value]) => ({
				id: key,
				...value,
			})),
		[folderConfirmations]
	);

	const confirmations = useMemo(() => {
		return (
			<>
				{confirmationList.map(confirmation => {
					return (
						<Row
							key={confirmation.id}
							marginTop={whitespace.big}
							flexDirection="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Row alignItems="center">
								<Row marginRight={whitespace.tiny}>
									<ConfirmStatus status={confirmation.status} />
								</Row>
								<Body color={({ theme }) => theme.primaryFontColor} marginTop={whitespace.tiny}>
									{confirmation.email}
								</Body>
							</Row>

							<Row alignItems="flex-end" justifyContent="center" flexDirection="row">
								<Row marginLeft={whitespace.medium} width="140px">
									<Select
										height="32px"
										options={ROLES}
										value={confirmation.role}
										onChange={event => updateFolderConfirmation(confirmation.id, { role: event.target.value })}
										isDisabled={confirmation.status !== "confirmed"}
									/>
								</Row>

								<Row justifyContent="center" alignItems="center" paddingBottom="4px">
									<StyledRemoveIconButton onClick={() => deleteFolderConfirmation(confirmation.id)}>
										<Close width={"24px"} height={"24px"} />
									</StyledRemoveIconButton>
								</Row>
							</Row>
						</Row>
					);
				})}
			</>
		);
	}, [confirmationList, updateFolderConfirmation, deleteFolderConfirmation]);

	const isAddDisabled = useMemo(() => {
		const isOwn = email === auth.email;

		return !EMAIL_REGEXP.test(email) || isOwn || confirmationList.map(conf => conf.email).includes(email);
	}, [email, confirmationList, auth.email]);

	const handleSelectRole = useCallback(event => {
		setRole(event.target.value);
	}, []);

	const handleSubmit = useCallback(() => {
		onSubmit({ title: localTitle, emojiIndex: localEmojiIndex });
	}, [onSubmit, localTitle, localEmojiIndex]);

	const emojiListNode = useMemo(
		() =>
			EMOJIS.map((emoji, index) => {
				return (
					<EmojiWrapper
						key={`Emoji-${index}`}
						isSelected={localEmojiIndex === index}
						onClick={() => setLocalEmojiIndex(index)}
					>
						{emoji}
					</EmojiWrapper>
				);
			}),
		[localEmojiIndex]
	);

	return (
		<FormContainer>
			<H2 fontWeight="600" textAlign="center">
				Manage workspace
			</H2>

			<Row flexDirection="column" marginTop={whitespace.big}>
				<Label>Icon</Label>

				<EmojiListWrapper>{emojiListNode}</EmojiListWrapper>
			</Row>

			<Row flexDirection="column" marginTop={whitespace.big}>
				<Label htmlFor="foldername">Title</Label>
				<Input value={localTitle} type="text" id="foldername" onChange={event => setLocalTitle(event.target.value)} />
			</Row>

			{isAdmin && (
				<>
					<Row flexDirection="column" marginTop={whitespace.big} marginBottom={whitespace.medium}>
						<Label htmlFor="title">Share with</Label>
						<Row flexDirection="row" flexGrow="1">
							<Row marginRight={whitespace.default} width={"60%"}>
								<Input
									value={email}
									placeholder="member email address"
									type="text"
									id="email"
									onChange={event => setEmail(event.target.value)}
								/>
							</Row>

							<Row marginRight={whitespace.default} width={"40%"}>
								<Select height="40px" value={role} options={ROLES} onChange={handleSelectRole} />
							</Row>

							<Button width="30%" text="Invite" disabled={isAddDisabled} onClick={handleClickAddEmail} />
						</Row>
					</Row>

					{confirmations}
				</>
			)}

			<Button marginTop={whitespace.large} design="primary" text="Done" onClick={handleSubmit} />
		</FormContainer>
	);
};

const dispatchToProps = {
	createFolderConfirmation: (folderId, data) => createFolderConfirmationAction(folderId, data),
	updateFolderConfirmation: (confId, data) => updateFolderConfirmationAction(confId, data),
	deleteFolderConfirmation: confId => deleteFolderConfirmationAction(confId),
};

const mapStateToProps = state => {
	return {
		auth: state.firebase.auth,
		search: state.project.search,
		folderConfirmations: state.firestore.data.folderConfirmations,
	};
};

export default compose(
	connect(mapStateToProps, dispatchToProps),
	firestoreConnect(props => {
		const folderConfirmationsQuery = {
			collection: "folderConfirmations",
			where: [["folder", "==", props.folderId]],
		};
		return [folderConfirmationsQuery];
	})
)(ManageFolder);
