export const EDITOR_SET_ANIMATION = "@@editor/SET_ANIMATION";
export const EDITOR_UPDATE_ANIMATION = "@@editor/UPDATE_ANIMATION";
export const EDITOR_SET_COLORS = "@@editor/SET_COLORS";
export const EDITOR_UPDATE_COLOR = "@@editor/UPDATE_COLOR";
export const EDITOR_SAVE_COLORS = "@@editor/SAVE_COLORS";
export const EDITOR_SET_PROJECT_ID = "@@editor/SET_PROJECT_ID";
export const EDITOR_CLEAR = "@@editor/CLEAR";
export const EDITOR_SET_IS_DISABLED = "@@editor/SET_IS_DISABLED";
export const EDITOR_UPDATE_BACKGROUND = "@@editor/UPDATE_BACKGROUND";
export const EDITOR_UPDATE_MULTIPLIER = "@@editor/UPDATE_MULTIPLIER";
