import React, { useState, memo, useMemo } from "react";
import styled from "styled-components";
import Row from "../layout/Row";
import fontsize from "../tokens/FontSizes";
import whitespace from "../tokens/Whitespace";
import H2 from "../layout/H2";
import Caption from "../layout/Caption";
import Button from "../layout/Button";
import Input from "../layout/Input";
import EmojiWrapper from "../layout/EmojiWrapper";
import { EMOJIS } from "../utils/emojis";

const FormContainer = styled.div`
	width: 480px;
	justify-content: center;
	display: flex;
	margin: auto;
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	padding: ${whitespace.big};
	@media (max-width: 800px) {
		width: 380px;
	}
`;

export const Label = styled.label`
	display: flex;
	font-size: ${fontsize.body};
	text-align: left;
	color: ${({ theme }) => theme.primaryFontColor};
	margin-bottom: ${whitespace.default};
`;

const EmojiListWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
`;

const AddFolder = ({ onSubmit }) => {
	const [title, setTitle] = useState("");
	const [emojiIndex, setEmojiIndex] = useState(0);

	const handleSave = event => {
		event.preventDefault();
		onSubmit({ title, emojiIndex });
	};

	const emojiListNode = useMemo(
		() =>
			EMOJIS.map((emoji, index) => {
				return (
					<EmojiWrapper key={`Emoji-${index}`} isSelected={emojiIndex === index} onClick={() => setEmojiIndex(index)}>
						{emoji}
					</EmojiWrapper>
				);
			}),
		[emojiIndex]
	);

	return (
		<FormContainer>
			<form
				style={{
					display: "flex",
					flexDirection: "column",
					flexGrow: "1",
				}}
			>
				<H2 fontWeight="600" textAlign="center">
					Create new workspace
				</H2>

				<Row flexDirection="column" marginTop={whitespace.medium} marginBottom={whitespace.big}>
					<Label>Pick icon</Label>

					<EmojiListWrapper>{emojiListNode}</EmojiListWrapper>
				</Row>

				<Row flexDirection="column" marginTop={whitespace.medium} marginBottom={whitespace.big}>
					<Label htmlFor="foldername">Add title</Label>
					<Input
						placeholder="Come up with some nifty title"
						value={title}
						type="text"
						id="foldername"
						onChange={event => setTitle(event.target.value)}
						maxLength={16}
					/>
				</Row>

				<Row marginTop={whitespace.tiny} marginBottom={whitespace.big}>
					<Caption color={({ theme }) => theme.secondaryFontColor}>
						Sharing with others becomes available as soon as the folder is created.
					</Caption>
				</Row>

				<Button design="primary" text="Create" onClick={handleSave} />
			</form>
		</FormContainer>
	);
};

export default memo(AddFolder);
