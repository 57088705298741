import React, { Component } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import ContentWraper from "./ContentWraper";
import Row from "./Row";
import TwoColumn from "./TwoColumn";
import whitespace from "../tokens/Whitespace";

const ViewerContainer = styled.div`
    width: 100%;
    height: 100%;
    flex-grow: 1;
    max-height: calc(100% - 120px);
    background-color: ${({ theme }) => theme.cardBackgroundColorDefault};
    color: ${({ theme }) => theme.cardBackgroundColorDefault};
    border-radius: 4px;
`;

class EditorSkeleton extends Component {
    render() {
        return (
            <ContentWraper paddingLeft={whitespace.large}>
                <TwoColumn
                    height="100%"
                    justifyContent="space-between"
                    paddingLeft={whitespace.large}
                    flexDirection="row"
                >
                    <Row
                        flexGrow="4"
                        padding={whitespace.large}
                        flexDirection={"column"}
                    >
                        <Row marginBottom={whitespace.default}>
                            <motion.div
                                animate={{ opacity: 0.5 }}
                                transition={{
                                    repeat: Infinity,
                                    repeatType: "reverse",
                                    duration: 0.3,
                                }}
                            >
                                <Row
                                    borderRadius="8px"
                                    backgroundColor={({ theme }) =>
                                        theme.cardBackgroundColorDefault
                                    }
                                    width="160px"
                                    height="34px"
                                />
                            </motion.div>
                        </Row>
                        <Row
                            alignItems="center"
                            marginBottom={whitespace.large}
                        >
                            <>
                                <motion.div
                                    animate={{ opacity: 0.5 }}
                                    transition={{
                                        repeat: Infinity,
                                        repeatType: "reverse",
                                        duration: 0.3,
                                    }}
                                >
                                    <Row
                                        marginTop="8px"
                                        marginLeft="4px"
                                        borderRadius="30px"
                                        backgroundColor={({ theme }) =>
                                            theme.cardBackgroundColorDefault
                                        }
                                        width="28px"
                                        height="28px"
                                    />
                                </motion.div>

                                <motion.div
                                    animate={{ opacity: 0.5 }}
                                    transition={{
                                        repeat: Infinity,
                                        repeatType: "reverse",
                                        duration: 0.3,
                                    }}
                                >
                                    <Row
                                        marginTop="8px"
                                        marginLeft="8px"
                                        borderRadius="8px"
                                        backgroundColor={({ theme }) =>
                                            theme.cardBackgroundColorDefault
                                        }
                                        width="128px"
                                        height="18px"
                                    />
                                </motion.div>
                            </>
                        </Row>

                        <ViewerContainer>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s
                        </ViewerContainer>
                    </Row>
                    <Row
                        width="395px"
                        height={`calc(100% - 2 * ${whitespace.medium})`}
                        padding={whitespace.medium}
                        backgroundColor={({ theme }) =>
                            theme.sidebarBackgroundColor
                        }
                        flexDirection="column"
                        justifyContent="space-between"
                    >
                        <Row
                            flexDirection="column"
                            padding={whitespace.medium}
                            justifyContent="start"
                            flexGrow="1"
                        >
                            <Row
                                marginTop={whitespace.default}
                                flexDirection="column"
                            ></Row>
                        </Row>
                    </Row>
                </TwoColumn>
            </ContentWraper>
        );
    }
}

export default EditorSkeleton;
