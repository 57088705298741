import React, { useRef, useState, memo } from "react";
import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import readableColor from "polished/lib/color/readableColor";
import LottieViewer from "./LottieViewer";
import JSON from "../Icons/JSON";

const StyledContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	flex-grow: 1;
	overflow: hidden;
	background-color: ${props => props.backgroundColor};
`;

const StyledJSONIcon = styled(JSON)`
	position: absolute;
	z-index: 3000;
	top: 20px;
	left: 25px;
	width: 30px;
	height: 30px;
	opacity: 0.55;
	pointer-events: none;
	color: ${props => props.color};
`;

const ControlsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
`;

const Slider = styled.input`
	flex-grow: 1;
	margin-right: 10px;
`;

const PlayPauseButton = styled.button`
	width: 40px;
	height: 40px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LottieViewerContainer = ({ animationData, backgroundColor = "#FFFFFF" }) => {
	const [isVisible, setIsVisible] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentFrame, setCurrentFrame] = useState(0);
	const [totalFrames, setTotalFrames] = useState(0);
	const imageContainerRef = useRef(null);
	const lottieRef = useRef(null);
	const { path } = useRouteMatch();
	const isProjectPath = path === "/folders/:folderId/:id";

	const togglePlayPause = () => {
		if (isPlaying) {
			lottieRef.current && lottieRef.current.pause();
		} else {
			lottieRef.current && lottieRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	const handleSliderChange = e => {
		const frame = parseInt(e.target.value, 10);
		lottieRef.current && lottieRef.current.goToAndStop(frame, true);
		setCurrentFrame(frame);
	};

	React.useEffect(() => {
		if (lottieRef.current && animationData) {
			setTotalFrames(lottieRef.current.getTotalFrames());

			const interval = setInterval(() => {
				if (lottieRef.current) {
					setCurrentFrame(lottieRef.current.getCurrentFrame());
				}
			}, 1000 / 60); // Update at 60fps

			return () => clearInterval(interval);
		}
	}, [animationData]);

	return (
		<>
			{isVisible && <StyledJSONIcon color={readableColor(backgroundColor)} />}
			<StyledContainer
				onMouseEnter={() => !isProjectPath && setIsVisible(true)}
				onMouseLeave={() => !isProjectPath && setIsVisible(false)}
				ref={imageContainerRef}
				backgroundColor={backgroundColor}
			>
				<LottieViewer
					ref={lottieRef}
					containerRef={imageContainerRef}
					data={animationData}
					isVisible={isVisible}
					isProjectPath={isProjectPath}
				/>
			</StyledContainer>
		</>
	);
};

export default memo(LottieViewerContainer);
