import React, { memo } from "react";
import styled from "styled-components";
import Body from "./Body";
import { motion } from "framer-motion";
import whitespace from "../tokens/Whitespace";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: fit-content;
	padding: 0 ${whitespace.medium};
	margin-top: ${whitespace.big};
`;

const StyledCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 700px;
	height: ${({ center }) => (center ? "100vh" : "400px")};
`;

const EmptyList = ({ center }) => (
	<Wrapper>
		<motion.div
			transition={{ duration: 0.5, ease: [0.25, 1, 0.25, 1] }}
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 50 }}
		>
			<StyledCard center={center}>
				<Body color={({ theme }) => theme.primaryFontColor}>Nothing in here 🏜️</Body>
			</StyledCard>
		</motion.div>
	</Wrapper>
);

export default memo(EmptyList);
