import React, { memo } from "react";
import { Link } from "react-router-dom";
import noop from "lodash/noop";

import ItemCard from "./ItemCard";
import whitespace from "../tokens/Whitespace";
import Container from "./Container";
import Row from "./Row";
import Grid from "./Grid";
import GridWrapper from "./GridWrapper";
import { motion } from "framer-motion";
import styled from "styled-components";

const CardWraper = styled(motion.div)`
	position: relative;
	min-height: ${({ minHeight = 0 }) => minHeight};
	height: calc(100%);
	flex-grow: 1;
`;

const frameVariants = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,

		transition: {
			duration: 0.1,
			staggerChildren: 0.025,
			ease: [0.02, 0.6, -0.01, 0.91],
		},
	},
};

const itemVariants = {
	hidden: {
		opacity: 0,
		y: 20,
	},
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.25,
			ease: [0.02, 0.6, -0.01, 0.91],
		},
	},
};

const CardGrid = ({ projects, baseUrl, onDuplicateProject = noop }) => {
	const [activeDropDownId, setActiveDropDownId] = React.useState(null);
	return (
		<GridWrapper>
			<Container>
				<Row paddingTop={whitespace.large} paddingBottom={"100px"}>
					<Grid variants={frameVariants} initial="hidden" animate="visible">
						{projects
							? projects
									.filter(project => project && project.json)
									.map(project => (
										<Link to={baseUrl + project.id} key={project.id}>
											<CardWraper variants={itemVariants}>
												<ItemCard
													project={project}
													onDuplicate={onDuplicateProject}
													setActiveDropDownId={setActiveDropDownId}
													activeDropDownId={activeDropDownId}
												/>
											</CardWraper>
										</Link>
									))
							: null}
					</Grid>
				</Row>
			</Container>
		</GridWrapper>
	);
};

export default memo(CardGrid);
