import React, { memo } from "react";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";

const StyledContainer = styled.div`
	width: calc(100% - 2 * ${whitespace.xlarge});
	max-width: 1500px;
	margin: 0 ${whitespace.large};
`;

const Container = ({ children, padding, className }) => {
	return (
		<StyledContainer className={className} padding={padding}>
			{children}
		</StyledContainer>
	);
};

export default memo(Container);
