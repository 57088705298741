import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import noop from "lodash/noop";
import { Link } from "react-router-dom";

import { confirmGroupAcceptAction } from "../../store/actions/folderActions";
import whitespace from "../tokens/Whitespace";
import Button from "../layout/Button";
import LinkButton from "../layout/LinkButton";
import H3 from "../layout/H3";
import H2 from "../layout/H2";
import H1 from "../layout/H1";
import Body from "../layout/Body";
import Row from "../layout/Row";
import Logoloader from "../layout/Logoloader";

const StyledWrapper = styled.div`
	margin: auto;
	height: 300px;
	width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

const withAuth = Component => ({ auth, ...props }) => {
	if (!auth || !auth.isLoaded) {
		return;
	}

	if (!auth.uid) {
		return (
			<StyledWrapper>
				<Row width="255px">
					<H2 textAlign="center">Log in or Sign up first</H2>
				</Row>

				<Body color={({ theme }) => theme.primaryFontColor} textAlign="center" marginTop={whitespace.large}>
					To join the folder, click the link from your email again after you:
				</Body>
				<Row alignItems="center" justifyContent="space-between" marginTop={whitespace.medium} width="140px">
					<Link to={"/signin"}>
						<LinkButton text="Log in" padding="none">
							Log in
						</LinkButton>
					</Link>
					<Body color={({ theme }) => theme.primaryFontColor}>or</Body>
					<Link to={"/signup"}>
						<LinkButton design="primary" text="Sign up">
							Sign up
						</LinkButton>
					</Link>
				</Row>
			</StyledWrapper>
		);
	}

	if (!props.confirmation || props.confirmation.email !== auth.email) {
		return (
			<StyledWrapper>
				<H2 textAlign="center">Invitation is not assigned to you</H2>
				<br />
				<Body color={({ theme }) => theme.primaryFontColor} textAlign="center">
					Try to Log out and Log in with another account
				</Body>
			</StyledWrapper>
		);
	}

	return <Component {...props} />;
};

const FolderConfirmation = ({ confirmation, confirm = noop, match }) => {
	const [initialStatus, setInitialStatus] = useState(null);
	const [message, setMessage] = useState(null);
	const [image, setImage] = useState(null);

	useEffect(() => {
		if (!initialStatus && confirmation) {
			setInitialStatus(confirmation.status);
		}
	}, [confirmation, initialStatus]);

	useEffect(() => {
		if (message || !initialStatus) {
			return;
		}

		if (initialStatus === "pending") {
			confirm(match.params.confId, () => {
				setMessage("Invitation accepted!");
				setImage("🥳");
			});

			return;
		}

		setMessage("Oops Something went wrong or invitation expired");
		setImage("😢");
	}, [initialStatus, confirm, message, match.params.confId]);

	if (!initialStatus || confirmation === undefined || (initialStatus === "pending" && !message)) {
		return (
			<StyledWrapper>
				<Row width="50px" alignItems="center" justifyContent="center" flexDirection="column">
					<Logoloader />
				</Row>
				{/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
				<Body color={({ theme }) => theme.primaryFontColor} textAlign="center" marginTop={whitespace.big}>
					Checking your invitation 🧐
				</Body>
			</StyledWrapper>
		);
	}

	return (
		<StyledWrapper>
			<Row width="50px" alignItems="center" justifyContent="center" flexDirection="column">
				<H1 textAlign="center">{image}</H1>
			</Row>
			<Row alignItems="center" justifyContent="center">
				<H3 color={({ theme }) => theme.primaryFontColor} marginTop={whitespace.big} textAlign="center">
					{message}
				</H3>
			</Row>
			<Link to={"/folders"}>
				<Button marginTop={whitespace.big} design="primary" text="Go to folders">
					Go to folders
				</Button>
			</Link>
		</StyledWrapper>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		confirmation: state.firestore.data[`conf_${ownProps.match.params.confId}`],
		auth: state.firebase.auth,
	};
};

const mapDispatchToProps = {
	confirm: (confId, onSuccess) => confirmGroupAcceptAction(confId, onSuccess),
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect(props => [
		{
			storeAs: `conf_${props.match.params.confId}`,
			collection: "folderConfirmations",
			doc: props.match.params.confId,
		},
	]),
	withAuth
)(FolderConfirmation);
