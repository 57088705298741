import isEqual from "lodash.isequal";

import { getColors } from "../../../Components/utils/lottie";
import { setColorsAction } from "../../actions/editorActions";
import { updateProject } from "../../actions/projectActions";

const handleSetAnimation = ({ dispatch, getState }, next, action) => {
	const { firestore, editor } = getState();

	const currentProject = firestore.data[`project_${editor.projectId}`];

	if (!currentProject.size) {
		const { w: width = 600, h: height = 600 } = action.payload;

		const size = {
			width,
			height,
			multiplier: 1,
		};

		dispatch(updateProject(editor.projectId, { size }));
	}

	const payloadColors = getColors(action.payload);

	const ignoreIdMap = ({ id, ...color }) => ({ color });

	if (!currentProject.colors || !isEqual(payloadColors.map(ignoreIdMap), currentProject.colors.map(ignoreIdMap))) {
		dispatch(setColorsAction(payloadColors));
		dispatch(updateProject(editor.projectId, { colors: payloadColors }));
		return;
	}

	dispatch(setColorsAction(currentProject.colors));
};

export default handleSetAnimation;
