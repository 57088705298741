import React, { memo, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import useClickAway from "ahooks/lib/useClickAway";
import fontsize from "../tokens/FontSizes";
import styled from "styled-components";
import whitespace from "../tokens/Whitespace";
import { signOut } from "../../store/actions/authActions";

const Panel = styled.div`
	overflow: hidden;

	width: 200px;
	height: 80px;
	background-color: ${({ theme }) => theme.selectMenuBackgroundColor};
	border-radius: 8px;
	padding: ${whitespace.small};
	justify-content: flex-start;
	display: flex;
	flex-direction: column;
	margin-left: ${props => props.marginLeft};
	margin-right: auto;
	margin-top: ${whitespace.medium};
	box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.25);
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);

	float: right;
`;

const ContextList = styled.div`
	flex-grow: 1;
	padding: ${whitespace.small};
	justify-content: flex-start;
	display: flex;
	flex-direction: column;
	margin-left: ${props => props.marginLeft};
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	float: left;
`;

const ContextListElement = styled.div`
	font-size: ${fontsize.body};
	color: ${({ theme }) => theme.primaryFontColor};
	flex-grow: 1;
	height: 16px;

	display: flex;
	transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
	float: right;
	padding: ${whitespace.default};
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: ${({ theme }) => theme.highlight};
	}
`;

const TooltipPanel = ({ onClick, onClose, toggleTheme }) => {
	const panelRef = useRef();
	const dispatch = useDispatch();

	const handleClickSignOut = useCallback(() => dispatch(signOut()), [dispatch]);

	useClickAway(onClose, panelRef);

	return (
		<Panel ref={panelRef} onClick={onClick}>
			<ContextList>
				<ContextListElement onClick={handleClickSignOut}>Log out</ContextListElement>
				{/*<ContextListElement onClick={this.props.accSettings}>
                        Account settings
                    </ContextListElement> */}
				<ContextListElement onClick={toggleTheme}>Switch theme</ContextListElement>
			</ContextList>
		</Panel>
	);
};

export default memo(TooltipPanel);
