import * as React from "react";

const Close = ({ styles = {}, ...props }) => (
    <svg height="32px" viewBox="0 0 32 32" width="32px" {...props}>
        <g fill="currentColor" fillRule="nonzero">
            <path
                fill="currentColor"
                d="M9.4,7.3l6.6,6.6l6.6-6.6c0.4-0.4,1-0.4,1.4,0L24.7,8c0.4,0.4,0.4,1,0,1.4L18.1,16l6.6,6.6
	c0.4,0.4,0.4,1,0,1.4L24,24.7c-0.4,0.4-1,0.4-1.4,0L16,18.1l-6.6,6.6c-0.4,0.4-1,0.4-1.4,0L7.3,24c-0.4-0.4-0.4-1,0-1.4l6.6-6.6
	L7.3,9.4C6.9,9,6.9,8.4,7.3,8L8,7.3C8.4,6.9,9,6.9,9.4,7.3z"
            />
        </g>
    </svg>
);

export default Close;
